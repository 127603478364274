import { DESTINATION_OWNERS, Routes } from '@packages/constants';
import { Session, TimelineEntryTypeUtil } from '@packages/firebase';
import { VFC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SessionDetailModal from './SessionDetailModal';
import Table from './Table';
import CellFormatters from './Table/CellFormatters';

export type SessionsListProps = {
  sessions: Session[];
  disableNavigation?: boolean;
  onSelect?: (ids: string[]) => void;
};

const columns = [
  {
    Header: 'Product',
    accessor: 'organ',
    Cell: CellFormatters.product,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: CellFormatters.datetime,
    sortType: 'datetime',
  },
  {
    Header: 'Destination',
    accessor: 'destination',
    Cell: CellFormatters.destination,
  },
  {
    Header: 'Session Type',
    accessor: 'sessionType',
    Cell: CellFormatters.sessionType,
  },
  {
    Header: 'Session active',
    accessor: 'sessionActive',
    Cell: CellFormatters.boolean,
    sortType: 'basic',
  },
  {
    Header: 'Logger active',
    accessor: 'loggerActive',
    Cell: CellFormatters.boolean,
    sortType: 'basic',
  },
  {
    Header: 'Logger SN',
    accessor: 'loggerSerialNo',
  },
  {
    Header: 'Donor ID',
    accessor: 'unosId',
  },

  {
    Header: 'Last Timeline Event',
    accessor: 'lastTimelineEvent',
  },
  {
    Header: 'Last Temp.',
    accessor: 'lastTemperature',
    Cell: CellFormatters.temperature,
  },
];

const SessionsList: VFC<SessionsListProps> = ({ sessions, disableNavigation = false, onSelect }) => {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [isDetailModalOpen, setIsDetailModalOpen] = useState(sessionId != null);
  const [modalSessionId, setModalSessionId] = useState<string | undefined>();

  const modalSession = useMemo(() => {
    if (sessionId == null && modalSessionId == null) {
      return null;
    }

    return !disableNavigation ? sessions.find(elem => elem.id === sessionId) : sessions.find(elem => elem.id === modalSessionId);
  }, [sessionId, sessions, modalSessionId]);

  useEffect(() => {
    if (modalSession == null) {
      setIsDetailModalOpen(false);
      return;
    }

    setIsDetailModalOpen(true);
  }, [modalSession]);

  useEffect(() => {
    if (!disableNavigation && !isDetailModalOpen) {
      navigate(`${Routes.wmt.ANALYTICS}${location.search}`);
    }

    if (!isDetailModalOpen) {
      setModalSessionId(undefined);
    }
  }, [isDetailModalOpen]);

  const data = useMemo(() => {
    if (sessions == null) {
      return [];
    }

    return sessions
      .map(session => {
        const destination = session.destination != null ? DESTINATION_OWNERS.find(elem => elem.unos === session.destination) : null;

        return {
          id: session.id,
          product: session.product,
          organ: session.organ,
          createdAt: session.createdAt,
          sessionType: session.sessionType,
          sessionActive: !session.finished,
          loggerActive: session.loggerState === 'STARTED',
          loggerSerialNo: session.loggerSerialNo ?? '-',
          unosId: session.unosId ?? '-',
          destination: destination?.unos ?? '-',
          lastTimelineEvent:
            session.lastTimelineEvent != null ? TimelineEntryTypeUtil.toUIString(session.lastTimelineEvent, session.product) : '-',
          lastTemperature: session.loggerData?.probe,
        };
      })
      .filter(session => session.createdAt != null);
  }, [sessions]);

  function onSessionDetail(sessionId: string) {
    if (disableNavigation) {
      setModalSessionId(sessionId);
      return;
    }

    navigate(`${Routes.wmt.ANALYTICS}/${sessionId}${location.search}`);
  }

  return (
    <>
      <Table
        columns={columns as any}
        data={data}
        options={{ hasPagination: true, hasRowSelect: onSelect != null, initialSortBy: [{ id: 'createdAt', desc: true }] }}
        onRowSelect={originals => onSelect != null && onSelect(originals.map(elem => elem.id))}
        onRowClick={({ original }) => onSessionDetail(original.id)}
      />

      {modalSession != null && <SessionDetailModal session={modalSession} isOpen={isDetailModalOpen} setIsOpen={setIsDetailModalOpen} />}
    </>
  );
};

export default SessionsList;
