import {
  DestinationModelParams,
  Organization,
  OrganizationMember,
  OrganizationMemberAccessRole,
  OrganizationMemberStatus,
  OrganizationModelParams,
  OrganizationPreselectedMember,
  OrganizationStatus,
  OrganizationUnregisteredInvite,
  destinationConverter,
  organizationConverter,
  organizationMemberConverter,
  organizationMemberRoleConverter,
  organizationPreselectedMemberConverter,
  organizationTypeConverter,
  organizationUnregisteredInviteConverter,
} from '@packages/firebase';
import {
  Timestamp,
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import { getFirebaseStore, getUser } from './firestore';

export function useFetchOrganizationTypesConfig() {
  const firestore = getFirebaseStore();

  const [organizationTypes, isLoading] = useCollectionData(
    query(collection(firestore, 'config', 'organizations', 'types'), orderBy('sort', 'asc')).withConverter(organizationTypeConverter),
  );

  return {
    organizationTypes,
    isLoading,
  };
}

export function useFetchOrganizationUserRolesConfig() {
  const firestore = getFirebaseStore();

  const [userRoles, areUserRolesLoading] = useCollectionData(
    collection(firestore, 'config', 'organizations', 'user_roles').withConverter(organizationMemberRoleConverter),
  );

  return {
    userRoles,
    isLoading: areUserRolesLoading,
  };
}

export function useFetchDestinationsConfig() {
  const firestore = getFirebaseStore();

  const [destinations, areDestinationsLoading] = useCollectionData(
    collection(firestore, 'config', 'organizations', 'destinations').withConverter(destinationConverter),
  );

  return {
    destinations,
    isLoading: areDestinationsLoading,
  };
}

export function useFetchOrganizations() {
  const firestore = getFirebaseStore();

  const [organizations, areOrganizationsLoading] = useCollectionData(
    collection(firestore, 'organizations').withConverter<Organization>(organizationConverter),
  );

  return {
    organizations,
    areOrganizationsLoading,
  };
}

export function useFetchOrganization(id: string) {
  const firestore = getFirebaseStore();

  const [organization, isOrganizationLoading] = useDocumentData(
    doc(firestore, 'organizations', id).withConverter<Organization>(organizationConverter),
  );

  const [users, areUsersLoading] = useCollectionData(
    query(collection(firestore, 'organizations', id, 'users')).withConverter<OrganizationMember>(organizationMemberConverter),
  );

  const [preselectedUsers, arePreselectedUsersLoading] = useCollectionData(
    query(collection(firestore, 'organizations', id, 'preselectedUsers')).withConverter<OrganizationPreselectedMember>(
      organizationPreselectedMemberConverter,
    ),
  );

  const [unregisteredInvites, areUnregisteredInvitesLoading] = useCollectionData(
    query(
      collection(firestore, 'organizations_unregistered_invites'),
      where('organizationId', '==', id),
    ).withConverter<OrganizationUnregisteredInvite>(organizationUnregisteredInviteConverter),
  );

  return {
    organization,
    users,
    unregisteredInvites,
    preselectedUsers,
    isLoading: isOrganizationLoading || areUsersLoading || arePreselectedUsersLoading || areUnregisteredInvitesLoading,
  };
}

export async function createOrganization(
  data: Omit<
    OrganizationModelParams,
    'id' | 'handle' | 'status' | 'adminIds' | 'userIds' | 'sessionIds' | 'createdAt' | 'updatedAt' | 'validationStartedAt' | 'activatedAt'
  >,
) {
  const firestore = getFirebaseStore();

  const organization = await addDoc(collection(firestore, 'organizations'), {
    ...data,
    status: OrganizationStatus.CREATED,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  });

  await updateDoc(doc(firestore, 'organizations', organization.id), { id: organization.id });
}

export async function updateDestination(handle: string, data: Partial<DestinationModelParams>) {
  const firestore = getFirebaseStore();

  return updateDoc(doc(firestore, 'config', 'organizations', 'destinations', handle), data);
}

export async function updateOrganization(
  id: string,
  data: Partial<
    Omit<
      OrganizationModelParams,
      'id' | 'handle' | 'status' | 'adminIds' | 'userIds' | 'sessionIds' | 'createdAt' | 'updatedAt' | 'validationStartedAt' | 'activatedAt'
    >
  >,
) {
  const firestore = getFirebaseStore();

  return updateDoc(doc(firestore, 'organizations', id), { ...data, updatedAt: Timestamp.now() });
}

export async function deleteOrganization(organizationId: string) {
  const firestore = getFirebaseStore();

  return deleteDoc(doc(firestore, 'organizations', organizationId));
}

export async function addPreselectedUserToOrganization(organizationId: string, userId: string) {
  const firestore = getFirebaseStore();

  const user = await getUser(userId);

  if (user == null) {
    return;
  }

  await setDoc(doc(firestore, 'organizations', organizationId, 'preselectedUsers', user.id), {
    id: user.id,
    email: user.email,
    firstname: user.firstname,
    lastname: user.lastname,
    accessRole: OrganizationMemberAccessRole.VIEWER,
    status: OrganizationMemberStatus.PRESELECTED,
    isConfirmed: false,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  });
}

export async function removePreselectedUserFromOrganization(organizationId: string, userId: string) {
  const firestore = getFirebaseStore();

  return deleteDoc(doc(firestore, 'organizations', organizationId, 'preselectedUsers', userId));
}

export async function removeUserFromOrganization(organizationId: string, userId: string) {
  const firestore = getFirebaseStore();

  await deleteDoc(doc(firestore, 'organizations', organizationId, 'users', userId));

  await updateDoc(doc(firestore, 'organizations', organizationId), {
    adminIds: arrayRemove(userId),
    userIds: arrayRemove(userId),
    updatedAt: Timestamp.now(),
  });

  await updateDoc(doc(firestore, 'users', userId), {
    organizationIds: arrayRemove(organizationId),
  });
}

export async function changePreselectedUserAccessRoleInOrganization(
  organizationId: string,
  userId: string,
  accessRole: OrganizationMemberAccessRole,
) {
  const firestore = getFirebaseStore();

  await updateDoc(doc(firestore, 'organizations', organizationId), {
    adminIds: accessRole === OrganizationMemberAccessRole.ADMIN ? arrayUnion(userId) : arrayRemove(userId),
    updatedAt: Timestamp.now(),
  });

  return updateDoc(doc(firestore, 'organizations', organizationId, 'preselectedUsers', userId), {
    accessRole,
    isConfirmed: accessRole === OrganizationMemberAccessRole.ADMIN,
    confirmedAt: accessRole === OrganizationMemberAccessRole.ADMIN ? Timestamp.now() : null,
    updatedAt: Timestamp.now(),
  });
}

export async function changeUserAccessRoleInOrganization(organizationId: string, userId: string, accessRole: OrganizationMemberAccessRole) {
  const firestore = getFirebaseStore();

  await updateDoc(doc(firestore, 'organizations', organizationId), {
    adminIds: accessRole === OrganizationMemberAccessRole.ADMIN ? arrayUnion(userId) : arrayRemove(userId),
    updatedAt: Timestamp.now(),
  });

  return updateDoc(doc(firestore, 'organizations', organizationId, 'users', userId), {
    accessRole,
    updatedAt: Timestamp.now(),
  });
}

export async function deleteOrganizationUnregisteredInvite(id: string) {
  const firestore = getFirebaseStore();

  return deleteDoc(doc(firestore, 'organizations_unregistered_invites', id));
}
