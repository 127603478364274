import { useFormikContext } from 'formik';
import { FC } from 'react';

const ContextCheck: FC = ({ children }) => {
  const isFormikContext = useFormikContext() != null;

  if (!isFormikContext) {
    throw new Error('This component can only be used within a Formik form context.');
  }

  return <div>{children}</div>;
};

export default ContextCheck;
