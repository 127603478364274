import { FC, ReactNode } from 'react';
import { HiCheckCircle, HiExclamationCircle, HiInformationCircle, HiXCircle } from 'react-icons/hi2';
import { BaseStyleTypes } from '../../types';

export type AlertStyleTypes = Omit<BaseStyleTypes, 'default'> | 'info';

type BaseProps = {
  type: AlertStyleTypes;
};

export type AlertProps = BaseProps & {
  title: ReactNode | ReactNode[];
};

type AlertContainerProps = BaseProps;

type AlertIconProps = BaseProps;

const Alert: FC<AlertProps> = ({ type, title, children }) => (
  <AlertContainer type={type}>
    <AlertIcon type={type} />

    <div className="ml-3">
      <AlertTitle>{title}</AlertTitle>
      {children != null && <AlertBody>{children}</AlertBody>}
    </div>
  </AlertContainer>
);

const AlertContainer: FC<AlertContainerProps> = ({ type, children }) => {
  return (
    <div className={`flex p-4 rounded-lg alert ${type.toString()}`} role="alert">
      {children}
    </div>
  );
};

const AlertIcon = ({ type }: AlertIconProps) => {
  switch (type) {
    case 'success':
      return (
        <div className="w-6 h-6">
          <HiCheckCircle className="w-6 h-6" />
        </div>
      );
    case 'warning':
      return (
        <div className="w-6 h-6">
          <HiXCircle className="w-6 h-6" />
        </div>
      );
    case 'danger':
      return (
        <div className="w-6 h-6">
          <HiExclamationCircle className="w-6 h-6" />
        </div>
      );
    default:
      return (
        <div className="w-6 h-6">
          <HiInformationCircle className="w-6 h-6" />
        </div>
      );
  }
};

const AlertTitle: FC = ({ children }) => {
  return <h3 className="leading-relaxed">{children}</h3>;
};

const AlertBody: FC = ({ children }) => {
  return <div className="mt-2 text-sm">{children}</div>;
};

export default Alert;
