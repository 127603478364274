import { classNames } from '@packages/utils';
import { VFC, useCallback, useEffect, useMemo, useState } from 'react';
import { HiCheck, HiInformationCircle, HiXCircle } from 'react-icons/hi2';
import { List } from 'react-virtualized';
import { useFetchUsers } from '../../../lib/firebase/firestore';
import Button from '../../Shared/Button';
import HighlightedText from '../../Shared/HighlightedText';
import Modal from '../../Shared/Modal';
import SearchBox from '../../Shared/SearchBox';

export type UsersWizardProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onAddUsers: (userIds: string[]) => void;
};

const UsersWizard: VFC<UsersWizardProps> = ({ isOpen, setIsOpen, onAddUsers }) => {
  const { users, areUsersLoading } = useFetchUsers();

  const [selected, setSelected] = useState<string[]>([]);
  const [search, setSearch] = useState<string | undefined>();

  function handleOnSelect(userId: string) {
    if (!selected.includes(userId)) {
      setSelected([...selected, userId]);
    } else {
      setSelected(selected.filter(id => id !== userId));
    }
  }

  function handleOnAddUsers() {
    onAddUsers(selected);
    setIsOpen(false);
  }

  const recommendations = useMemo(() => {
    if (areUsersLoading || users == null) {
      return [];
    }

    const result = users
      .filter(user => {
        if (search == null || search.length === 0) {
          return user;
        }

        return (
          (user.email != null && user.email.toLowerCase().includes(search.toLowerCase())) ||
          (user.fullname != null && user.fullname.toLowerCase().includes(search.toLowerCase())) ||
          (user.organization != null && user.organization.toLowerCase().includes(search.toLowerCase()))
        );
      })
      .sort((a, b) => (a.organizationIds ?? []).length - (b.organizationIds ?? []).length);

    return result;
  }, [areUsersLoading, users, search]);

  const isSelected = useCallback(
    (id: string) => {
      if (selected == null || selected.length === 0) {
        return false;
      }

      return selected.includes(id);
    },
    [selected],
  );

  useEffect(() => {
    setSelected([]);
    setSearch(undefined);
  }, [isOpen]);

  const rowRenderer = ({ index, style }) => {
    const user = recommendations[index];

    return (
      <div key={user.id} className="my-2" style={style}>
        <button
          type="button"
          onClick={() => handleOnSelect(user.id)}
          className={classNames(
            isSelected(user.id) ? 'border-blue-500' : 'border-gray-200',
            'flex w-full cursor-pointer items-center justify-between rounded-lg border-2 bg-white p-5 text-left text-gray-900 hover:bg-gray-50 hover:text-gray-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500',
          )}
          disabled={user.organizationIds != null && user.organizationIds.length > 0}
        >
          <div className="space-y-4">
            <div className="space-y-2">
              <div className="w-full text-lg">
                <HighlightedText className="font-bold" text={user.fullname} highlight={search ?? ''} /> (
                <HighlightedText text={user.email} highlight={search ?? ''} />)
              </div>
            </div>

            <div className="flex items-center space-x-1 text-xs">
              {user.organizationIds == null || user.organizationIds.length === 0 ? (
                <>
                  <HiInformationCircle className="h-4 w-4 text-blue-500" />
                  <span>This user has not been assigned to any organization yet</span>
                </>
              ) : (
                <>
                  <HiXCircle className="h-4 w-4 text-red-500" />
                  <span>
                    <strong className="text-red-500">Cannot be assigned!</strong> This user is already part of{' '}
                    <strong>{user.organizationIds.length}</strong> organization(s).{' '}
                  </span>
                </>
              )}
            </div>
          </div>

          {isSelected(user.id) && (
            <div className="flex items-center">
              <div className="rounded-full bg-green-200 p-1">
                <HiCheck className="h-5 w-5 text-green-700" />
              </div>
            </div>
          )}
        </button>
      </div>
    );
  };

  if (areUsersLoading) {
    return null;
  }

  return (
    <Modal disableOutsideClick size="5xl" isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="divide-y">
        <div className="px-6 py-4">
          <h1 className="text-xl font-semibold text-gray-900">Users</h1>
        </div>

        <div className="space-y-4 px-6 py-4">
          <div>
            <SearchBox onChange={search => setSearch(search)} placeholder="Search for user name, organization or email ..." />
          </div>
        </div>

        <div className="h-[500px] overflow-scroll">
          <div className="space-y-6 px-6 py-4 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div>
                <List width={976} height={480} rowHeight={110} rowCount={recommendations.length} rowRenderer={rowRenderer} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex space-x-2 px-6 py-4">
          <Button buttonType="button" styleType="light" onClick={() => setIsOpen(false)} isFullWidth>
            Cancel
          </Button>

          <Button buttonType="submit" onClick={() => handleOnAddUsers()} isFullWidth disabled={selected.length === 0}>
            <div className="flex items-center space-x-2">
              <div>Add</div>

              {selected.length > 0 && (
                <div className="flex h-5 w-5 items-center justify-center rounded-full bg-white text-xs font-bold text-blue-500">
                  <span>{selected.length}</span>
                </div>
              )}
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UsersWizard;
