import { useFormik } from 'formik';
import { createRef, useEffect, useMemo, useState } from 'react';
import { HiPlus } from 'react-icons/hi2';
import withUser from '../components/HOC/withUser';
import AppLayout from '../components/Layouts/AppLayout';
import CreateOrganizationModal from '../components/Pages/Organizations/CreateOrganizationModal';
import ImportOrganizationsModal from '../components/Pages/Organizations/ImportOrganizationsModal';
import OrganizationsListSection from '../components/Pages/Organizations/OrganizationsListSection';
import Button from '../components/Shared/Button';
import DataState from '../components/Shared/DataState';
import Loader from '../components/Shared/Loader';
import { useFetchOrganizations } from '../lib/firebase/organizations';
import { Meta } from '../types';

const meta: Meta = {
  title: 'Organizations',
  description: 'Manage all Paragonix organizations and customers.',
};

const Organizations = () => {
  const { organizations, areOrganizationsLoading } = useFetchOrganizations();

  const [isCreateOrganizationModalOpen, setIsCreateOrganizationModalOpen] = useState(false);
  const [isImportOrganizationModalOpen, setIsImportOrganizationModalOpen] = useState(false);
  const searchInputRef = createRef<HTMLInputElement>();

  const formik = useFormik({ initialValues: { search: '' }, onSubmit: () => {} });

  function handleCmdK(event: any) {
    if (searchInputRef?.current != null && event.keyCode === 75 && event.metaKey) {
      searchInputRef.current.focus();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleCmdK);

    return () => window.removeEventListener('keydown', handleCmdK);
  });

  const filteredOrganizations = useMemo(() => {
    if (areOrganizationsLoading || organizations == null) {
      return [];
    }

    if (formik.values.search == null || formik.values.search.length === 0) {
      return organizations;
    }

    return organizations.filter(
      organization =>
        organization.name.toLowerCase().includes(formik.values.search.toLowerCase()) ||
        (organization.handle != null &&
          organization.handle.length > 0 &&
          organization.handle.toLowerCase().includes(formik.values.search.toLowerCase())),
    );
  }, [organizations, formik.values.search]);

  if (areOrganizationsLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader size="xl" />
      </div>
    );
  }

  return (
    <AppLayout meta={meta}>
      <div className="mt-12 mb-4">
        <div>
          {organizations != null && organizations.length > 0 && (
            <div className="flex justify-between">
              <div>
                <div className="relative flex items-center">
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="block rounded-md border-gray-300 pr-12 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    ref={searchInputRef}
                    onChange={formik.handleChange}
                    value={formik.values.search}
                  />
                  <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                    <kbd className="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400">
                      ⌘K
                    </kbd>
                  </div>
                </div>
              </div>

              <div>
                <Button onClick={() => setIsCreateOrganizationModalOpen(true)} size="sm">
                  <HiPlus className="mr-2 h-4 w-4" />
                  <span>Create Organization</span>
                </Button>
              </div>
            </div>
          )}

          <DataState
            hasData={organizations != null && organizations.length > 0}
            isLoading={areOrganizationsLoading}
            styleType="card"
            text={
              <div className="flex flex-col justify-center space-y-4">
                <span className="block text-center">No organizations found</span>

                <div>
                  <Button onClick={() => setIsCreateOrganizationModalOpen(true)} size="sm">
                    <HiPlus className="mr-2 h-4 w-4" />
                    <span>Create Organization</span>
                  </Button>
                </div>
              </div>
            }
          >
            {organizations != null && (
              <div className="mt-8">
                <OrganizationsListSection organizations={filteredOrganizations} />
              </div>
            )}
          </DataState>
        </div>

        <CreateOrganizationModal isOpen={isCreateOrganizationModalOpen} setIsOpen={setIsCreateOrganizationModalOpen} />

        {organizations != null && (
          <ImportOrganizationsModal
            organizations={organizations}
            isOpen={isImportOrganizationModalOpen}
            setIsOpen={setIsImportOrganizationModalOpen}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default withUser(Organizations);
