/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { classNames } from '@packages/utils';
import { useEffect, useMemo, VFC } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi2';
import { Column, useTable } from 'react-table';
import configureTable, { ConfigureTablePluginOptions } from './configureTable';
import SortToggle from './SortToggle';

export type TableStyleTypes = 'card' | 'default';

export type TableProps<D extends {}> = {
  columns: Column<D>[];
  data: D[];
  onRowSelect?: (originals: D[]) => void;
  onRowClick?: (originals: D) => void;
  options?: ConfigureTablePluginOptions;
};

const Table: VFC<TableProps<any>> = ({ columns, data, onRowSelect, onRowClick, options }) => {
  const { tableOptions, tablePlugins } = configureTable(columns, data, options);
  const table = useTable(tableOptions, ...tablePlugins);

  const total = useMemo(() => table.rows.length, [table.rows.length]);

  const from = useMemo(
    () => (total > 0 ? table.state.pageSize * table.state.pageIndex + 1 : 0),
    [table.state.pageIndex, table.state.pageSize, total],
  );

  const to = useMemo(
    () =>
      table.state.pageSize * table.state.pageIndex + table.state.pageSize < total
        ? table.state.pageSize * table.state.pageIndex + table.state.pageSize
        : total,
    [total, table.state.pageIndex, table.state.pageSize],
  );

  useEffect(() => {
    if (onRowSelect == null) {
      return;
    }

    if (table.selectedFlatRows == null) {
      return;
    }

    onRowSelect(table.selectedFlatRows.map(elem => elem.original));
  }, [table.selectedFlatRows]);

  return (
    <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="border-t border-b border-gray-100">
              {table.headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      scope="col"
                      className="py-3.5 first:px-6 px-2 text-left text-sm font-semibold text-gray-900"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="flex items-center space-x-1">
                        <span className="text-sm">{column.render('Header')}</span>
                        <span>
                          <SortToggle isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody className="bg-white" {...table.getTableBodyProps()}>
              {(options?.hasPagination ? table.page : table.rows).map(row => {
                table.prepareRow(row);

                return (
                  <tr className="border-b border-gray-100 hover:bg-gray-50" {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td
                          className={classNames(
                            onRowClick != null && cell.column.id !== 'selection' && cell.column.id !== 'actions' ? 'cursor-pointer' : '',
                            'px-2 first:px-6 py-4 text-gray-500 whitespace-nowrap',
                          )}
                          {...cell.getCellProps()}
                          onClick={() =>
                            onRowClick != null && cell.column.id !== 'selection' && cell.column.id !== 'actions' && onRowClick(row)
                          }
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {options?.hasPagination && (
          <nav className="flex items-center justify-between w-full py-3 bg-transparent">
            <div className="hidden sm:block">
              <p className="text-sm text-gray-400">
                Showing results {from} to {to} of {total}
              </p>
            </div>

            <div className="flex justify-between flex-1 space-x-6 sm:justify-end">
              <div className="space-x-3">
                <span className="text-sm text-gray-400">Number of results</span>
                <select
                  className="py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={table.pageSize}
                  onChange={event => {
                    table.setPageSize(Number(event.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={`page-size-option-${pageSize}`} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex items-center space-x-4">
                <button
                  type="button"
                  className="relative inline-flex items-center text-gray-500 disabled:text-gray-300"
                  disabled={!table.canPreviousPage}
                  onClick={() => table.previousPage()}
                >
                  <HiChevronLeft className="h-6" />
                </button>
                <button
                  type="button"
                  className="relative inline-flex items-center text-gray-500 disabled:text-gray-300"
                  disabled={!table.canNextPage}
                  onClick={() => table.nextPage()}
                >
                  <HiChevronRight className="h-6" />
                </button>
              </div>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
};

export default Table;
