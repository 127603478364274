import { classNames } from '@packages/utils';
import { useMemo, VFC } from 'react';

type HorizontalReportPanelProps = {
  columns: ColumnProps[];
};

type ColumnProps = {
  name: string;
  value: string | number | undefined;
  dotColor?: string;
};

const HorizontalReportPanel: VFC<HorizontalReportPanelProps> = ({ columns }) => {
  const numColumns = useMemo(() => {
    switch (columns.length) {
      case 1:
        return 'md:grid-cols-1';
      case 2:
        return 'md:grid-cols-2';
      case 3:
        return 'md:grid-cols-3';
      case 4:
        return 'md:grid-cols-4';
      case 5:
        return 'md:grid-cols-5';
      case 6:
        return 'md:grid-cols-6';
      case 7:
        return 'md:grid-cols-7';
      case 8:
        return 'md:grid-cols-8';
      case 9:
        return 'md:grid-cols-9';
      case 10:
        return 'md:grid-cols-10';
      case 11:
        return 'md:grid-cols-11';
      case 12:
        return 'md:grid-cols-12';
      default:
        return 'md:grid-cols-3';
    }
  }, [columns]);

  return (
    <div>
      <dl className={classNames(numColumns, 'grid grid-cols-1 overflow-hidden divide-y md:divide-y-0 md:divide-x')}>
        {columns.map((column, index) => (
          <Column key={`column-${column.name}-${index}`} {...column} />
        ))}
      </dl>
    </div>
  );
};

const Column: VFC<ColumnProps> = ({ name, value, dotColor }) => {
  return (
    <div className="w-full space-y-1 text-center">
      <dt className="flex items-center justify-center space-x-2">
        {dotColor != null && <div className="w-2 h-2 rounded-full" style={{ backgroundColor: dotColor }} />}
        <span className="text-gray-400">{name}</span>
      </dt>
      <dd className="w-full">
        <span className="text-2xl font-semibold text-center text-black">{value ?? '-'}</span>
      </dd>
    </div>
  );
};

export default HorizontalReportPanel;
