import { Routes as RouteDefs } from '@packages/constants';
import { BrowserRouter, Navigate, Route, Routes as Switch } from 'react-router-dom';
import Analytics from '../pages/Analytics';
import Countries from '../pages/Countries';
import Live from '../pages/Live';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import OrganizationDetail from '../pages/OrganizationDetail';
import Organizations from '../pages/Organizations';
import Tools from '../pages/Tools';
import ManageDestinations from '../pages/Tools/ManageDestinations';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={RouteDefs.wmt.LOGIN} element={<Login />} />

        <Route path="/" element={<Navigate to={RouteDefs.wmt.ANALYTICS} replace />} />
        <Route path={RouteDefs.wmt.ANALYTICS} element={<Analytics />}>
          <Route path=":sessionId" element={null} />
        </Route>

        <Route path={RouteDefs.wmt.LIVE} element={<Live />} />

        <Route path={RouteDefs.wmt.TOOLS} element={<Tools />} />
        <Route path={RouteDefs.wmt.TOOLS_MANAGE_DESTINATIONS} element={<ManageDestinations />} />

        <Route path={RouteDefs.wmt.ORGANIZATIONS} element={<Organizations />} />
        <Route path={RouteDefs.wmt.ORGANIZATION_DETAIL} element={<OrganizationDetail />} />
        <Route path={RouteDefs.wmt.COUNTRIES} element={<Countries />} />

        <Route element={<NotFound />} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
