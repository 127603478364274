import { classNames } from '@packages/utils';
import { Field, useFormikContext } from 'formik';
import { VFC } from 'react';
import ContextCheck from './ContextCheck';

export type CheckboxBubbleProps = {
  id: string;
  label: string;
  value: string | number;
};

const CheckboxBubble: VFC<CheckboxBubbleProps> = ({ id, label, value }) => {
  const { values } = useFormikContext<{ [key: string]: any }>();

  const checkboxKey = `${id}-${label}`;

  return (
    <ContextCheck>
      <div>
        <Field type="checkbox" className="hidden" id={checkboxKey} name={id} value={value} />
        <div className="my-2">
          <label
            htmlFor={checkboxKey}
            className={classNames(
              values[id] === true || values[id] === value || (typeof values[id] === 'object' && values[id].includes(value))
                ? 'bg-blue-500 border-blue-500 text-white'
                : 'bg-transparent border-gray-300 text-gray-500',
              'px-3 py-2 text-sm border rounded',
            )}
          >
            {label}
          </label>
        </div>
      </div>
    </ContextCheck>
  );
};

export default CheckboxBubble;
