import { formatDistance, millisecondsToTime } from '@packages/utils';
import { useMemo } from 'react';
import { useSessions } from '../../../contexts/useSessions';
import Card from '../../Shared/Card';

const OverviewSection = () => {
  const { stats } = useSessions();

  const totalSessions = useMemo(() => {
    if (stats.counts.total == null) {
      return '-';
    }

    return new Intl.NumberFormat().format(stats.counts.total);
  }, [stats]);

  const totalDuration = useMemo(() => {
    if (stats.counts.totalDuration < 1) {
      return '-';
    }

    return millisecondsToTime(stats.counts.totalDuration, { maxUnits: 1 });
  }, [stats]);

  const totalLoggingDuration = useMemo(() => {
    if (stats.counts.totalLoggingDuration < 1) {
      return '-';
    }

    return millisecondsToTime(stats.counts.totalLoggingDuration, { maxUnits: 1 });
  }, [stats]);

  const totalDistance = useMemo(() => {
    if (stats.counts.totalDistance < 1) {
      return '-';
    }

    return formatDistance(stats.counts.totalDistance);
  }, [stats]);

  const totalTimestamps = useMemo(() => {
    if (stats.counts.totalTimestamps == null) {
      return '-';
    }

    return new Intl.NumberFormat().format(stats.counts.totalTimestamps);
  }, [stats]);

  return (
    <div className="grid grid-cols-5 gap-x-8">
      <Card>
        <dt className="text-sm font-medium text-gray-500 truncate">Total Sessions</dt>
        <dd className="mt-1 text-2xl font-semibold tracking-tight text-gray-900">{totalSessions}</dd>
      </Card>

      <Card>
        <dt className="text-sm font-medium text-gray-500 truncate">Total Session Duration</dt>
        <dd className="mt-1 text-2xl font-semibold tracking-tight text-gray-900">{totalDuration}</dd>
      </Card>

      <Card>
        <dt className="text-sm font-medium text-gray-500 truncate">Total Logging Duration</dt>
        <dd className="mt-1 text-2xl font-semibold tracking-tight text-gray-900">{totalLoggingDuration}</dd>
      </Card>

      <Card>
        <dt className="text-sm font-medium text-gray-500 truncate">Total Distance</dt>
        <dd className="mt-1 text-2xl font-semibold tracking-tight text-gray-900">{totalDistance}</dd>
      </Card>

      <Card>
        <dt className="text-sm font-medium text-gray-500 truncate">Total Timestamps</dt>
        <dd className="mt-1 text-2xl font-semibold tracking-tight text-gray-900">{totalTimestamps}</dd>
      </Card>
    </div>
  );
};

export default OverviewSection;
