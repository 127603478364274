import { DonationType, DonationTypeUtil } from '@packages/firebase';
import Badge, { BadgeStyleTypes } from '../../Badge';

function donationTypeFormatter({ value }: { value: DonationType }) {
  if (value == null) {
    return (
      <Badge styleType="danger" size="xs">
        Unknown
      </Badge>
    );
  }

  const styleType: BadgeStyleTypes = value === DonationType.DBD ? 'donation_type_dbd' : 'donation_type_dcd';

  return (
    <Badge size="xs" styleType={styleType}>
      {DonationTypeUtil.toUIString(value)}
    </Badge>
  );
}

export default donationTypeFormatter;
