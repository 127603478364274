import { Popover } from '@headlessui/react';
import { DonationType, ProductType, ProductUtil, SessionType } from '@packages/firebase';
import { classNames } from '@packages/utils';
import { Form, Formik } from 'formik';
import { VFC, useMemo } from 'react';
import { HiOutlineFunnel } from 'react-icons/hi2';
import Button from '../../../Shared/Button';
import CheckboxBubbleGroup, { CheckboxBubbleGroupProps } from '../../../Shared/Formik/CheckboxBubbleGroup';

export type SessionFiltersProps = {
  productType: ProductType[] | undefined;
  donationType: DonationType[] | undefined;
  sessionType: SessionType[] | undefined;
  finished: string[] | undefined;
  logging: string[] | undefined;
  stopReminderSent: string[] | undefined;
  forceStopAfter48Hours: string[] | undefined;
  loggerConnected: string[] | undefined;
  onClear: () => void;
  onSubmit: (values: SessionFiltersFormValues) => void;
};

export type SessionFiltersFormValues = {
  productType: ProductType[];
  donationType: DonationType[];
  sessionType: SessionType[];
  finished: string[];
  logging: string[];
  stopReminderSent: string[];
  forceStopAfter48Hours: string[];
  loggerConnected: string[];
};

type CheckboxBubbleFilterGroupProps = {
  label: string;
} & CheckboxBubbleGroupProps;

const FILTERS: CheckboxBubbleFilterGroupProps[] = [
  {
    id: 'productType',
    label: 'Product',
    checkboxes: [
      { label: ProductUtil.toHeading(ProductType.SHERPAPAK), value: ProductType.SHERPAPAK },
      { label: ProductUtil.toHeading(ProductType.LUNGGUARD), value: ProductType.LUNGGUARD },
      { label: ProductUtil.toHeading(ProductType.BAROGUARD), value: ProductType.BAROGUARD },
      { label: ProductUtil.toHeading(ProductType.LIVERGUARD), value: ProductType.LIVERGUARD },
      { label: ProductUtil.toHeading(ProductType.PANCREASPAK), value: ProductType.PANCREASPAK },
      { label: ProductUtil.toHeading(ProductType.KTS), value: ProductType.KTS },
      { label: ProductUtil.toHeading(ProductType.VANTAGEPOINT), value: ProductType.VANTAGEPOINT },
    ].map(item => ({ ...item, id: 'productType' })),
  },
  {
    id: 'donationType',
    label: 'Donation Type',
    checkboxes: [
      { label: 'DBD', value: DonationType.DBD },
      { label: 'DCD', value: DonationType.DCD },
    ].map(item => ({ ...item, id: 'donationType' })),
  },
  {
    id: 'sessionType',
    label: 'Session Type',
    checkboxes: [
      { label: 'Clinical', value: SessionType.CLINICAL },
      { label: 'Training', value: SessionType.TRAINING },
    ].map(item => ({ ...item, id: 'sessionType' })),
  },
  {
    id: 'finished',
    label: 'Session active',
    checkboxes: [
      { label: 'Yes', value: 'false' },
      { label: 'No', value: 'true' },
    ].map(item => ({ ...item, id: 'finished' })),
  },
  {
    id: 'logging',
    label: 'Logger active',
    checkboxes: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ].map(item => ({ ...item, id: 'logging' })),
  },
  {
    id: 'stopReminderSent',
    label: 'Stop reminder sent',
    checkboxes: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ].map(item => ({ ...item, id: 'stopReminderSent' })),
  },
  {
    id: 'forceStopAfter48Hours',
    label: 'Force stop after 48 hours',
    checkboxes: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ].map(item => ({ ...item, id: 'forceStopAfter48Hours' })),
  },
  {
    id: 'loggerConnected',
    label: 'Logger connected',
    checkboxes: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ].map(item => ({ ...item, id: 'loggerConnected' })),
  },
];

const SessionFilters: VFC<SessionFiltersProps> = ({
  productType,
  donationType,
  sessionType,
  finished,
  logging,
  stopReminderSent,
  forceStopAfter48Hours,
  loggerConnected,
  onClear,
  onSubmit,
}) => {
  const isActive = useMemo(
    () =>
      (productType != null && productType.length > 0) ||
      (donationType != null && donationType.length > 0) ||
      (sessionType != null && sessionType.length > 0) ||
      (finished != null && finished.length > 0) ||
      (logging != null && logging.length > 0) ||
      (stopReminderSent != null && stopReminderSent.length > 0) ||
      (forceStopAfter48Hours != null && forceStopAfter48Hours.length > 0) ||
      (loggerConnected != null && loggerConnected.length > 0),
    [productType, donationType, sessionType, finished, logging, stopReminderSent, forceStopAfter48Hours, loggerConnected],
  );

  return (
    <Formik
      initialValues={{
        productType: productType ?? [],
        donationType: donationType ?? [],
        sessionType: sessionType ?? [],
        finished: finished ?? [],
        logging: logging ?? [],
        stopReminderSent: stopReminderSent ?? [],
        forceStopAfter48Hours: forceStopAfter48Hours ?? [],
        loggerConnected: loggerConnected ?? [],
      }}
      onSubmit={values => onSubmit(values)}
      onReset={() => onClear()}
    >
      <Form>
        <Popover>
          <div className="space-y-3">
            <Popover.Button>
              <div
                className={classNames(
                  isActive ? 'bg-blue-50 text-blue-500' : 'bg-gray-100 text-gray-500',
                  'flex items-center space-x-2 rounded-lg px-3 py-2 text-sm shadow',
                )}
              >
                <HiOutlineFunnel className="h-5" />

                <div className="space-x-2">
                  <span>Filters</span>
                </div>
              </div>
            </Popover.Button>
            <Popover.Panel className="absolute z-50">
              <div className="space-y-4 bg-white p-4 shadow-md">
                <div className="space-y-4">
                  {FILTERS.map(filter => (
                    <CheckboxBubbleFilterGroup key={filter.id} {...filter} />
                  ))}
                </div>

                <div className="flex justify-end space-x-2">
                  <Button styleType="light" buttonType="reset">
                    Clear
                  </Button>
                  <Button buttonType="submit">Apply</Button>
                </div>
              </div>
            </Popover.Panel>
          </div>
        </Popover>
      </Form>
    </Formik>
  );
};

const CheckboxBubbleFilterGroup: VFC<CheckboxBubbleFilterGroupProps> = ({ label, ...checkboxBubbleGroup }) => {
  return (
    <div>
      <span className="font-medium text-gray-600">{label}</span>
      <CheckboxBubbleGroup {...checkboxBubbleGroup} />
    </div>
  );
};

export default SessionFilters;
