import { OrganTypeUtil } from '@packages/firebase';
import { useMemo, useState } from 'react';
import withUser from '../../components/HOC/withUser';
import AppLayout from '../../components/Layouts/AppLayout';
import SearchBox from '../../components/Shared/SearchBox';
import Table from '../../components/Shared/Table';
import { updateDestination, useFetchDestinationsConfig, useFetchOrganizationTypesConfig } from '../../lib/firebase/organizations';
import { Meta } from '../../types';

const meta: Meta = {
  title: 'Manage Destinations',
  description: 'Add, delete and edit destinations.',
};

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Handle',
    accessor: 'handle',
    sortType: 'basic',
  },
  {
    Header: 'Organs',
    accessor: 'organs',
  },
  {
    Header: 'Types',
    accessor: 'types',
  },
  {
    Header: 'Owner Name',
    accessor: 'ownerName',
  },
  {
    Header: 'Owner Email',
    accessor: 'ownerEmail',
  },
  {
    Header: 'Is Session Option',
    accessor: 'isSessionOption',
  },
  {
    Header: 'Is Organization Option',
    accessor: 'isOrganizationOption',
  },
];

const ManageDestinations = () => {
  const { destinations, isLoading: areDestinationsLoading } = useFetchDestinationsConfig();
  const { organizationTypes, isLoading: areOrganizationTypesLoading } = useFetchOrganizationTypesConfig();

  const [search, setSearch] = useState<string>('');

  const data = useMemo(() => {
    if (areDestinationsLoading || areOrganizationTypesLoading) {
      return [];
    }

    if (destinations == null || organizationTypes == null) {
      return [];
    }

    return destinations.map(destination => {
      return {
        name: destination.name,
        handle: destination.handle,
        organs: destination.organs.map(organ => OrganTypeUtil.organString(organ)).join(', '),
        types: destination.types.map(type => organizationTypes.find(orgType => orgType.id === type)?.locales?.en ?? 'Unknown').join(', '),
        ownerName: destination.owner?.name ?? <i>No owner</i>,
        ownerEmail: destination.owner?.email ?? <i>No owner</i>,
        isSessionOption: (
          <input
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-blue-600"
            checked={destination.isSessionOption}
            onChange={() => updateDestination(destination.handle, { isSessionOption: !destination.isSessionOption })}
          />
        ),
        isOrganizationOption: (
          <input
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-blue-600"
            checked={destination.isOrganizationOption}
            onChange={() => updateDestination(destination.handle, { isOrganizationOption: !destination.isOrganizationOption })}
          />
        ),
      };
    });
  }, [destinations, areDestinationsLoading, organizationTypes, areOrganizationTypesLoading]);

  const filteredData = useMemo(() => {
    return data.filter(destination => {
      if (search == null || search.length === 0) {
        return true;
      }

      return (
        destination.handle.toString().toLowerCase().includes(search.toLowerCase()) ||
        destination.name.toString().toLowerCase().includes(search.toLowerCase()) ||
        (destination.ownerName != null && destination.ownerName.toString().toLowerCase().includes(search.toLowerCase())) ||
        (destination.ownerEmail != null && destination.ownerEmail.toString().toLowerCase().includes(search.toLowerCase()))
      );
    });
  }, [data, search]);

  return (
    <AppLayout meta={meta}>
      <div className="mt-12 mb-4 space-y-2">
        <SearchBox onChange={search => setSearch(search)} placeholder="Search for session name, handle, owner name or owner email" />

        <Table columns={columns} data={filteredData} options={{ hasPagination: true }} />
      </div>
    </AppLayout>
  );
};

export default withUser(ManageDestinations);
