import { Reading } from '@packages/firebase';
import { formatPressure, millisecondsToTime } from '@packages/utils';
import { useMemo, VFC } from 'react';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useProductColor } from '../../../../contexts/useProductColor';
import DataState from '../../DataState';
import { calculateChartTicks } from './calculateChartTicks';
import CustomTooltip from './Tooltip';

export type PressureChartProps = {
  readings: Reading[] | undefined;
};

const MIN_CHART_DATA_LENGTH = 5;

function xAxisTickFormatter(value: number) {
  return millisecondsToTime(value);
}

function yAxisTickFormatter(value: number) {
  return formatPressure(value, 1, false);
}

const PressureChart: VFC<PressureChartProps> = ({ readings }) => {
  const colors = useProductColor();

  const startedEvent = useMemo(() => {
    if (readings == null || readings.length === 0) {
      return null;
    }

    return readings.find(reading => reading.isStartedEvent());
  }, [readings]);

  const latestEvent = useMemo(() => {
    if (readings == null || readings.length === 0) {
      return null;
    }

    return readings.find(reading => reading.isStoppedEvent()) ?? readings[readings.length - 1];
  }, [readings]);

  const data = useMemo(() => {
    if (readings == null || readings.length === 0) {
      return null;
    }

    if (startedEvent == null) {
      return null;
    }

    const validReadings = readings.filter(reading => reading.isReading());

    if (validReadings.length < MIN_CHART_DATA_LENGTH) {
      return null;
    }

    return validReadings.map(reading => ({
      milliseconds: reading.timestamp.getTime() - startedEvent.timestamp.getTime(),
      currentTime: reading.timestamp.toLocaleTimeString(navigator.language, { timeStyle: 'short' }),
      time: millisecondsToTime(reading.timestamp.getTime() - startedEvent.timestamp.getTime(), { seconds: false }),
      pressure: reading.pressure,
    }));
  }, [readings]);

  const ticks = useMemo(() => {
    if (startedEvent == null || latestEvent == null) {
      return [];
    }

    return calculateChartTicks(startedEvent.timestamp.getTime(), latestEvent.timestamp.getTime());
  }, [latestEvent, startedEvent]);

  return (
    <DataState hasData={data != null} isLoading={false} text="Not enough Readings found">
      <div className="mt-8 h-[500px]">
        <ResponsiveContainer>
          <LineChart data={data as any}>
            <XAxis
              type="number"
              dataKey="milliseconds"
              ticks={ticks}
              domain={[0, ticks[ticks.length - 1]]}
              stroke="#D3D3D3"
              tickFormatter={value => xAxisTickFormatter(value)}
            />

            <YAxis type="number" domain={[0, 25]} tickCount={7} stroke="#D3D3D3" tickFormatter={value => yAxisTickFormatter(value)} />

            <Legend iconType="circle" />

            <Line name="Pressure" dataKey="pressure" stroke={colors.default} dot={false} strokeWidth={3} />

            <Tooltip content={<CustomTooltip />} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </DataState>
  );
};

export default PressureChart;
