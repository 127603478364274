import { Session, SessionMember } from '@packages/firebase';
import { FormatDateMode, formatDate } from '@packages/utils';
import { VFC, useMemo } from 'react';
import { HiArrowRight, HiArrowUp } from 'react-icons/hi2';
import { MdAirplanemodeActive, MdOutlineTimer } from 'react-icons/md';
import { useProductColor } from '../../../contexts/useProductColor';
import { useETA } from '../../../hooks/useETA';
import KPI from '../Statistics/KPI';

export type ETAProps = {
  session: Session;
  members: SessionMember[];
};

export const ETA: VFC<ETAProps> = ({ session, members }) => {
  const productColors = useProductColor();

  const { eta, flights, progress } = useETA(session, members ?? []);
  // activeFlight

  return (
    <div>
      <KPI
        name="ETA"
        value={eta.countdown != null ? eta.countdown : '-'}
        icon={MdOutlineTimer}
        colors={{ icon: productColors.light, background: productColors.default }}
      />

      {flights != null && flights.length > 0 && (
        <div className="border-t">
          {flights.map((flight, index) => (
            <KPI
              key={`kpi-flight-${index}`}
              name={flight.title ?? '-'}
              nameBadgeText={flight.state}
              value={
                <div className="flex items-center space-x-4">
                  <FlightTrackerTime
                    code={flight.departure.code ?? '-'}
                    planned={flight.departure.planned}
                    estimated={flight.departure.estimated}
                    isDeparture
                  />
                  <FlightTrackerTime
                    code={flight.arrival.code ?? '-'}
                    planned={flight.arrival.planned}
                    estimated={flight.arrival.estimated}
                    isDeparture={false}
                  />
                </div>
              }
              icon={MdAirplanemodeActive}
              colors={{ icon: productColors.light, background: productColors.default }}
            />
          ))}
        </div>
      )}

      {session.createdAt != null && session.eta != null && (
        <div className="border-t px-4 py-5 sm:py-6 sm:px-6">
          <ProgressBar start={session.createdAt} end={session.eta} progress={progress} />
        </div>
      )}
    </div>
  );
};

const FlightTrackerTime: VFC<any> = ({ code, planned, estimated, isDeparture = true }) => {
  const COLORS = {
    isOnTime: '#34c38f',
    isDelayed: '#f46a6a',
  };

  const plannedText = useMemo(() => (planned != null ? formatDate(planned, { mode: FormatDateMode.TIME }) : null), [planned]);

  const estimatedText = useMemo(
    () =>
      estimated != null && planned != null && planned.getTime() !== estimated.getTime()
        ? formatDate(estimated, { mode: FormatDateMode.TIME })
        : null,
    [estimated, planned],
  );

  const isDelayed = useMemo(() => estimatedText != null, [estimatedText]);

  if (plannedText == null && estimatedText == null) {
    return <div>-</div>;
  }

  return (
    <div className="flex items-center space-x-2">
      <div
        className="flex items-center justify-center rounded-full p-1 text-white"
        style={{ backgroundColor: isDelayed ? COLORS.isDelayed : COLORS.isOnTime }}
      >
        {isDeparture ? <HiArrowUp className="h-4 w-4 rotate-45" /> : <HiArrowRight className="h-4 w-4 rotate-45" />}
      </div>

      <div>
        {code}{' '}
        <span style={{ color: !isDelayed ? COLORS.isOnTime : '#000000', textDecoration: isDelayed ? 'line-through' : 'none' }}>
          {plannedText}
        </span>{' '}
        {isDelayed && <span style={{ color: COLORS.isDelayed }}>{estimatedText}</span>}
      </div>
    </div>
  );
};

const ProgressBar: VFC<any> = ({ start, end, progress }) => {
  const productColors = useProductColor();

  return (
    <div className="relative w-full">
      <div className="flex w-full justify-start overflow-hidden rounded-md" style={{ height: '5px', backgroundColor: productColors.light }}>
        <div style={{ width: `${progress.percentage}%`, backgroundColor: productColors.default }} className="flex rounded-md" />

        <div
          className="absolute self-center rounded-full"
          style={{ height: '25px', width: '25px', left: '0%', backgroundColor: productColors.default, border: '2px solid white' }}
        />

        {progress.flight != null && (progress.flight.startPercentage != null || progress.flight.endPercentage) && (
          <>
            {progress.flight.startPercentage != null && (
              <div
                className="absolute self-center rounded-full"
                style={{
                  height: '15px',
                  width: '15px',
                  right: `${100 - progress.flight.startPercentage}%`,
                  backgroundColor: productColors.default,
                  border: '2px solid white',
                }}
              />
            )}

            <div
              className="absolute flex items-center justify-center self-center rounded-full"
              style={{
                height: '25px',
                width: '25px',
                right: `${100 - progress.percentage}%`,
                backgroundColor: productColors.default,
                border: '2px solid white',
                zIndex: 1,
              }}
            >
              <div>
                <MdAirplanemodeActive className="h-3 w-3 rotate-90 text-white" />
              </div>
            </div>

            {progress.flight.endPercentage != null && (
              <div
                className="absolute self-center rounded-full"
                style={{
                  height: '15px',
                  width: '15px',
                  right: `${100 - progress.flight.endPercentage}%`,
                  backgroundColor: productColors.default,
                  border: '2px solid white',
                }}
              />
            )}
          </>
        )}

        <div
          className="absolute self-center rounded-full"
          style={{ height: '25px', width: '25px', right: '0%', backgroundColor: productColors.default, border: '2px solid white' }}
        />
      </div>

      <div className="mt-3 flex w-full justify-between">
        <span className="font-semibold">{formatDate(start, { mode: FormatDateMode.TIME })}</span>
        <span className="font-semibold">{formatDate(end, { mode: FormatDateMode.TIME })}</span>
      </div>
    </div>
  );
};
