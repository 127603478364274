import { VFC } from 'react';
import { HiMagnifyingGlass } from 'react-icons/hi2';

export type SearchBoxProps = {
  onChange: (search: string) => void;
  placeholder?: string;
};

const SearchBox: VFC<SearchBoxProps> = ({ onChange, placeholder = '' }) => {
  const onSearchChange = event => {
    onChange(event.target.value);
  };

  return (
    <div className="relative mt-1 rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <HiMagnifyingGlass className="w-5 h-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        id="search"
        name="search"
        type="text"
        className="block w-full pl-10 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        placeholder={placeholder}
        onChange={onSearchChange}
      />
    </div>
  );
};

export default SearchBox;
