import { FC } from 'react';

export const TooltipContainer: FC = ({ children }) => {
  return <div className="relative inline-block cursor-pointer group">{children}</div>;
};

export const Tooltip: FC = ({ children }) => {
  return (
    <div className="absolute top-0 bottom-0 hidden -mt-8 group-hover:block">
      <div className="bg-gray-700 text-white py-1.5 px-2 rounded-md text-xs shadow-lg">{children}</div>
    </div>
  );
};
