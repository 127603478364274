import { classNames } from '@packages/utils';
import { FC } from 'react';

export type CardProps = {
  hasPadding?: boolean;
};

const Card: FC<CardProps> = ({ hasPadding = true, children }) => {
  return <div className={classNames(hasPadding ? 'px-4 py-8 sm:px-10' : '', 'bg-white sm:shadow sm:rounded-xl')}>{children}</div>;
};

export default Card;
