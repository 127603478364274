import { TooltipProps } from 'recharts';
import { formatPressure } from '@packages/utils';
import { useProductColor } from '../../../../contexts/useProductColor';

function ChartTooltip({ active, payload }: TooltipProps<string, number>) {
  const colors = useProductColor();

  if (!active || payload == null) {
    return null;
  }

  const pressureData = payload.find(elem => elem.dataKey === 'pressure')?.payload;

  if (pressureData == null) {
    return null;
  }

  return (
    <div className="w-56 bg-white shadow-lg">
      <div className="px-4 py-2 text-center text-white rounded-t" style={{ backgroundColor: colors.default }}>
        <div className="text-lg font-semibold">{pressureData?.currentTime ?? '-'}</div>
        <div>+{pressureData?.time != null && pressureData?.time.length > 0 ? pressureData?.time ?? '0m' : '0m'}</div>
      </div>
      <div className="flex justify-center px-5 py-4 space-x-8">
        <div className="text-center">
          <div className="text-xs font-medium text-gray-500 uppercase">Pressure</div>
          <div className="text-lg">{formatPressure(pressureData?.pressure)}</div>
        </div>
      </div>
    </div>
  );
}

export default ChartTooltip;
