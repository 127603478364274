import { classNames } from '@packages/utils';
import { useMemo, VFC } from 'react';

export type PieChartTableProps = {
  data: PieChartTableDataItem[];
  highLightIndex?: number;
};

export type PieChartTableDataItem = {
  name: string;
  count: number;
  color?: string;
};

type SetDataItem = PieChartTableDataItem & {
  percentage: number;
};

const PieChartTable: VFC<PieChartTableProps> = ({ data, highLightIndex }) => {
  const setData: SetDataItem[] = useMemo(() => {
    if (data == null || data.length === 0) {
      return [];
    }

    const total = data.reduce((prev, curr) => prev + curr.count, 0);

    return data.map(item => ({ ...item, percentage: total > 0 ? Math.round((item.count / total) * 100) : 0 }));
  }, [data]);

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>
          <th className="py-4">&nbsp;</th>
          <th className="py-4 text-sm font-semibold text-right text-gray-900">Cases</th>
          <th className="py-4 text-sm font-semibold text-right text-gray-900">%</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {setData.map((item, index) => (
          <tr
            key={`pie-chart-table-row-${item.name}-${index}`}
            className={classNames(highLightIndex !== null && highLightIndex === index ? 'bg-gray-50' : 'bg-transparent')}
          >
            <td className="px-2 py-4 text-sm font-medium text-gray-900">
              <div className="flex items-center space-x-2">
                {item.color != null && <div className="p-1.5 rounded-full" style={{ backgroundColor: item.color }} />}
                <span>{item.name}</span>
              </div>
            </td>
            <td className="px-2 py-4 text-sm text-right text-gray-500">{item.count}</td>
            <td className="px-2 py-4 text-sm text-right text-gray-500">{item.percentage}%</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PieChartTable;
