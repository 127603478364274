import { DESTINATION_OWNERS } from '@packages/constants';
import { useMemo, useState, VFC } from 'react';
import { useSessions } from '../../../contexts/useSessions';
import SearchBox from '../../Shared/SearchBox';
import SessionsList from '../../Shared/SessionsList';

const SessionsListSection: VFC = () => {
  const { sessions } = useSessions();

  const [search, setSearch] = useState<string | undefined>();

  function matchesDestinationName(unos: string) {
    const destination = DESTINATION_OWNERS.find(destination => destination.unos === unos);

    if (search == null || search.length === 0) {
      return true;
    }

    if (destination == null) {
      return false;
    }

    return destination.companyName.toLowerCase().includes(search.toLowerCase());
  }

  const filteredSessions = useMemo(() => {
    if (sessions == null) {
      return [];
    }

    return sessions.filter(session => {
      if (search == null || search.length === 0) {
        return true;
      }

      return (
        (session.id != null && session.id.toString().toLowerCase().includes(search.toLowerCase())) ||
        (session.joinKey != null && session.joinKey.toString().toLowerCase().includes(search.toLowerCase())) ||
        (session.unosId != null && session.unosId.toString().toLowerCase().includes(search.toLowerCase())) ||
        (session.loggerSerialNo != null && session.loggerSerialNo.toString().toLowerCase().includes(search.toLowerCase())) ||
        (session.advancedTrackerId != null && session.advancedTrackerId.toString().toLowerCase().includes(search.toLowerCase())) ||
        (session.destination != null && session.destination.toString().toLowerCase().includes(search.toLowerCase())) ||
        (session.destination != null && matchesDestinationName(session.destination))
      );
    });
  }, [sessions, search]);

  return (
    <div className="space-y-2">
      <SearchBox
        onChange={search => setSearch(search)}
        placeholder="Search for session ID, session key, logger SN, donor ID, advanced tracker ID or destination"
      />

      <SessionsList sessions={filteredSessions} />
    </div>
  );
};

export default SessionsListSection;
