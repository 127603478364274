import { Routes } from '@packages/constants';
import { Organization, OrganizationModelParams, OrganTypeUtil } from '@packages/firebase';
import { formatCountry } from '@packages/ui';
import { classNames } from '@packages/utils';
import { FC, useMemo, useState, VFC } from 'react';
import { createOrganization } from '../../../../lib/firebase/organizations';
import Alert from '../../../Shared/Alert';
import Button from '../../../Shared/Button';
import Modal from '../../../Shared/Modal';
import ImportDrop from './ImportDrop';

export type ImportOrganizationsModalProps = {
  organizations: Organization[];
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

type ShowImportDataStepProps = {
  data: ImportData[];
  onCancel: () => void;
};

type ImportDropStepProps = {
  organizations: Organization[];
  onData: (data: ImportData[]) => void;
  onCancel: () => void;
};

export type ImportData = {
  organization: Omit<OrganizationModelParams, 'id' | 'logoFileName' | 'logoUrl' | 'createdAt' | 'updatedAt' | 'validationStartedAt'>;
  errors: string[];
};

const ImportOrganizationsModal: VFC<ImportOrganizationsModalProps> = ({ organizations, isOpen, setIsOpen }) => {
  const [importData, setImportData] = useState<ImportData[] | undefined>();

  return (
    <div className="py-24">
      <Modal size="7xl" isOpen={isOpen} setIsOpen={setIsOpen}>
        {importData != null ? (
          <ShowImportDataStep data={importData} onCancel={() => setImportData(undefined)} />
        ) : (
          <ImportDropStep organizations={organizations} onData={data => setImportData(data)} onCancel={() => setIsOpen(false)} />
        )}
      </Modal>
    </div>
  );
};

const ShowImportDataStep: FC<ShowImportDataStepProps> = ({ data, onCancel }) => {
  const [isImporting, setIsImporting] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const importableData = useMemo(() => {
    return data.filter(elem => elem.errors.length === 0);
  }, [data]);

  const notImportableData = useMemo(() => {
    return data.filter(elem => elem.errors.length > 0);
  }, [data]);

  async function onImport() {
    if (importableData == null || importableData.length === 0) {
      return;
    }

    setIsImporting(true);

    for (const { organization, errors } of importableData) {
      if (errors.length > 0) {
        // eslint-disable-next-line no-continue
        continue;
      }

      // eslint-disable-next-line no-await-in-loop
      await createOrganization(organization);
    }

    setIsImporting(false);
    setIsComplete(true);
  }

  return (
    <>
      <div>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Data from Spreadsheet</h3>
          <p className="mt-1 text-sm text-gray-500">
            {importableData.length === 0 && (
              <span className="block text-red-700">
                There is not data that can be imported. There is either no data in the spreadsheet, or all of the rows had an error.
              </span>
            )}

            {importableData.length > 0 && (
              <>
                <span className="block">By clicking on the Import button below the following will happen:</span>
                <span className="block text-green-700">
                  A total of <span className="font-bold">{importableData.length}</span> organization(s) will be imported.
                </span>
                {notImportableData.length > 0 && (
                  <span className="block text-red-700">
                    A total of <span className="font-bold">{notImportableData.length}</span> organization(s) cannot be imported.
                  </span>
                )}
              </>
            )}
          </p>
        </div>

        <div className="px-6 overflow-scroll h-[700px]">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Name
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Handle
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Address
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Country
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Organs
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Types
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Domains
                </th>
                <th scope="col" className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Errors
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.map(({ organization, errors }, index) => (
                <tr key={`${organization.handle}-${index}`}>
                  <td
                    className={classNames(
                      errors.length > 0 ? 'text-red-700' : 'text-gray-500',
                      'py-2 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6',
                    )}
                  >
                    {organization.name ?? '-'}
                  </td>
                  <td
                    className={classNames(
                      errors.length > 0 ? 'text-red-700' : 'text-gray-900',
                      'px-2 py-2 text-sm font-medium whitespace-nowrap',
                    )}
                  >
                    {organization.handle ?? '-'}
                  </td>
                  <td className={classNames(errors.length > 0 ? 'text-red-700' : 'text-gray-900', 'px-2 py-2 text-sm whitespace-nowrap')}>
                    {organization.address ?? '-'}
                  </td>
                  <td className={classNames(errors.length > 0 ? 'text-red-700' : 'text-gray-500', 'px-2 py-2 text-sm whitespace-nowrap')}>
                    {organization.country != null ? formatCountry(organization.country) : '-'}
                  </td>
                  <td className={classNames(errors.length > 0 ? 'text-red-700' : 'text-gray-500', 'px-2 py-2 text-sm whitespace-nowrap')}>
                    {organization.organTypes != null && organization.organTypes.length > 0
                      ? organization.organTypes.map(organType => OrganTypeUtil.organString(organType)).join(', ')
                      : '-'}
                  </td>
                  <td className={classNames(errors.length > 0 ? 'text-red-700' : 'text-gray-500', 'px-2 py-2 text-sm whitespace-nowrap')}>
                    {organization.types != null && organization.types.length > 0 ? organization.types.map(type => type).join(', ') : '-'}
                  </td>
                  <td className={classNames(errors.length > 0 ? 'text-red-700' : 'text-gray-500', 'px-2 py-2 text-sm whitespace-nowrap')}>
                    {organization.domains != null && organization.domains.length > 0 ? organization.domains.join(', ') : '-'}
                  </td>
                  <td
                    className={classNames(
                      errors.length > 0 ? 'text-red-700' : 'text-gray-500',
                      'px-2 py-2 text-sm whitespace-nowrap font-bold',
                    )}
                  >
                    {errors != null && errors.length > 0 ? errors.join(' ') : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {isComplete && (
        <div className="px-4 pt-2">
          <Alert type="success" title="Data import done. You can close the dialog now." />
        </div>
      )}

      <div className="flex p-4 space-x-2 bg-white">
        <Button buttonType="button" styleType="light" onClick={() => onCancel()} isFullWidth>
          {!isComplete ? <span>Cancel</span> : <span>Close</span>}
        </Button>

        {!isComplete && (
          <Button
            buttonType="button"
            styleType="default"
            onClick={() => onImport()}
            isFullWidth
            disabled={importableData.length === 0}
            isLoading={isImporting}
          >
            <span>Import</span>
          </Button>
        )}
      </div>
    </>
  );
};

const ImportDropStep: FC<ImportDropStepProps> = ({ organizations, onData, onCancel }) => {
  return (
    <>
      <div>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Import from Spreadsheet</h3>
          <p className="mt-1 text-sm text-gray-500">
            Import organizations from an Excel or CSV spreadsheet. Below you can find the specification on what the spreadsheet should look
            like. You can also download a sample spreadsheet by clicking{' '}
            <a className="text-blue-500 underline" href="/organization_import_example.xlsx" target="_blank">
              here
            </a>
            . All properties that cannot be found in the specification below will be ignored by the import.
          </p>
        </div>
        <div className="px-4 py-5 border-t border-gray-200 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Name of the transplant center.</dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Handle</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                Unique identifier of the transplant center (e.g. ACME-TX1).
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Address</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Address of the transplant center.</dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Country</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                Country code of the transplant center.{' '}
                <a className="text-blue-500 underline" href={Routes.wmt.COUNTRIES} target="_blank" rel="noreferrer">
                  Here
                </a>{' '}
                you can find the list of valid country codes.
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Organs</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                Organ that the transplant center specializes in. This can be a comma separated list of organs (e.g. Heart, Lung, Liver,
                Kidney, Pancreas).
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Types</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                Category of the transplant center. This can be a comma separated list of types (e.g. Transplant Center, OPO, Procurement
                Service).
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Domains</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                Known domains that are used for user emails by the transplant center. This can be a comma separated list of domains (e.g.
                example.com, example2.com). This information will help create matching recommendations for users and sessions.
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div className="px-4 py-4 space-y-4">
        <ImportDrop organizations={organizations} onData={data => onData(data)} />

        <Button buttonType="button" styleType="light" onClick={() => onCancel()} isFullWidth>
          Cancel
        </Button>
      </div>
    </>
  );
};

export default ImportOrganizationsModal;
