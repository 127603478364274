import { SessionType, SessionTypeUtil } from '@packages/firebase';
import Badge, { BadgeStyleTypes } from '../../Badge';

function sessionTypeFormatter({ value }: { value: SessionType }) {
  if (value == null) {
    return (
      <Badge styleType="danger" size="xs">
        Unknown
      </Badge>
    );
  }

  const styleType: BadgeStyleTypes = value === SessionType.TRAINING ? 'session_type_training' : 'session_type_clinical';

  return (
    <Badge size="xs" styleType={styleType}>
      {SessionTypeUtil.toUIString(value)}
    </Badge>
  );
}

export default sessionTypeFormatter;
