"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  default: () => src_default,
  useTranslation: () => import_react_i18next.useTranslation
});
module.exports = __toCommonJS(src_exports);
var import_i18next = __toESM(require("i18next"));
var import_i18next_browser_languagedetector = __toESM(require("i18next-browser-languagedetector"));
var import_react_i18next = require("react-i18next");

// src/locales/en/translation.json
var translation_default = {
  app_name: "Paragonix",
  language_key: "en",
  session: "Session",
  logger: "Logger",
  tracking: "Tracking",
  settings: "Settings",
  unknown: "Unknown",
  tutorials: "Tutorials",
  tutorial_chapter: "Chapter %d",
  cancel: "Cancel",
  ok: "OK",
  documents: "Documents",
  links: "Links",
  support: "Support",
  facebook: "Facebook",
  twitter: "Twitter",
  linkedin: "LinkedIn",
  youtube: "YouTube",
  login: "Login",
  firstname: "First name",
  lastname: "Last name",
  organization: "Organization",
  role: "Role",
  email: "Email",
  password: "Password",
  registration_terms_text: "By signing up you agree to our\\n%s",
  login_terms_text: "By signing in you agree to our\\n%s ",
  privacy_policy: "Privacy Policy",
  login_text: "Already have an account? %s",
  register_text: "No account yet? %s",
  reset_password_text: "Remember it? %s",
  register: "Register",
  intro_text: "Thank you for downloading the Paragonix app!\\n In order to use all features of the app, please register or login.",
  invalid_email: "The email address is incorrectly formatted.",
  weak_password: "The password must be 6 characters long or more.",
  email_already_in_use: "The provided email address is already in use.",
  wrong_credentials: "The provided credentials are incorrect.",
  generic_error_message: "Oops! Something went wrong. Please try again later or contact our support.",
  more: "More",
  logout: "Logout",
  logout_confirmation_text: "Do you really want to logout?",
  disclaimer_text: "Please refer to the instructions for use for full prescribing information including Indications for use, contraindications, precautions and warnings.",
  download_notification_channel_name: "Downloads",
  download_notification_channel_description: "Shows notifications when a download of offline content is running.",
  download_notification_title: "Downloading \u2026",
  download_notification_message: "Download of offline content is running.",
  session_notification_channel_name: "Session updates",
  session_notification_channel_description: "Notifications regarding your current session.",
  logger_notification_channel_name: "Logger connection",
  logger_notification_channel_description: "Information about the connection to the logger.",
  select_device_title: "Device Type",
  select_device_description: "Please select the type of device you want to connect to below.",
  select_button_text: "Select product",
  session_timeline_title: "Timeline",
  add_timeline_status_button_title: "Add status",
  add_to_timeline_button_title: "Add to Timeline",
  join_session: "Join Session",
  create_session: "Create Session",
  stop_session: "Stop Session",
  leave_session: "Remove from Archive",
  invite_to_session: "Invite Members",
  permission_bluetooth_title: "Allow Bluetooth",
  permission_bluetooth_text: "Bluetooth is required in order to connect to your Paragonix device and publish the data to other session members.",
  permission_bluetooth_error: "Please allow us to use Bluetooth to continue.",
  permission_location_title: "Allow Location Services",
  permission_location_text: 'Please allow us to use Location Services, so we can publish the current location of the device to other session members. Please note that the mode "%s" is required.',
  permission_location_always_allow: "Always",
  permission_location_allow_while_in_use: "Allow While Using App",
  permission_location_error: 'Please use "Always" location mode to track a session.',
  permission_notifications_title: "Allow notifications",
  permission_notifications_text: "If you allow us to send push notification, you will be updated with important events of your session.",
  allow_ignore_battery_optimization_title: "Background Work",
  allow_ignore_battery_optimization_text: "The app needs to be exempt from standby optimizations in order to maintain a connection to the Logger.",
  allow_ignore_battery_optimization_button_title: "Allow app to run in background",
  allow_ignore_battery_error: "Please allow us this permission to continue.",
  permission_location_text_android: "Please allow us to use Location Services, so we can publish the current location of the device to other session members.",
  permission_scan_title: "Allow Device Discovery",
  permission_scan_text: "Device Discovery Permission is needed in order to find a neary Paragonix device.",
  permission_scan_error: "Please allow us to Device Discovery Permission to continue.",
  permission_location_button_title_android: "Allow",
  session_end_dialog_title: "End Session?",
  session_end_dialog_text: "Are you sure you want to end the session? Ending the session disconnects from logger, permanently clears all chats, and archives the temperature logs and timeline data.",
  session_stop_now_button_title: "Stop now",
  session_chat: "Chat",
  message_decrypt_error: "This message could not be decrypted.",
  chat_message_input_hint: "Your Message\u2026",
  session_members_title: "Members",
  logger_selection_screen_title: "Device selection",
  rssi_quality_excellent: "Excellent signal",
  rssi_quality_great: "Great signal",
  rssi_quality_good: "Good signal",
  rssi_quality_bad: "Bad signal",
  rssi_quality_no: "No signal",
  connection_state: "Connection state",
  logger_state: "Logger state",
  battery: "Battery",
  signal_quality: "Signal quality",
  logger_serial_no: "SN: %s",
  chirp: "Chirp",
  passkey_title: "Enter passkey code",
  passkey_subtitle: "Please enter the passkey code to connect to Paragonix device with serial number %s. You can find the code on a printed label at back of the Shipper.",
  passkey_placeholder: "Enter code",
  join_title: "Enter code",
  join_subtitle: "Please enter the session code",
  join_placeholder: "Enter code",
  session_empty_title: "No active session",
  session_empty_subtitle: "Create a new session or join a session to get started.",
  data_last_updated: "Data last updated at:",
  stop_logging: "Stop Logging",
  probe: "Probe",
  ambient: "Ambient",
  stop_logging_alert_title: "Stop Logging?",
  stop_logging_alert_text: "Once the logger is stopped, it is not possible to restart it.",
  stop_logging_yes_btn: "Stop",
  stop_logging_disconnected_alert_title: "Logger not connected",
  stop_logging_disconnected_alert_text: "Unable to stop the logger while it is disconnected. Please stop the logger manually or try again.",
  session_leave_dialog_title: "Remove Session from Archive?",
  session_leave_dialog_text: "Are you sure to remove the session permanently and lose access to all session data?",
  session_leave_dialog_phone_additional_text: "Alternatively, just close the session and access it again from Archived Sessions.",
  session_leave_dialog_yes_btn: "Remove",
  close_session: "Close Session",
  logger_not_connected: "No Logger is currently connected to the session.",
  logger_has_been_stopped_infotext: "Logging has been stopped by the administrator.",
  authentication_error: "Authentication Error",
  start_logger_title: "Start Logger",
  start_logger_text: "Please start the logger. NOTE: A logger can only be started once, and cannot be restarted once stopped.",
  connect_logger_title: "Connect Logger",
  connect_logger_text: "Only one Logger can be connected to a session.",
  invite_members_text: "Invite contacts to join your session to communicate and share your location and temperature data. Members can be added now or at any point later in the session",
  skip: "Skip",
  connect_stopped_logger_message: "This logger was already used before and can't be selected for this session.",
  invitation_text: "%s is inviting you to join a session in the Paragonix App. Your personal invitation code is: %s, or you can join by using this link: %s",
  report: "Report",
  session_update_start_session: "Session Started",
  session_update_leave_tx_center: "Departed Recipient Center",
  session_update_plane_departure_recipient: "Take-off at Recipient Airport",
  session_update_plane_arrival_donor: "Landed at Donor Airport",
  session_update_arrival_at_donor: "Arrived at Donor Center",
  session_update_donor_in_room: "Donor In-room",
  session_update_heparin_administered: "Heparin Administered",
  session_update_time_of_withdrawal: "Time of Withdrawal",
  session_update_warm_ischemic_time_start: "Warm Ischemic Time Start",
  session_update_observation_period_start: "Observation Period Start",
  session_update_time_of_death: "Time of Death",
  session_update_time_to_incision: "Time of Incision",
  session_update_crossclamp_at_donor: "Donor Crossclamp",
  session_update_perfusion_start: "Start Aortic Flush",
  session_update_perfusion_stop: "End Aortic Flush",
  session_update_organ_removal: "Heart Removal",
  session_update_organ_removal_pancreas: "Pancreas Removal",
  session_update_organ_removal_lung: "Lung Removal",
  session_update_lung_placed_in_lungguard: "Lung(s) Placed into {{transportDeviceName}}",
  session_update_liver_placed_in_liverguard: "Liver Placed into LIVERguard\u2122",
  session_update_heart_placed_in_sherpapak: "Heart Placed into SherpaPak\xAE CTS",
  session_update_start_logger: "Logger Started",
  session_update_leave_donor: "Departed Donor Center",
  session_update_plane_departure_donor: "Take-off at Donor Airport",
  session_update_plane_arrival_recipient: "Landed at Recipient Airport",
  session_update_arrive_at_recipient: "Arrived at Recipient Center",
  session_update_lungs_removed_from_lungguard: "Lungs Removed from {{transportDeviceName}}",
  session_update_left_lung_removed_from_lungguard: "Left Lung Removed from {{transportDeviceName}}",
  session_update_right_lung_removed_from_lungguard: "Right Lung Removed from {{transportDeviceName}}",
  session_update_left_lung_anastomosis_complete: "Left Lung Anastomosis Complete",
  session_update_right_lung_anastomosis_complete: "Right Lung Anastomosis Complete",
  session_update_stop_logger: "Logger Stopped",
  session_update_crossclamp_at_recipient: "Recipient Off Clamp",
  session_update_recipient_left_lung_off_clamp: "Recipient Left Lung Off Clamp",
  session_update_recipient_right_lung_off_clamp: "Recipient Right Lung Off Clamp",
  session_update_stop_session: "Session Stopped",
  session_update_organ_accepted: "Organ Accepted",
  session_update_organ_declined: "Organ Declined",
  session_update_heart_removed_from_sherpapak: "Heart Removed from SherpaPak\xAE CTS",
  session_update_organ_removal_liver: "Liver Removal",
  session_update_pancreas_placed_in_pancreaspak: "Pancreas Placed Into PancreasPak\u2122",
  session_update_pancreas_removed_from_pancreaspak: "Pancreas Removed From PancreasPak\u2122",
  session_update_liver_removed_from_liverguard: "Liver Removed from LIVERguard\u2122",
  session_update_portal_reperfusion: "Portal Reperfusion",
  session_update_arterial_reperfusion: "Arterial Reperfusion",
  session_update_donor_final_blood_urea_nitrogen: "Donor Final Blood Urea Nitrogen",
  session_update_donor_final_serum_creatinine: "Donor Final Serum Creatinine",
  session_update_donor_kidney_donor_profile_index: "Donor Kidney Donor Profile Index",
  session_update_withdrawal_support: "Withdrawal of Support",
  session_update_start_agonal_phase: "Start of Agonal Phase",
  session_update_start_flush: "Start Flush",
  session_update_x_clamp: "X Clamp",
  session_update_right_kidney_removal: "Right Kidney Removal",
  session_update_left_kidney_removal: "Left Kidney Removal",
  session_update_right_kidney_placed_in_device: "Right Kidney placed into {{transportDeviceName}}",
  session_update_left_kidney_placed_in_device: "Left Kidney placed into {{transportDeviceName}}",
  session_update_right_kidney_accepted: "Right Kidney Accepted",
  session_update_right_kidney_declined: "Right Kidney Declined",
  session_update_left_kidney_accepted: "Left Kidney Accepted",
  session_update_left_kidney_declined: "Left Kidney Declined",
  session_update_right_kidney_handed_off_to_courier: "Right Kidney handed off to Courier/Air",
  session_update_left_kidney_handed_off_to_courier: "Left Kidney handed off to Courier/Air",
  session_update_right_kidney_arrived_at_txc_opo: "Right Kidney arrived at TXC or OPO",
  session_update_left_kidney_arrived_at_txc_opo: "Left Kidney arrived at TXC or OPO",
  session_update_right_kidney_out_of_device: "Right Kidney out of {{transportDeviceName}}",
  session_update_left_kidney_out_of_device: "Left Kidney out of {{transportDeviceName}}",
  session_update_right_kidney_artery_anastomosis: "Right Kidney Artery Anastomosis",
  session_update_right_kidney_vein_anastomosis: "Right Kidney Vein Anastomosis",
  session_update_right_kidney_ureter_anostomosis: "Right Kidney Ureter anostomosis",
  session_update_left_kidney_artery_anastomosis: "Left Kidney Artery Anastomosis",
  session_update_left_kidney_vein_anastomosis: "Left Kidney Vein Anastomosis",
  session_update_left_kidney_ureter_anostomosis: "Left Kidney Ureter anostomosis",
  session_update_head_vessel_clamp: "Head Vessel Clamp/Ligate",
  session_update_on_cardio_pulmonary_bypass_1: "On Cardio Pulmonary Bypass 1",
  session_update_start_wean_1: "Start Wean 1",
  session_update_off_cardio_pulmonary_bypass_1: "Off Cardio Pulmonary Bypass 1",
  session_update_on_cardio_pulmonary_bypass_2: "On Cardio Pulmonary Bypass 2",
  session_update_start_wean_2: "Start Wean 2",
  session_update_off_cardio_pulmonary_bypass_2: "Off Cardio Pulmonary Bypass 2",
  session_update_anastomosis: "Anastomosis",
  session_update_cold_preservation_flush: "Cold Preservation Flush",
  session_update_cannulation: "Cannulation",
  session_update_unknown: "Unsupported Event (Please update the app!)",
  min: "Min",
  minimum: "Minimum",
  average: "Average",
  max: "Max",
  maximum: "Maximum",
  started: "Started",
  finished: "Finished",
  duration: "Duration",
  distance: "Distance",
  timeline_event: "Event",
  timeline_no_event: "No Event",
  timeline_no_events_title: "No Events in Timeline",
  timeline_no_events_text: "All timeline events will be shown here once they occur.",
  timeline_date_now: "Now",
  timeline_date_today: "Today",
  timeline_date_tomorrow: "Tomorrow",
  timeline_date_yesterday: "Yesterday",
  timeline_date_and_time: "Date & Time",
  timeline_date: "Date",
  timeline_time: "Time",
  timeline_add_to_timeline: "Add to Timeline",
  timeline_save_date_and_time: "Save date and time",
  view_all: "View All",
  delete: "Delete",
  support_number: "Call Support Hotline",
  downloads: "Downloads",
  archived_sessions: "Archived Sessions",
  general_information: "Website",
  logger_reading: "Temperatures",
  logger_event: "Logger Event",
  export_header_event_name: "Event",
  export_header_event_value: "Value",
  export_header_time: "Time",
  export_header_probe_temperature: "Probe Temperature",
  export_header_ambient_temperature: "Ambient Temperature",
  export_header_pressure: "Pressure",
  export_header_pump_voltage: "Pump Voltage",
  export_header_description: "Description",
  export_error_message: "The data could not be exported. Please try again.",
  export_error_message_alt: "Export for session %s failed.",
  export_success_message: "Export done! %s CSV files exported.",
  support_clinical_us_number: "+1 (781) 428 4828",
  support_clinical_us_email: "clinical@paragonixtechnologies.com",
  empty_archived_sessions_title: "You have no archived sessions",
  empty_archived_sessions_text: "They will appear once you have participated in a tracking session, and that session is finished.",
  more_user_info: "Logged in as: %s",
  more_version_info: "Version: %s",
  empty_downloads_title: "No downloaded files",
  empty_downloads_text: "Download files from Documents or Tutorials to have them available offline. ",
  export: "Download Session Report",
  export_sessions_table: "Download Sessions Table",
  done: "Done",
  session_notification_title: "Active Session",
  session_notification_subtitle: "Connection quality: %s",
  session_member_joined_at: "Joined at: %s",
  session_pending_full_read_title: "Keep Logger connected",
  session_pending_full_read_text: "Waiting for complete data read from Logger.",
  forgot_password: "Forgot password?",
  please_enter_your_email: "Please enter your email address",
  reset_password_failed: "Password reset failed, please try again.",
  reset_password_success: "Reset password email sent, check your mailbox.",
  chat_unavailable_no_members: "Invite users to the session in order to use the chat.",
  invitation_key_text: "Invitation key: %s",
  support_mail: "Mail support",
  registration_password_invalid_length: "Password must contain at least six characters.",
  registration_password_invalid_number: "Password must contain at least one number (0-9).",
  registration_password_invalid_lowercase: "Password must contain at least one lowercase letter (a-z).",
  registration_password_invalid_uppercase: "Password must contain at least one uppercase letter (A-Z).",
  registration_password_invalid_userdata: "Password must be different from already provided userdata.",
  logout_session_running: "It is not possible to logout during a running session. Please stop your session and try again.",
  paragonix_support_action: "Contact Paragonix Support",
  paragonix_support_subject: "Support Case: #%s",
  admin_disconnected_text: "Your have a bad internet connection. Data may not be published to other session members.",
  session_join_deeplink_error: "You could not join the session because you already have a running session.",
  key: "Key",
  last_session: "Last session",
  logger_serial_number: "Logger serial number",
  session_key: "Session key",
  message_send_error: "Your message could not be sent. ",
  delete_archived_session_dialog_title: "Delete?",
  open_settings: "Open Settings",
  open_settings_text: "Please change the permission in Settings",
  grant_permission: "Grant permission",
  logger_connect: "Connect",
  timeline_entry: "Timeline Entry",
  scan_device: "Scanning for devices",
  scan_devices_message: "Currently no loggers found ",
  connect_stopped_logger_title: "Attention",
  connect: "Connect",
  connecting: "Connecting",
  authenticate: "Authenticate",
  registration: "Registration",
  product_selection_text: "Please choose the Paragonix product you\u2019d like to connect to.",
  session_created_success: "Your session was successfully created",
  session_join_success: "The session was successfully joined",
  session_stop_success: "The session was stopped",
  continue_label: "Continue",
  start_logger_alert_title: "Start Logger?",
  start_logger_alert_subtitle: "Are you sure you want to start the Logger? This cannot be undone.",
  logger_state_stopped: "Stopped",
  logger_state_ready: "Ready",
  logger_state_logging: "Logging",
  logger_state_powered: "Power Reset",
  enter_passkey: "Please enter the passkey",
  authenticated: "Authenticated",
  not_available: "Not available",
  start_now: "Start Now",
  battery_level_low: "Low",
  battery_level_okay: "Okay",
  battery_level_good: "Good",
  logger_connection_state_disconnected: "Disconnected",
  logger_connection_state_connected: "Connected",
  logger_connection_state_authenticated: "Authenticated",
  logger_connection_state_auth_error: "Authentication Error",
  session_admin_tag: "Admin",
  session_pending_tag: "Pending",
  make_admin_request_title: "Make admin",
  cancel_admin_request_title: "Cancel admin switch",
  pending_admin_request_title: "You were requested as session admin.",
  accept_admin_request: "Accept",
  decline_admin_request: "Decline",
  pending_admin_switch_request: "Admin switch request is pending.",
  member_actions: 'Actions for "%s"',
  enable_location_prompt_title: "Location Services disabled",
  enable_location_prompt_text: "Location Services are required in order to scan for Loggers.",
  accept_terms_button_title: "Accept",
  decline_terms_button_title: "Decline",
  terms_approval_title: "Consent required",
  terms_approval_text: "In order to use the Paragonix app we need you to accept our Terms of Use and Privacy Policy. Please examine both documents carefully before giving your consent. Once you have accepted, you can continue using the app, otherwise please logout or contact our support.",
  terms_of_use: "Terms of Use",
  contact_support: "Contact Clinical Support",
  create_session_for_device: "Create a session for %s",
  connect_device: "Connect Device",
  device: "Device",
  eta_title: "ETA at Recipient Center",
  eta_title_appendix: "(per Administrator)",
  eta_in: "in %s",
  eta_since: "since %s",
  eta_not_set: "Not set",
  eta_remove: "Remove",
  eta_edit: "Edit",
  eta_edit_title: "Edit ETA",
  eta_add: "Add ETA at Recipient Center",
  flight_number: "Flight %s",
  flight_from_to: "%s to %s",
  flight_add: "Add Flight",
  flight_search: "Search Flights",
  flight_number_placeholder: "Enter Flight or Tail number (e.g. UAL601 or N456XX)",
  add_flight_success: "Flight added!",
  flight_delete: "Delete This Flight",
  flight_status_scheduled: "Scheduled",
  flight_status_in_air: "In Air",
  flight_status_landed: "Landed",
  flight_error_search: "An error occured while searching your flight. Please check your flight number and try again.",
  flight_error_add: "An error occured while adding your flight. Please try again later.",
  flight_error_delete: "An error occured while removing your flight. Please try again later.",
  flight_select_title: "Select your Flight",
  flight_times_not_available: "Flight times not available",
  generic_information_title: "Generic Information",
  donation_type: "Type of Donation",
  donation_type_selection_description: "Please select the Type of Donation for your session. DCD session will show additional timeline entries.",
  dbd: "DBD",
  dbd_description: "Donation after Brain Death",
  dcd: "DCD",
  dcd_description: "Donation after Circulatory Death",
  ld: "LD",
  ld_description: "Living Donor",
  whats_new_title: "What's new in",
  back: "Back",
  invite_now: "Invite now",
  join_key: "Join Key:",
  tap_to_copy: "Tap to copy",
  join_key_copy_success: "The session key was copied to the Pasteboard.",
  permission_missing: "Important Permissions are missing.",
  permission_missing_btn_title: "Grant Permissions",
  get: "Get",
  session_info: "Info",
  chat: "Chat",
  session_creator: "Creator",
  setting_include_timezone: "Include Timezone",
  setting_force_24_hours: "Force 24-Hour Format",
  theme_system: "Automatic",
  theme_light: "Light Mode",
  theme_dark: "Dark Mode",
  appearance: "Appearance",
  other: "Other",
  app_icons: "App Icons",
  session_type: "Session Type",
  session_type_text: "Please select the type of your session. Training session information will not be available in archived session data.",
  session_type_clinical: "Clinical Case",
  session_type_training: "Training Session",
  session_type_unknown: "Unknown",
  forgot_pin: "Forgot PIN?",
  enter_pin: "Enter PIN",
  create_pin: "Create PIN",
  create_pin_title: "4-Digit PIN Code",
  create_pin_text: "As the chat function is end-to-end encrypted, a PIN code is required as soon as you install Paragonix on your device or on other devices.",
  repeat_pin_title: "Repeat PIN Code",
  repeat_pin_text: "Please re-enter your code to ensure you have entered the correct code and not mistyped it.",
  finish: "Finish",
  enter_pin_title: "Enter 4-Digit PIN Code",
  enter_pin_text: "Please enter your PIN code that you created last time. This ensures that messages from the chats can only be seen between you and the recipients.",
  reset_pin_title: "Reset PIN?",
  reset_pin_text: "Are you sure you want to reset the PIN? This action cannot be undone.",
  reset: "Reset",
  wrong_pin_error_message: "The entered PIN was not correct.",
  general_pin_error_message: "There was an error verifying your PIN.",
  send_by: "by %s",
  new_message: "New message!",
  new_member: "New member!",
  joined: "%s joined",
  new_eta: "New ETA: %s",
  live: "Live",
  dashboard: "Dashboard",
  min_abbrevation: "Min.",
  avg_abbrevation: "Avg.",
  max_abbrevation: "Max.",
  temperature_abbrevation: "Temp.",
  disconnect_logger: "Disconnect Logger",
  disconnect_and_stop: "Stop Logger",
  disconnect: "Disconnect",
  please_choose: "Please Choose:",
  connected_logger: "Connected Logger: %s",
  support_mail_info: "Email Paragonix Technologies Support Team for questions or comments",
  support_hotline_info: "For urgent support please contact our Customer Support Hotline",
  error_message_timout: "The operation timed out. Please try again.",
  error_message_wrong_logger_state: "The logger cannot be connected because it is in the wrong state.",
  connect_powered_logger_message: "This logger is not in a correct state and needs to be configured. For further information please contact support.",
  connect_powered_logger_title: "Attention",
  session_end_dialog_empty_title: "End Session?",
  session_end_dialog_empty_text: "Are you sure you want to end the session? This session has no log entries and will be deleted when stopped.",
  session_end_dialog_logging_title: "End Session?",
  session_end_dialog_logging_text: "Are you sure you want to end the session, because the logger is currently running. Ending the session disconnects from logger, permanently clears all chats, and archives the temperature logs and timeline data.",
  session_end_dialog_incomplete_title: "End Session?",
  session_end_dialog_incomplete_text: "Are you sure you want to end the session, because there is some data on the logger, which is not downloaded.",
  session_logger_info_self: "Currently You are connected to the logger with the serial number %s",
  session_logger_info_self_ohter_device: "Currently You are connected with another device to the logger with the serial number %s",
  session_logger_info_other: "Currently %s is connected to the logger with the serial number %s",
  session_logger_info_self_you: "You",
  session_logger_info_other_someone: "someone",
  ischemic_time_title: "Ischemic Time",
  ischemic_time_hours: "hh",
  ischemic_time_minutes: "mm",
  ischemic_time_seconds: "ss",
  document_page_info: "Page %d of %d",
  sessions: "Sessions",
  take_over_logger: "Claim Logger",
  take_over_dialog_title: "Claim Logger",
  take_over_dialog_text: "Do you want to claim the logger now? Please note, the Bluetooth connection from the logger to the previous device has to be terminated before in order to discover the logger.",
  take_over_dialog_confirm_button_title: "Yes",
  location: "Current Location",
  single_logger_restriction_title: "Already logging",
  single_logger_restriction_text: "You are already connected to a logger in another session. It's not possible to connect multiple loggers at the moment.",
  legal: "Legal",
  settings_marketing_mails: "Stay Informed on Paragonix Updates",
  settings_session_report_mails: "Receive session report emails",
  marketing_mails_title: "Paragonix News & Updates",
  marketing_mails_info: "Paragonix Technologies is committed to protecting and respecting your privacy, and we\u2019ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please check the box below. You can unsubscribe from these communications at any time. For more information on our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.",
  downloads_delete_all: "Delete All",
  downloads_delete_all_confirmation: "Do you really want to delete all your locally stored Documents and Tutorials?",
  paste_from_clipboard: "Paste from clipboard",
  session_state_created: "Started",
  session_state_finished: "Finished",
  session_state_logger_started: "Logging",
  session_state_logger_stopped: "Logger Stopped",
  session_state_unknown: "Unknown",
  organ: "Organ",
  organ_heart: "Heart",
  organ_liver: "Liver",
  organ_lung: "Lung",
  organ_kidney: "Kidney",
  organ_pancreas: "Pancreas",
  sherpapak_product_heading: "SherpaPak\xAE CTS",
  liverguard_product_heading: "LIVERguard\u2122",
  lungguard_product_heading: "LUNGguard\u2122",
  baroguard_product_heading: "BAROguard\u2122",
  pancreaspak_product_heading: "PancreasPak\u2122",
  kts_product_heading: "Kidney Transport System",
  vantage_point_product_heading: "Transport Device",
  session_state: "State",
  date: "Date",
  email_placeholder: "Enter Email",
  password_placeholder: "Enter Password",
  firstname_placeholder: "Enter First Name",
  lastname_placeholder: "Enter Last Name",
  organization_placeholder: "Enter Organization",
  role_placeholder: "Enter Role",
  date_time_separator: "at",
  product_type: "Product Type",
  joined_at: "Joined at",
  name: "Name",
  join_key_copy_text: "Copy the join key and share it with future members of this session",
  leave_session_text: "Click the button to remove the Session. This cannot be undone and you will lose access to all session data permanently.",
  leave_session_error_message: "Unable to remove Session: %s",
  leave_session_success_message: "Removed Session successfully.",
  yes: "Yes",
  no: "No",
  accept: "Accept",
  decline: "Decline",
  meta_title_accept_terms: "Accept Terms",
  meta_title_dashboard: "Dashboard",
  meta_title_join_session: "Join Session",
  meta_title_reset_password: "Reset Password",
  meta_title_session_detail: "Session %s",
  meta_title_setup_organization: "Setup Organization",
  meta_title_sessions: "Sessions",
  meta_title_login: "Log In",
  meta_title_register: "Register",
  meta_title_support: "Support",
  meta_title_download_session_report: "Download Session Report",
  meta_title_not_found: "Page not found",
  session_panel_key_title: "Session Key",
  session_panel_started_title: "Session started",
  session_panel_stopped_title: "Session stopped",
  logger_panel_serial_number_title: "Logger Serial Number",
  logger_panel_started_title: "Logger started",
  logger_panel_stopped_title: "Logger stopped",
  dashboard_empty_state_title: "No Sessions",
  dashboard_empty_state_subtitle: "Create a new session or join a session to get started.",
  session_temperature_details_title: "Session Temperature Details",
  session_pressure_details_title: "Session Pressure Details",
  view_details: "View Details",
  sidebar_menu: "Menu",
  sidebar_live_sessions: "Live Sessions",
  settings_date_and_time_title: "Date & Time",
  settings_distance_unit_title: "Distance Unit",
  settings_distance_unit_metric: "Metric",
  settings_distance_unit_imperial: "Imperial",
  settings_marketing_mails_title: "Marketing Mails",
  settings_session_report_mails_title: "Session Report Mails",
  settings_save_error_message: "Saving Settings failed: %s",
  settings_save_success_message: "Saved Settings",
  latest_sessions: "Latest Sessions",
  live_session_tracking: "Live Session Tracking",
  search_keys: "Search Keys",
  session_join_error: "Unable to join Session: %s",
  remember_me: "Remember Me",
  login_title: "Welcome back!",
  login_subtitle: "Thank you for using the Paragonix web app! In order to use all features of the web app, please register or login.",
  register_title: "Register Account",
  register_subtitle: "Thank you for choosing the Paragonix web app! In order to use all features of the web app, please register.",
  reset_password_title: "Reset Password",
  reset_password_subtitle: "Enter your Email and instructions will be sent to you!",
  session_latest_timeline_event_title: "Latest Timeline Event",
  session_temperature_timeline_title: "Temperature Timeline",
  session_pressure_timeline_title: "Pressure Timeline",
  accept_terms_of_use_text: "Accept Paragonix Terms of Use",
  accept_privacy_policy_text: "Accept Paragonix Privacy Policy",
  next: "Next",
  to_dashboard: "Go to Dashboard",
  to_session: "Go to Session",
  email_support: "Email Support",
  call_support: "Call Support",
  dismiss: "Dismiss",
  notification_type_eta_update_text: "New ETA set by %s",
  notification_type_new_member_text: "New Member: %s",
  notification_type_timeline_update_text: "Timeline Update: %s",
  close: "Close",
  save: "Save",
  cookie_banner_text: "This website stores cookies on your computer. These cookies are used to improve your website experience and provide more personalized services to you, both on this website and through other media. To find out more about the cookies we use, see our Privacy Policy. We won't track your information when you visit our site. But in order to comply with your preferences, we'll have to use just one tiny cookie so that you're not asked to make this choice again.",
  page_not_found: "Page not found",
  video_tag_not_supported: "Video tag not supported by your browser.",
  product_metrics_title: "Product Details",
  product_metrics_all_title: "All Cases",
  product_metrics_temperature: "Temperature",
  product_metrics_distance: "Distance",
  product_metrics_session_duration: "Session Duration",
  product_metrics_logging_duration: "Logging Duration",
  product_metrics_ischemic_time: "Ischemic Time",
  feature_multiple_sessions_title: "Multiple Sessions",
  feature_multiple_sessions_text: "You can now participate in multiple live sessions simultaneously.",
  feature_multiple_devices_title: "Multiple Devices",
  feature_multiple_devices_text: "Phone, Tablet or Desktop. You can use the app on multiple devices with the same account.",
  feature_new_design_title: "New Design",
  feature_new_design_text: "The whole look and feel of the app was updated including our brand new dark mode.",
  feature_liverguard_title: "Paragonix LIVERguard\u2122 Support",
  feature_liverguard_text: "This update adds full session support for the Paragonix LIVERguard\u2122.",
  feature_vantage_point_title: "VantagePoint\u2122 Tracking",
  feature_vantage_point_text: "Track the location of your Paragonix device and donor organ via VantagePoint GPS tracker and advanced tracking technology",
  feature_flight_aware_title: "Real-time Flight Tracking",
  feature_flight_aware_text: "Track the location of your Paragonix device and donor organ while on a flight and get up-to-date flight information.",
  feature_stream_chat_title: "All-New Chat Functionality",
  feature_stream_chat_text: "New HIPAA compliant chat adds support for multimedia items like images, videos or documents.",
  archive_clinical_cases: "Clinical Cases",
  archive_training_sessions: "Training Sessions",
  archive_legacy_sessions: "Unspecified Sessions",
  archive_legacy_sessions_explanation: "These session were recorded with older versions of the app and have no distincation between clinical case or training session",
  disconnect_logger_confirmation_text: "Do you really want to disconnect the logger?",
  chart_hover_relative_time_info: "+ %1$dh %2$dm",
  app_training: "App Training",
  session_insufficient_readings_title: "Waiting for temperature readings",
  session_insufficient_readings_text: "Once there are sufficient readings the temperature timeline chart will be displayed here.",
  session_map_no_active_sessions_title: "No active Sessions",
  download_session_report_heading: "Your latest Paragonix {{productHeading}} case",
  download_session_report_text: "Thank you for using the Paragonix {{productHeading}} for your latest transplantation case. The session report will be downloaded automatically, if not please click the download button below.",
  distance_kilometers: "Kilometers",
  distance_miles: "Miles",
  distance_system: "Automatic",
  report_disclaimer: "Timeline entries for %s and %s are required to generate results.",
  report_mail_headline: "Automated E-Mail Case Reports",
  report_mail_title: "E-Mail Reports",
  report_mail_optin: "Opt-in to E-Mail Reports",
  report_mail_description: "Enable this feature to receive reports by email after a session ends. These emails contain a temperature graph and additional session data.",
  report_mail_footer: "You can toggle Email Case Reports in the App Settings",
  settings_delete_account: "Delete Account",
  settings_delete_text: "Once you delete your account it can't be restored. You'll lose access to past sessions and all gathered data.",
  settings_delete_confirm_title: "Are you sure?",
  settings_delete_confirm_text: "Your account and all data will be deleted permanently. This action cannot be undone.",
  chat_deprecation_warning: "This session uses an outdated version of the Paragonix chat which is no longer supported. Please make sure session creators use the latest version of the app to use the new chat.",
  advanced_tracking: "VantagePoint\u2122 Tracking",
  scan_barcode: "Scan Barcode",
  activate_advanced_tracking: "Activate VantagePoint\u2122 Tracking",
  enter_tracker_id: "Enter VantagePoint\u2122 Identifier",
  activate_advanced_tracking_error: "An error occured while activating VantagePoint\u2122 Tracking. Please check your Identifier and try again.",
  camera_permission_missing_title: "Camera permission required!",
  camera_permission_missing_text: "In order to scan codes, please grant permission for the Camera in the Settings App.",
  advanced_tracking_active: "VantagePoint\u2122 Tracking Active",
  activate_advanced_tracking_success: "VantagePoint\u2122 Tracking activated!",
  missing_location_map_overlay: "No current location available",
  unos_id: "Donor ID",
  add_unos_id: "Add Donor ID",
  save_unos_id: "Save Donor ID",
  unos_id_skip_description: "Assign an unique Donor ID to your session. This is optional and can also be added later in the session.",
  unos_id_placeholder: "Enter Donor ID",
  no_unos_id: "No Donor ID added",
  edit_unos_id: "Edit Donor ID",
  delete_unos_id: "Delete Donor ID",
  no_vantage_point_location: "No location was delivered from VantagePoint\u2122 Tracking yet. This can take several minutes.",
  unos_id_lenght_hint: "Must be 6 or 7 characters long.",
  mute_session: "Mute Notifications for Session",
  unmute_session: "Unmute Notifications for Session",
  mute_session_message: "Notfications for this Session are muted!",
  unmute_session_message: "Notfications for this Session are unmuted!",
  feature_mute_title: "Mute Session",
  feature_mute_text: "You will not receive push notifications for muted sessions.",
  feature_unos_id_title: "Donor IDs",
  feature_unos_id_text: "Attach Donor IDs to your sessions.",
  organization_category_transplant_center: "Transplant Center",
  organization_category_opo: "OPO",
  organization_category_procurement_service: "Procurement Service"
};

// src/index.ts
var resources = {
  en: {
    translation: translation_default
  }
};
import_i18next.default.use(import_i18next_browser_languagedetector.default).use(import_react_i18next.initReactI18next).init({
  resources,
  fallbackLng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});
var src_default = import_i18next.default;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  useTranslation
});
