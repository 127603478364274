import { createContext, useContext } from 'react';

const ProductColorContext = createContext<{ default: string; light: string }>({ default: '#000000', light: 'ffffff' });

function useProductColor() {
  const context = useContext(ProductColorContext);

  if (context == null) {
    throw Error('Color must be used inside color context');
  }

  return context;
}

export { ProductColorContext, useProductColor };
