import {
  DonationType,
  DonationTypeUtil,
  OrganType,
  OrganTypeUtil,
  ProductType,
  ProductUtil,
  SessionType,
  SessionTypeUtil,
  Stats,
} from '@packages/firebase';
import { useMemo } from 'react';
import { useSessions } from '../../../contexts/useSessions';
import Card from '../../Shared/Card';
import PieChart, { PieChartProps } from './PieChart';

function filterByProductType(stats: Stats) {
  return [
    {
      type: ProductType.SHERPAPAK,
      count: stats.counts.productType[ProductType.SHERPAPAK],
    },
    {
      type: ProductType.LUNGGUARD,
      count: stats.counts.productType[ProductType.LUNGGUARD],
    },
    {
      type: ProductType.BAROGUARD,
      count: stats.counts.productType[ProductType.BAROGUARD],
    },
    {
      type: ProductType.LIVERGUARD,
      count: stats.counts.productType[ProductType.LIVERGUARD],
    },
    {
      type: ProductType.PANCREASPAK,
      count: stats.counts.productType[ProductType.PANCREASPAK],
    },
    {
      type: ProductType.KTS,
      count: stats.counts.productType[ProductType.KTS],
    },
    {
      type: ProductType.VANTAGEPOINT,
      count: stats.counts.productType[ProductType.VANTAGEPOINT],
    },
  ];
}

function filterByOrganType(stats: Stats) {
  return [
    {
      type: OrganType.HEART,
      count: stats.counts.organType[OrganType.HEART],
    },
    {
      type: OrganType.LUNG,
      count: stats.counts.organType[OrganType.LUNG],
    },
    {
      type: OrganType.LIVER,
      count: stats.counts.organType[OrganType.LIVER],
    },
    {
      type: OrganType.KIDNEY,
      count: stats.counts.organType[OrganType.KIDNEY],
    },
    {
      type: OrganType.PANCREAS,
      count: stats.counts.organType[OrganType.PANCREAS],
    },
  ];
}

function filterByDonationType(stats: Stats) {
  return [
    {
      type: DonationType.DBD,
      count: stats.counts.donationType[DonationType.DBD],
    },
    {
      type: DonationType.DCD,
      count: stats.counts.donationType[DonationType.DCD],
    },
    {
      type: DonationType.UNKNOWN,
      count: stats.counts.donationType[DonationType.UNKNOWN],
    },
  ];
}

function filterBySessionType(stats: Stats) {
  return [
    {
      type: SessionType.CLINICAL,
      count: stats.counts.sessionType[SessionType.CLINICAL],
    },
    {
      type: SessionType.TRAINING,
      count: stats.counts.sessionType[SessionType.TRAINING],
    },
    {
      type: SessionType.UNKNOWN,
      count: stats.counts.sessionType[SessionType.UNKNOWN],
    },
  ];
}

const PiechartSection = () => {
  const { stats } = useSessions();

  const productTypeData = useMemo(
    () =>
      filterByProductType(stats).map(elem => ({
        name: ProductUtil.toHeading(elem.type),
        count: elem.count,
        color: elem.type === ProductType.BAROGUARD ? '#2C7FA3' : ProductUtil.colors(elem.type).primary,
      })),
    [stats],
  );

  const organTypeData = useMemo(
    () =>
      filterByOrganType(stats).map(elem => ({
        name: OrganTypeUtil.organString(elem.type),
        count: elem.count,
        color: OrganTypeUtil.colors(elem.type).primary,
      })),
    [stats],
  );

  const donationTypeData = useMemo(
    () =>
      filterByDonationType(stats).map(elem => ({
        name: DonationTypeUtil.toUIString(elem.type),
        count: elem.count,
        color: DonationTypeUtil.colors(elem.type),
      })),
    [stats],
  );

  const sessionTypeData = useMemo(
    () =>
      filterBySessionType(stats).map(elem => ({
        name: SessionTypeUtil.toUIString(elem.type),
        count: elem.count,
        color: SessionTypeUtil.colors(elem.type),
      })),
    [stats],
  );

  const loggerConnectedData = useMemo(() => {
    return [
      {
        name: 'Connected',
        count: stats.counts.loggerConnected.connected,
        color: '#077857',
      },
      {
        name: 'Not connected',
        count: stats.counts.loggerConnected.notConnected,
        color: '#BA1B1B',
      },
    ];
  }, [stats]);

  const sessionMembersCountData = useMemo(() => {
    return [
      {
        name: '1',
        count: stats.counts.sessionMembersCount.one,
        color: '#a6a6a6',
      },
      {
        name: '2-4',
        count: stats.counts.sessionMembersCount.twoToFour,
        color: '#7b7b7b',
      },
      {
        name: '5+',
        count: stats.counts.sessionMembersCount.fiveOrMore,
        color: '#363636',
      },
    ];
  }, [stats]);

  const destinationCountsData = useMemo(() => {
    return [
      {
        name: 'With destination',
        count: stats.counts.destinationCounts.set,
        color: '#077857',
      },
      {
        name: 'Without destination',
        count: stats.counts.destinationCounts.unknown,
        color: '#BA1B1B',
      },
    ];
  }, [stats]);

  const flightCountsData = useMemo(() => {
    return [
      {
        name: '0',
        count: stats.counts.flightCounts.zero,
        color: '#a6a6a6',
      },
      {
        name: '1',
        count: stats.counts.flightCounts.one,
        color: '#7b7b7b',
      },
      {
        name: '2+',
        count: stats.counts.flightCounts.twoOrMore,
        color: '#363636',
      },
    ];
  }, [stats]);

  const etaCountsData = useMemo(() => {
    return [
      {
        name: 'With ETA',
        count: stats.counts.etaCounts.set,
        color: '#077857',
      },
      {
        name: 'Without ETA',
        count: stats.counts.etaCounts.unknown,
        color: '#BA1B1B',
      },
    ];
  }, [stats]);

  const unosIdCountsData = useMemo(() => {
    return [
      {
        name: 'With UNOS ID',
        count: stats.counts.unosIdCounts.set,
        color: '#077857',
      },
      {
        name: 'Without UNOS ID',
        count: stats.counts.unosIdCounts.unknown,
        color: '#BA1B1B',
      },
    ];
  }, [stats]);

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-2 gap-8">
        <PieChartCard title="Product Type" data={productTypeData} />
        <PieChartCard title="Organ Type" data={organTypeData} />
      </div>

      <div className="grid grid-cols-2 gap-8">
        <PieChartCard title="Donor Type" data={donationTypeData} />
        <PieChartCard title="Session Type" data={sessionTypeData} />
      </div>

      <div className="grid grid-cols-3 gap-8">
        <PieChartCard title="Members per Session" data={sessionMembersCountData} />
        <PieChartCard title="Flights per Session" data={flightCountsData} />
        <PieChartCard title="Logger connected" data={loggerConnectedData} />
      </div>

      <div className="grid grid-cols-3 gap-8">
        <PieChartCard title="Destinations set" data={destinationCountsData} />
        <PieChartCard title="UNOS ID set" data={unosIdCountsData} />
        <PieChartCard title="ETA set" data={etaCountsData} />
      </div>
    </div>
  );
};

const PieChartCard = ({ title, data }: { title: string; data: PieChartProps['data'] }) => {
  return (
    <Card>
      <h2 className="mb-12 text-left text-xl font-semibold">{title}</h2>

      <PieChart data={data} />
    </Card>
  );
};

export default PiechartSection;
