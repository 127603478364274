import { formatCountry } from '@packages/ui';

function countryFormatter({ value }: { value: string }) {
  if (value == null) {
    return <i>No country</i>;
  }

  return formatCountry(value);
}

export default countryFormatter;
