function organizationFormatter({ value, row }: { value: string; row: any }) {
  return (
    <div className="whitespace-nowrap pr-3">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <div className="relative">
            <div>
              {row.original.logoUrl ? (
                <div className="flex h-12 w-12 items-center justify-center rounded-md border bg-gray-100">
                  <img className="inline-block max-h-full w-12 rounded-md" src={row.original.logoUrl} alt="" />
                </div>
              ) : (
                <div className="inline-block h-12 w-12 rounded-md border bg-gray-100" />
              )}
            </div>
          </div>
        </div>

        <div className="ml-4">
          <div className="text-base font-medium text-gray-900">{value}</div>
        </div>
      </div>
    </div>
  );
}

export default organizationFormatter;
