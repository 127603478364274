import { formatTemperature } from '@packages/utils';
import { TooltipProps } from 'recharts';
import { useProductColor } from '../../../../contexts/useProductColor';

function ChartTooltip({ active, payload }: TooltipProps<string, number>) {
  const colors = useProductColor();

  if (!active || payload == null) {
    return null;
  }

  const probeData = payload.find(elem => elem.dataKey === 'probe')?.payload;
  const ambientData = payload.find(elem => elem.dataKey === 'ambient')?.payload;

  if (probeData == null && ambientData == null) {
    return null;
  }

  return (
    <div className="w-56 bg-white shadow-lg">
      <div className="px-4 py-2 text-center text-white rounded-t" style={{ backgroundColor: colors.default }}>
        <div className="text-lg font-semibold">{probeData?.currentTime ?? ambientData?.currentTime ?? '-'}</div>
        <div>
          +
          {(probeData?.time != null && probeData?.time.length > 0) || (ambientData?.time != null && ambientData?.time.length > 0)
            ? probeData?.time ?? ambientData?.time ?? '0m'
            : '0m'}
        </div>
      </div>
      <div className="flex justify-center px-5 py-4 space-x-8">
        <div className="text-center">
          <div className="text-xs font-medium text-gray-500 uppercase">Probe</div>
          <div className="text-lg">{probeData?.probe != null ? formatTemperature(probeData.probe, 1) : '-'}</div>
        </div>
        <div className="text-center">
          <div className="text-xs font-medium text-gray-500 uppercase">Ambient</div>
          <div className="text-lg">{ambientData?.ambient != null ? formatTemperature(ambientData.ambient, 1) : '-'}</div>
        </div>
      </div>
    </div>
  );
}

export default ChartTooltip;
