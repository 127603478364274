import { OrganType, OrganTypeUtil } from '@packages/firebase';
import Badge from '../../Badge';

function organTypeFormatter({ value }: { value: OrganType[] }) {
  if (value == null || value.length === 0) {
    return '-';
  }

  return (
    <div className="flex">
      {value.map((organType, index) => (
        <div key={`${organType}-${index}`} className="mb-2 mr-2">
          <Badge styleType="light" size="xs">
            <div className="flex items-center space-x-1">
              <img className="h-4 w-4" src={OrganTypeUtil.iconPath(organType)} alt="" />
              <span>{OrganTypeUtil.organString(organType)}</span>
            </div>
          </Badge>
        </div>
      ))}
    </div>
  );
}

export default organTypeFormatter;
