import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Meta } from '../../types';

export type AuthLayoutProps = {
  meta: Meta;
};

const AuthLayout: FC<AuthLayoutProps> = ({ meta, children }) => {
  return (
    <>
      <Helmet>
        <title>{meta.title} | Paragonix Webmaster Tool</title>
        <meta name="description" content={meta.description} />
      </Helmet>

      <main>{children}</main>
    </>
  );
};

export default AuthLayout;
