import { OrganizationStatus, OrganizationStatusUtil } from '@packages/firebase';
import Badge from '../../Badge';

function organizationStatusFormatter({ value }: { value: OrganizationStatus }) {
  if (value == null || value.length === 0) {
    return '-';
  }

  const text = OrganizationStatusUtil.toUIString(value);

  switch (value) {
    case OrganizationStatus.CREATED:
      return (
        <Badge styleType="default" size="xs">
          {text}
        </Badge>
      );
    case OrganizationStatus.CREATE_REQUESTED:
      return (
        <Badge styleType="default" size="xs">
          {text}
        </Badge>
      );
    case OrganizationStatus.IN_VALIDATION:
      return (
        <Badge styleType="warning" size="xs">
          {text}
        </Badge>
      );
    case OrganizationStatus.ACTIVE:
      return (
        <Badge styleType="success" size="xs">
          {text}
        </Badge>
      );
    case OrganizationStatus.INACTIVE:
      return (
        <Badge styleType="danger" size="xs">
          {text}
        </Badge>
      );
    default:
      return (
        <Badge styleType="light" size="xs">
          Unknown
        </Badge>
      );
  }
}

export default organizationStatusFormatter;
