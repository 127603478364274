import { FC, ReactElement, ReactNode, VFC } from 'react';
import Card from './Card';
import Loader from './Loader';

export type StyleTypes = 'default' | 'card';

export type DataStateProps = {
  hasData: boolean;
  isLoading: boolean;
  text?: ReactNode;
  styleType?: StyleTypes;
};

type NoDataProps = {
  text?: ReactNode;
};

const DataState: FC<DataStateProps> = ({ text, hasData, isLoading, styleType = 'default', children }) => {
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <Loader size="md" />
      </div>
    );
  }

  if (!hasData && styleType === 'default') {
    return <NoData text={text} />;
  }

  if (!hasData && styleType === 'card') {
    return (
      <Card>
        <NoData text={text} />
      </Card>
    );
  }

  return children as ReactElement;
};

const NoData: VFC<NoDataProps> = ({ text }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full space-y-3">
      <div className="p-2 px-4 text-gray-500 rounded-full shadow bg-gray-50">?</div>
      <div className="font-medium">{text ?? 'No Data'}</div>
    </div>
  );
};

export default DataState;
