import { ProductType, TimelineEntry } from '@packages/firebase';
import { timelineEntryIcon } from '@packages/ui';
import { formatDate } from '@packages/utils';
import { DateTime } from 'luxon';
import { VFC } from 'react';
import { useProductColor } from '../../../contexts/useProductColor';
import DataState from '../DataState';

export type TimelineProps = {
  timeline: TimelineEntry[] | undefined;
  product: ProductType;
};

export type EventIconProps = {
  icon: any;
  color: string;
  backgroundColor: string;
};

const Timeline: VFC<TimelineProps> = ({ timeline, product }) => {
  const colors = useProductColor();

  return (
    <DataState hasData={timeline != null && timeline.length > 0} isLoading={false} text="No Timeline Entries found">
      <div className="flow-root">
        <ul className="-mb-8">
          {(timeline as TimelineEntry[]).map((event, index) => {
            return (
              <li key={`timeline-entry-${index}`}>
                <div className="relative pb-8">
                  {index !== (timeline as TimelineEntry[]).length - 1 ? (
                    <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <EventIcon icon={timelineEntryIcon(event.type)} color={colors.light} backgroundColor={colors.default} />

                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm font-medium text-black">{event.getTitle(product)}</p>
                        <p className="mt-0.5 text-sm text-gray-500">{`${DateTime.fromJSDate(event.timestamp).toRelative({
                          padding: 1000,
                        })} (${formatDate(event.timestamp)})`}</p>
                      </div>
                      <div className="text-sm text-right text-gray-500 whitespace-nowrap">
                        <span>{formatDate(event.timestamp)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </DataState>
  );
};

export const EventIcon: VFC<EventIconProps> = ({ icon: Icon, color, backgroundColor }) => {
  return (
    <div>
      <span className="flex items-center justify-center w-8 h-8 rounded-md ring-8 ring-white" style={{ color, backgroundColor }}>
        <Icon />
      </span>
    </div>
  );
};

export default Timeline;
