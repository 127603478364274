import { FlightState, FlightStateUtil, Session, SessionMember } from '@packages/firebase';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCountdown } from './useCountdown';

export function useETA(session: Session, members: SessionMember[]) {
  const etaCountdown = useCountdown(session.eta);

  const etaSetBy = useMemo(() => {
    const etaSetByMember = session.etaSetBy != null ? members?.find(member => member.id === session.etaSetBy) : undefined;

    return session.eta != null && etaSetByMember != null ? `by ${etaSetByMember.fullname}` : '-';
  }, [members, session.eta, session.etaSetBy]);

  const flights = useMemo(() => {
    if (session.flights == null) {
      return null;
    }

    return session.flights.map(flight => ({
      title:
        flight.origin?.name != null || flight.destination?.name != null
          ? [flight.origin?.name, flight.destination?.name].filter(elem => elem != null).join(' to ')
          : null,
      state: FlightStateUtil.toUIString(flight.state as FlightState),
      departure: {
        code: flight.origin?.code,
        planned: flight.plannedDeparture,
        estimated: flight.estimatedDeparture,
      },
      arrival: {
        code: flight.destination?.code,
        planned: flight.plannedArrival,
        estimated: flight.estimatedArrival,
      },
    }));
  }, [session.flights]);

  const activeFlight = useActiveFlight(session);

  const progress = useProgress(session.createdAt, session.eta, activeFlight);

  return {
    eta: {
      countdown: etaCountdown,
      setBy: etaSetBy,
    },
    flights,
    activeFlight,
    progress,
  };
}

function useActiveFlight(session: Session) {
  const activeFlight = useMemo(() => {
    if (session.flightPosition == null) {
      return null;
    }

    if (session.flights == null || session.flights.length === 0) {
      return null;
    }

    const currentFlight = session.flights.find(
      elem => elem.state === FlightState.IN_AIR && elem.flightId === session.flightPosition?.flightId,
    );

    if (currentFlight == null) {
      return null;
    }

    return {
      start: currentFlight.actualDeparture ?? currentFlight.estimatedDeparture ?? currentFlight.plannedDeparture,
      end: currentFlight.actualArrival ?? currentFlight.estimatedArrival ?? currentFlight.plannedArrival,
    };
  }, [session.flightPosition, session.flights]);

  return activeFlight;
}

function useProgress(start: Date, end?: Date, activeFlight?: { start: Date; end: Date } | null) {
  const progressPerUnit = useMemo(() => (start != null && end != null ? (end.getTime() - start.getTime()) / 100 : null), [start, end]);

  const calcProgress = useCallback(
    () => (start != null && progressPerUnit != null ? (new Date().getTime() - start.getTime()) / progressPerUnit : null),
    [start, progressPerUnit],
  );

  const flightStart = useMemo(
    () =>
      start != null &&
      end != null &&
      progressPerUnit &&
      activeFlight?.start != null &&
      activeFlight.start.getTime() > start.getTime() &&
      activeFlight.start.getTime() < end.getTime()
        ? (activeFlight.start.getTime() - start.getTime()) / progressPerUnit
        : null,
    [start, end, activeFlight, progressPerUnit],
  );

  const flightEnd = useMemo(
    () =>
      start != null &&
      end != null &&
      progressPerUnit &&
      activeFlight?.end != null &&
      activeFlight.end.getTime() < end.getTime() &&
      activeFlight.end.getTime() > start.getTime()
        ? (activeFlight.end.getTime() - start.getTime()) / progressPerUnit
        : null,
    [start, end, activeFlight, progressPerUnit],
  );

  const [progress, setProgress] = useState(calcProgress());

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(calcProgress());
    }, 1000);

    return () => clearInterval(interval);
  }, [calcProgress]);

  return {
    percentage: progress,
    flight: {
      startPercentage: flightStart,
      endPercentage: flightEnd,
    },
  };
}
