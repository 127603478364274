import { useFormikContext } from 'formik';
import { useState, VFC } from 'react';
import CheckboxBubble, { CheckboxBubbleProps } from './CheckboxBubble';

export type CheckboxBubbleGroupProps = {
  id: string;
  checkboxes: CheckboxBubbleProps[];
  hideAfter?: number;
};

const CheckboxBubbleGroup: VFC<CheckboxBubbleGroupProps> = ({ id, checkboxes, hideAfter }) => {
  const { touched, errors } = useFormikContext<{ [key: string]: any }>();

  const [internalHideAfter, setInternalHideAfter] = useState(hideAfter ?? checkboxes.length);

  return (
    <div>
      <div className="flex flex-row flex-wrap">
        {checkboxes.map((checkbox, index) => {
          if (index > internalHideAfter - 1) {
            return null;
          }

          return (
            <div key={checkbox.label} className="my-2 mr-3">
              <CheckboxBubble id={id} label={checkbox.label} value={checkbox.value} />
            </div>
          );
        })}

        {hideAfter != null &&
          checkboxes.length > hideAfter &&
          (internalHideAfter === checkboxes.length ? (
            <button type="button" onClick={() => setInternalHideAfter(hideAfter)} className="py-2 text-sm font-semibold text-blue-500">
              Show less
            </button>
          ) : (
            <button
              type="button"
              onClick={() => setInternalHideAfter(checkboxes.length)}
              className="py-2 text-sm font-semibold text-blue-500"
            >
              Show more
            </button>
          ))}
      </div>

      {touched[id] && errors[id] != null && <p className="mt-2 text-xs text-red-600">{errors[id]}</p>}
    </div>
  );
};

export default CheckboxBubbleGroup;
