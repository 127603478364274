import { Colors } from '@packages/constants';
import { formatTemperature } from '@packages/utils';
import { useMemo, VFC } from 'react';
import { useProductColor } from '../../../contexts/useProductColor';
import HorizontalReportPanel from '../HorizontalReportPanel';

type LiveTemperatureProps = {
  probe: number | undefined;
  ambient: number | undefined;
};

const LiveTemperatures: VFC<LiveTemperatureProps> = ({ probe, ambient }) => {
  const colors = useProductColor();

  const columns = useMemo(() => {
    return [
      {
        name: 'Probe',
        value: formatTemperature(probe),
        dotColor: colors.default,
      },
      {
        name: 'Ambient',
        value: formatTemperature(ambient),
        dotColor: Colors.COLOR_AMBIENT,
      },
    ];
  }, [probe, ambient]);

  return <HorizontalReportPanel columns={columns} />;
};

export default LiveTemperatures;
