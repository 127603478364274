import { cleanObject } from '@packages/utils';
import { useEffect, useMemo, useState, VFC } from 'react';
import { useFilters } from '../../../../hooks/useFilters';
import SessionFilter, { SessionFiltersFormValues } from './SessionFilters';
import TimeIntervalFilters, { TimeIntervalFiltersFormValues } from './TimeIntervalFilters';

const FilterBar: VFC = () => {
  const [filters, setFilters, resetFilters] = useFilters();

  const [timeIntervalFilters, setTimeIntervalFilters] = useState<TimeIntervalFiltersFormValues>();
  const [sessionFilters, setSessionFilters] = useState<SessionFiltersFormValues>();

  function onTimeIntervalFiltersUpdate(values: TimeIntervalFiltersFormValues) {
    setTimeIntervalFilters(values);
  }

  function onSessionFiltersUpdate(values: SessionFiltersFormValues) {
    setSessionFilters(values);
  }

  function onClearTimeIntervalFilters() {
    resetFilters(['startDate', 'endDate']);
    setTimeIntervalFilters(undefined);
  }

  function onClearSessionFilters() {
    resetFilters(['productType', 'donationType', 'sessionType', 'finished', 'logging']);
    setSessionFilters(undefined);
  }

  const query = useMemo(() => {
    const result: any = {
      startDate: filters.startDate != null ? filters.startDate.getTime() : undefined,
      endDate: filters.endDate != null ? filters.endDate.getTime() : undefined,
      productType: filters.productType != null ? filters.productType.join(',') : undefined,
      donationType: filters.donationType != null ? filters.donationType.join(',') : undefined,
      sessionType: filters.sessionType != null ? filters.sessionType.join(',') : undefined,
      finished: filters.finished != null ? filters.finished.join(',') : undefined,
      logging: filters.logging != null ? filters.logging.join(',') : undefined,
      stopReminderSent: filters.stopReminderSent != null ? filters.stopReminderSent.join(',') : undefined,
      forceStopAfter48Hours: filters.forceStopAfter48Hours != null ? filters.forceStopAfter48Hours.join(',') : undefined,
      loggerConnected: filters.loggerConnected != null ? filters.loggerConnected.join(',') : undefined,
    };

    if (timeIntervalFilters?.startDate != null && timeIntervalFilters?.endDate != null) {
      result.startDate = timeIntervalFilters.startDate.getTime();
      result.endDate = timeIntervalFilters.endDate.getTime();
    }

    if (sessionFilters?.productType != null) {
      result.productType = sessionFilters.productType.join(',');
    }

    if (sessionFilters?.donationType != null) {
      result.donationType = sessionFilters.donationType.join(',');
    }

    if (sessionFilters?.sessionType != null) {
      result.sessionType = sessionFilters.sessionType.join(',');
    }

    if (sessionFilters?.finished != null) {
      result.finished = sessionFilters.finished.join(',');
    }

    if (sessionFilters?.logging != null) {
      result.logging = sessionFilters.logging.join(',');
    }

    if (sessionFilters?.stopReminderSent != null) {
      result.stopReminderSent = sessionFilters.stopReminderSent.join(',');
    }

    if (sessionFilters?.forceStopAfter48Hours != null) {
      result.forceStopAfter48Hours = sessionFilters.forceStopAfter48Hours.join(',');
    }

    if (sessionFilters?.loggerConnected != null) {
      result.loggerConnected = sessionFilters.loggerConnected.join(',');
    }

    return cleanObject(result);
  }, [timeIntervalFilters, sessionFilters]);

  useEffect(() => {
    setFilters(query);
  }, [query]);

  return (
    <div className="z-50 flex flex-row mt-10 space-x-2">
      <TimeIntervalFilters
        startDate={filters.startDate}
        endDate={filters.endDate}
        onClear={() => onClearTimeIntervalFilters()}
        onSelection={values => onTimeIntervalFiltersUpdate(values)}
      />
      <SessionFilter
        productType={filters.productType}
        donationType={filters.donationType}
        sessionType={filters.sessionType}
        finished={filters.finished?.map(elem => elem.toString())}
        logging={filters.logging?.map(elem => elem.toString())}
        stopReminderSent={filters.stopReminderSent?.map(elem => elem.toString())}
        forceStopAfter48Hours={filters.forceStopAfter48Hours?.map(elem => elem.toString())}
        loggerConnected={filters.loggerConnected?.map(elem => elem.toString())}
        onClear={() => onClearSessionFilters()}
        onSubmit={values => onSessionFiltersUpdate(values)}
      />
    </div>
  );
};

export default FilterBar;
