import { OrganizationMemberAccessRole, OrganizationStatus } from '@packages/firebase';
import { useMemo } from 'react';
import {
  activateOrganizationMember,
  deactivateOrganizationMember,
  grantOrganizationAccess,
  sendOrganizationMemberInvite,
  startOrganizationValidation,
} from '../../../lib/firebase/functions';
import {
  addPreselectedUserToOrganization,
  changePreselectedUserAccessRoleInOrganization,
  changeUserAccessRoleInOrganization,
  removePreselectedUserFromOrganization,
  removeUserFromOrganization,
  useFetchOrganization,
} from '../../../lib/firebase/organizations';

export function useOrganizationManager(organizationId: string) {
  const { organization, users, preselectedUsers, unregisteredInvites, isLoading } = useFetchOrganization(organizationId);

  const isOrganizationComplete = useMemo(() => {
    return (
      organization?.name != null &&
      organization?.handle != null &&
      organization?.address != null &&
      organization?.country != null &&
      organization?.organTypes != null &&
      organization?.organTypes.length > 0 &&
      ((organization?.types != null && organization?.types.length > 0) || organization?.isCorporate === true)
    );
  }, [organization]);

  const isValidateable = useMemo(() => {
    if (!isOrganizationComplete) {
      return false;
    }

    return organization?.status === OrganizationStatus.CREATE_REQUESTED || organization?.status === OrganizationStatus.CREATED;
  }, [organization?.status, isOrganizationComplete]);

  const canStartValidation = useMemo(() => {
    if (!isValidateable) {
      return false;
    }

    if (
      !users?.some(user => user.accessRole === OrganizationMemberAccessRole.ADMIN) &&
      !preselectedUsers?.some(user => user.accessRole === OrganizationMemberAccessRole.ADMIN)
    ) {
      return false;
    }

    return true;
  }, [organization?.status, users, preselectedUsers, isValidateable]);

  const canAddNewUsers = useMemo(() => {
    if (!isOrganizationComplete) {
      return false;
    }

    return (
      organization?.status === OrganizationStatus.CREATE_REQUESTED ||
      organization?.status === OrganizationStatus.CREATED ||
      organization?.status === OrganizationStatus.IN_VALIDATION
    );
  }, [organization?.status, isOrganizationComplete]);

  const allUsers = useMemo(() => {
    if (organization?.status !== OrganizationStatus.ACTIVE && organization?.status !== OrganizationStatus.INACTIVE) {
      return [...(users ?? []), ...(preselectedUsers ?? []).filter(user => !users?.some(elem => elem.id === user.id))];
    }

    return users ?? [];
  }, [users, preselectedUsers, organization?.status]);

  async function addUser(userId: string) {
    if (organization?.status == null) {
      return;
    }

    if (
      organization.status === OrganizationStatus.CREATE_REQUESTED ||
      organization.status === OrganizationStatus.CREATED ||
      organization.status === OrganizationStatus.IN_VALIDATION
    ) {
      if (users?.some(elem => elem.id === userId) || preselectedUsers?.some(elem => elem.id === userId)) {
        return;
      }

      await addPreselectedUserToOrganization(organizationId, userId);
    }
  }

  async function removeUser(userId: string) {
    if (organization?.status == null) {
      return;
    }

    if (preselectedUsers?.some(elem => elem.id === userId)) {
      await removePreselectedUserFromOrganization(organizationId, userId);
    }

    if (users?.some(elem => elem.id === userId)) {
      await removeUserFromOrganization(organizationId, userId);
    }
  }

  async function changeUserAccessRole(userId: string, accessRole: OrganizationMemberAccessRole) {
    if (organization?.status == null) {
      return;
    }

    if (users?.some(elem => elem.id === userId)) {
      await changeUserAccessRoleInOrganization(organizationId, userId, accessRole);
    }

    if (
      organization.status !== OrganizationStatus.ACTIVE &&
      organization.status !== OrganizationStatus.INACTIVE &&
      preselectedUsers?.some(elem => elem.id === userId)
    ) {
      await changePreselectedUserAccessRoleInOrganization(organizationId, userId, accessRole);
    }
  }

  async function startValidation() {
    if (!canStartValidation) {
      return;
    }

    await startOrganizationValidation(organizationId);
  }

  async function grantAccess(userId: string) {
    if (organization == null) {
      return;
    }

    await grantOrganizationAccess(organization.id, userId);
  }

  async function deactivateUser(userId: string) {
    if (organization == null) {
      return;
    }

    await deactivateOrganizationMember(organization.id, userId);
  }

  async function reactivateUser(userId: string) {
    if (organization == null) {
      return;
    }

    await activateOrganizationMember(organization.id, userId);
  }

  async function sendInvite(userId: string) {
    if (organization == null) {
      return;
    }

    await sendOrganizationMemberInvite(organization.id, [userId]);
  }

  return {
    organization,
    users,
    preselectedUsers,
    unregisteredInvites,
    allUsers,
    isLoading,
    addUser,
    removeUser,
    changeUserAccessRole,
    startValidation,
    grantAccess,
    deactivateUser,
    reactivateUser,
    sendInvite,
    isOrganizationComplete,
    isValidateable,
    canStartValidation,
    canAddNewUsers,
  };
}
