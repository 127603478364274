import Badge from '../../Badge';

function booleanFormatter({ value }: { value: boolean }) {
  return value === true ? (
    <Badge styleType="success" size="xs">
      Yes
    </Badge>
  ) : (
    <Badge styleType="danger" size="xs">
      No
    </Badge>
  );
}

export default booleanFormatter;
