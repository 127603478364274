import { Popover } from '@headlessui/react';
import { classNames, formatDate, FormatDateMode } from '@packages/utils';
import { useEffect, useMemo, useState, VFC } from 'react';
import { DateRangePicker, Range } from 'react-date-range';
import { HiCalendar } from 'react-icons/hi2';
import Button from '../../../Shared/Button';

export type TimeIntervalFiltersProps = {
  startDate: Date | undefined;
  endDate: Date | undefined;
  onClear: () => void;
  onSelection: (values: TimeIntervalFiltersFormValues) => void;
};

export type TimeIntervalFiltersFormValues = {
  startDate?: Date;
  endDate?: Date;
};

const PLACEHOLDER = 'Time Interval';

const TimeIntervalFilters: VFC<TimeIntervalFiltersProps> = ({ startDate, endDate, onClear, onSelection }) => {
  const [ranges, setRanges] = useState<Range[]>([
    {
      startDate,
      endDate,
      key: 'selection',
    },
  ]);

  function onClearAction() {
    setRanges([
      {
        key: 'selection',
      },
    ]);

    onClear();
  }

  const isActive = useMemo(() => {
    return ranges[0].startDate != null && ranges[0].endDate != null;
  }, [ranges]);

  const selectionLabels = useMemo(() => {
    const dates = ranges[0];

    return {
      startDate: dates.startDate != null ? formatDate(dates.startDate, { mode: FormatDateMode.DATE }) : PLACEHOLDER,
      endDate: dates.endDate != null ? formatDate(dates.endDate, { mode: FormatDateMode.DATE }) : PLACEHOLDER,
    };
  }, [ranges]);

  useEffect(
    () =>
      setRanges([
        {
          startDate,
          endDate,
          key: 'selection',
        },
      ]),
    [startDate, endDate],
  );

  useEffect(() => {
    const dates = ranges[0];

    if (dates.startDate?.getTime() === startDate?.getTime() && dates.endDate?.getTime() === endDate?.getTime()) {
      return;
    }

    onSelection({ startDate: dates.startDate, endDate: dates.endDate });
  }, [ranges]);

  return (
    <Popover>
      <div className="space-y-3">
        <Popover.Button>
          <div
            className={classNames(
              isActive ? 'text-blue-500 bg-blue-50' : 'text-gray-500 bg-gray-100',
              'flex items-center px-3 py-2 space-x-2 text-sm rounded-lg shadow',
            )}
          >
            <HiCalendar className="h-5" />
            <span>
              {selectionLabels.startDate === selectionLabels.endDate
                ? selectionLabels.startDate
                : `${selectionLabels.startDate} - ${selectionLabels.endDate}`}
            </span>
          </div>
        </Popover.Button>
        <Popover.Panel className="absolute z-50 bg-white">
          <div className="shadow-md">
            <DateRangePicker
              onChange={(item: any) => setRanges([item.selection])}
              showPreview
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={ranges}
              direction="horizontal"
            />
            <div className="flex justify-end px-4 py-4 space-x-2">
              <Button styleType="light" buttonType="button" onClick={() => onClearAction()}>
                Clear
              </Button>
            </div>
          </div>
        </Popover.Panel>
      </div>
    </Popover>
  );
};

export default TimeIntervalFilters;
