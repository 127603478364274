import { Menu, Transition } from '@headlessui/react';
import { classNames } from '@packages/utils';
import { VFC, useMemo } from 'react';
import { HiBars3, HiChevronDown } from 'react-icons/hi2';
import { useAuthUser } from '../../../contexts/useAuthUser';
import { logout } from '../../../lib/firebase/auth';
import { InitialsAvatar } from '../../Shared/Avatars';

export type HeaderProps = {
  setSidebarIsOpen: (value: boolean) => void;
};

const Header: VFC<HeaderProps> = ({ setSidebarIsOpen }) => {
  const { user, isUserLoading } = useAuthUser();

  const fullname = useMemo(() => {
    if (user == null) {
      return '';
    }

    return user.fullname;
  }, [user.firstname, user.lastname]);

  function onLogout() {
    logout();
  }

  if (isUserLoading) {
    return null;
  }

  return (
    <div className="sticky top-0 z-10 flex flex-shrink-0 h-16 bg-white border-b">
      <button
        type="button"
        className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
        onClick={() => setSidebarIsOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <HiBars3 className="w-6 h-6" aria-hidden="true" />
      </button>
      <div className="flex justify-end flex-1 px-4">
        <div className="flex items-center ml-4 md:ml-6">
          <Menu as="div" className="relative ml-3">
            <div>
              <Menu.Button className="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 lg:p-1 lg:rounded-md lg:hover:bg-gray-50">
                <InitialsAvatar firstname={user.firstname} lastname={user.lastname} size="md" />
                <span className="hidden ml-2 text-sm font-medium text-gray-700 lg:block">
                  <span className="sr-only">Open user menu for </span>
                  {fullname}
                </span>
                <HiChevronDown className="flex-shrink-0 hidden w-4 h-4 ml-1 text-gray-400 lg:block" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as="div"
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 text-left w-full')}
                      onClick={() => onLogout()}
                    >
                      Logout
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Header;
