import { Routes } from '@packages/constants';
import { Organization } from '@packages/firebase';
import { useMemo, VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchOrganizationTypesConfig } from '../../../lib/firebase/organizations';
import Table from '../../Shared/Table';
import CellFormatters from '../../Shared/Table/CellFormatters';

export type OrganizationsListSectionProps = {
  organizations: Organization[];
};

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: CellFormatters.organization,
  },
  {
    Header: 'Handle',
    accessor: 'handle',
  },
  {
    Header: 'Country',
    accessor: 'country',
    Cell: CellFormatters.country,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: CellFormatters.organizationStatus,
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: CellFormatters.datetime,
    sortType: 'datetime',
  },
];

const OrganizationsListSection: VFC<OrganizationsListSectionProps> = ({ organizations }) => {
  const navigate = useNavigate();
  const config = useFetchOrganizationTypesConfig();

  function onOrganizationDetail(organizationId: string) {
    navigate(Routes.wmt.ORGANIZATION_DETAIL.replace(':id', organizationId));
  }

  const data = useMemo(() => {
    return organizations.map(organization => {
      return {
        id: organization.id,
        name: organization.name,
        logoUrl: organization.logoUrl,
        handle: organization.handle ?? <i>No handle</i>,
        status: organization.status,
        country: organization.country,
        createdAt: organization.createdAt,
      };
    });
  }, [organizations]);

  if (config.isLoading) {
    return null;
  }

  return (
    <Table
      columns={columns as any}
      data={data}
      options={{ hasPagination: true, hasRowSelect: false }}
      onRowClick={({ original }) => onOrganizationDetail(original.id)}
    />
  );
};

export default OrganizationsListSection;
