import { ReadingCompressed, Session, TimelineEntry } from '@packages/firebase';
import { FC, VFC, useState } from 'react';
import { importSessionLogs } from '../../../../lib/firebase/firestore';
import Alert from '../../Alert';
import Button from '../../Button';
import ImportDrop from './ImportDrop';

export type ImportLogsProps = {
  session: Session;
  timeline?: TimelineEntry[];
};

type ShowImportDataStepProps = {
  data: ReadingCompressed[];
  session: Session;
  timeline?: TimelineEntry[];
  onCancel: () => void;
};

type ImportDropStepProps = {
  session: Session;
  onData: (data: ReadingCompressed[]) => void;
};

const ImportLogs: VFC<ImportLogsProps> = ({ session, timeline }) => {
  const [importData, setImportData] = useState<ReadingCompressed[] | undefined>();

  return (
    <div className="mt-4">
      {!importData && <ImportDropStep session={session} onData={data => setImportData(data)} />}
      {importData && (
        <ShowImportDataStep session={session} timeline={timeline} data={importData} onCancel={() => setImportData(undefined)} />
      )}
    </div>
  );
};

const ShowImportDataStep: FC<ShowImportDataStepProps> = ({ data, session, timeline, onCancel }) => {
  const [isImporting, setIsImporting] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [error, setError] = useState<string | undefined>();

  async function onImport() {
    if (data == null || data.length === 0) {
      return;
    }

    setIsImporting(true);

    try {
      await importSessionLogs(session, timeline ?? [], data);
      setIsComplete(true);
    } catch (error) {
      setError('An error occurred while importing the data.');
    } finally {
      setIsImporting(false);
    }
  }

  return (
    <>
      <div>
        {!isComplete && (
          <div className="px-4 py-5 border rounded sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Data from Spreadsheet</h3>
            <p className="mt-1 text-sm text-gray-500">
              {data.length === 0 && (
                <span className="block text-red-700">
                  There is not data that can be imported. There is either no data in the spreadsheet, or all of the rows had an error.
                </span>
              )}

              {data.length > 0 && (
                <>
                  <span className="block">By clicking on the Import button below the following will happen:</span>
                  <span className="block text-green-700">
                    A total of <span className="font-bold">{data.length}</span> log(s) will be imported.
                  </span>
                </>
              )}
            </p>
          </div>
        )}
      </div>

      {isComplete && (
        <div className="pt-2">
          <Alert type="success" title="Data import done." />
        </div>
      )}

      {error && (
        <div className="pt-2">
          <Alert type="danger" title={error} />
        </div>
      )}

      <div className="flex mt-4 space-x-2 bg-white">
        {!isComplete && (
          <Button buttonType="button" styleType="light" onClick={() => onCancel()} isFullWidth>
            Cancel
          </Button>
        )}

        {!isComplete && (
          <Button
            buttonType="button"
            styleType="default"
            onClick={() => onImport()}
            isFullWidth
            disabled={data.length === 0}
            isLoading={isImporting}
          >
            <span>Import</span>
          </Button>
        )}
      </div>
    </>
  );
};

const ImportDropStep: FC<ImportDropStepProps> = ({ session, onData }) => {
  return (
    <div className="space-y-4">
      <ImportDrop session={session} onData={data => onData(data)} />
    </div>
  );
};

export default ImportLogs;
