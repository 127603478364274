import { DonationType, ProductType, SessionFilters, SessionType } from '@packages/firebase';
import { useMemo } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

export function useFilters(): [
  SessionFilters,
  (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined,
  ) => void,
  (reset: string[]) => void,
] {
  const [filters, setFilters] = useSearchParams();

  function resetFilters(reset: string[]) {
    reset.forEach(key => filters.delete(key));

    setFilters(filters);
  }

  const startDate = useMemo(() => {
    const filter = filters.get('startDate');

    return filter != null ? new Date(Number(filter)) : undefined;
  }, [filters]);

  const endDate = useMemo(() => {
    const filter = filters.get('endDate');

    return filter != null ? new Date(Number(filter)) : undefined;
  }, [filters]);

  const productType = useMemo(() => {
    const filter = filters.get('productType');

    return filter != null
      ? filter
          .split(',')
          .map(elem => elem as ProductType)
          .filter(elem => elem != null)
      : undefined;
  }, [filters]);

  const donationType = useMemo(() => {
    const filter = filters.get('donationType');

    return filter != null
      ? filter
          .split(',')
          .map(elem => DonationType[elem as DonationType])
          .filter(elem => elem != null)
      : undefined;
  }, [filters]);

  const sessionType = useMemo(() => {
    const filter = filters.get('sessionType');

    return filter != null
      ? filter
          .split(',')
          .map(elem => SessionType[elem as SessionType])
          .filter(elem => elem != null)
      : undefined;
  }, [filters]);

  const finished = useMemo(() => {
    const filter = filters.get('finished');

    return filter != null
      ? filter
          .split(',')
          .map(elem => elem === 'true')
          .filter(elem => elem != null)
      : undefined;
  }, [filters]);

  const logging = useMemo(() => {
    const filter = filters.get('logging');

    return filter != null
      ? filter
          .split(',')
          .map(elem => elem === 'true')
          .filter(elem => elem != null)
      : undefined;
  }, [filters]);

  const stopReminderSent = useMemo(() => {
    const filter = filters.get('stopReminderSent');

    return filter != null
      ? filter
          .split(',')
          .map(elem => elem === 'true')
          .filter(elem => elem != null)
      : undefined;
  }, [filters]);

  const forceStopAfter48Hours = useMemo(() => {
    const filter = filters.get('forceStopAfter48Hours');

    return filter != null
      ? filter
          .split(',')
          .map(elem => elem === 'true')
          .filter(elem => elem != null)
      : undefined;
  }, [filters]);

  const loggerConnected = useMemo(() => {
    const filter = filters.get('loggerConnected');

    return filter != null
      ? filter
          .split(',')
          .map(elem => elem === 'true')
          .filter(elem => elem != null)
      : undefined;
  }, [filters]);

  return [
    {
      startDate,
      endDate,
      productType,
      donationType,
      sessionType,
      finished,
      logging,
      stopReminderSent,
      forceStopAfter48Hours,
      loggerConnected,
    },
    setFilters,
    resetFilters,
  ];
}
