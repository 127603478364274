import { Assets } from '@packages/assets';
import { VFC } from 'react';
import NavigationItem, { type NavigationItemProps } from './NavigationItem';

export type DesktopNavigationProps = {
  items: NavigationItemProps[];
};

const DesktopNavigation: VFC<DesktopNavigationProps> = ({ items }) => {
  return (
    <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex flex-col flex-grow pt-5 overflow-y-auto bg-primary">
        <div className="flex items-center flex-shrink-0 px-4">
          <img className="w-auto" src={Assets.logo.paragonixLogoDarkPng} alt="Workflow" />
        </div>
        <div className="flex flex-col flex-1 mt-5">
          <nav className="flex-1 px-2 pb-4 space-y-1">
            {items.map(item => (
              <NavigationItem key={`desktop-navigation-item-${item.name}`} {...item} />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default DesktopNavigation;
