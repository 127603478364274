import { calculateSessionStats, filterSessions } from '@packages/firebase';
import { useMemo } from 'react';
import { GrDocumentMissing } from 'react-icons/gr';
import withUser from '../components/HOC/withUser';
import AppLayout from '../components/Layouts/AppLayout';
import AveragesTabsSection from '../components/Pages/Analytics/AveragesTabsSection';
import DestinationsListSection from '../components/Pages/Analytics/DestinationsListSection';
import FilterBar from '../components/Pages/Analytics/FilterBar';
import OverviewSection from '../components/Pages/Analytics/OverviewSection';
import PiechartSection from '../components/Pages/Analytics/PiechartSection';
import SessionsListSection from '../components/Pages/Analytics/SessionsListSection';
import Card from '../components/Shared/Card';
import Loader from '../components/Shared/Loader';
import { Tooltip, TooltipContainer } from '../components/Shared/Tooltip';
import { SessionsContext } from '../contexts/useSessions';
import { useFilters } from '../hooks/useFilters';
import { useFetchSessions } from '../lib/firebase/firestore';
import { Meta } from '../types';

const meta: Meta = {
  title: 'Analytics',
  description: 'Your current analytics summary and overview.',
};

const Analytics = () => {
  const [filters] = useFilters();

  const { sessions, areSessionsLoading } = useFetchSessions();

  const value = useMemo(() => {
    if (sessions == null) {
      return null;
    }

    const filteredSessions = filterSessions(sessions, filters);

    return {
      sessions: filteredSessions,
      areSessionsLoading,
      stats: calculateSessionStats(filteredSessions),
    };
  }, [
    sessions,
    areSessionsLoading,
    filters.startDate,
    filters.endDate,
    filters.productType,
    filters.donationType,
    filters.sessionType,
    filters.finished,
    filters.logging,
    filters.stopReminderSent,
    filters.forceStopAfter48Hours,
    filters.loggerConnected,
  ]);

  if (areSessionsLoading) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Loader size="xl" />
      </div>
    );
  }

  return (
    <AppLayout meta={meta}>
      {value != null ? (
        <SessionsContext.Provider value={value}>
          <div className="mb-8">
            <FilterBar />
          </div>

          <div>
            <TooltipContainer>
              <Tooltip />
            </TooltipContainer>
          </div>

          <div className="space-y-8">
            <OverviewSection />
            <SessionsListSection />
            <PiechartSection />
            <DestinationsListSection />
            <AveragesTabsSection />
          </div>
        </SessionsContext.Provider>
      ) : (
        <div className="mt-12">
          <Card>
            <div className="flex flex-col items-center justify-center w-full h-full space-y-6">
              <div className="p-4 text-white bg-blue-300 rounded-full">
                <GrDocumentMissing className="w-8 h-8" />
              </div>
              <span className="font-bold">No Sessions found</span>
            </div>
          </Card>
        </div>
      )}
    </AppLayout>
  );
};

export default withUser(Analytics);
