"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  FormatDateMode: () => FormatDateMode,
  calculateChartTicks: () => calculateChartTicks,
  calculateLoggerDistance: () => calculateLoggerDistance,
  capitalize: () => capitalize,
  chunkArray: () => chunkArray,
  classNames: () => classNames,
  cleanObject: () => cleanObject,
  formatDate: () => formatDate,
  formatDistance: () => formatDistance,
  formatPressure: () => formatPressure,
  formatTemperature: () => formatTemperature,
  isImperialDefault: () => isImperialDefault,
  kalmanFilterAll: () => kalmanFilterAll,
  millisecondsToPaddedTime: () => millisecondsToPaddedTime,
  millisecondsToTime: () => millisecondsToTime,
  millisecondsToTimeArray: () => millisecondsToTimeArray,
  parseAddressComponents: () => parseAddressComponents
});
module.exports = __toCommonJS(src_exports);

// src/millisecondsToTimeArray.ts
var import_luxon = require("luxon");
function millisecondsToTimeArray(milliseconds) {
  const { days, hours, minutes, seconds } = import_luxon.Duration.fromObject({ milliseconds }).shiftTo(
    "days",
    "hours",
    "minutes",
    "seconds",
    "milliseconds"
  );
  return [days > 0 ? days : 0, hours > 0 ? hours : 0, minutes > 0 ? minutes : 0, seconds > 0 ? seconds : 0];
}

// src/calculateChartTicks.ts
function calculateChartTicks(startMilliseconds, latestMilliseconds) {
  const [days, hours, minutes] = millisecondsToTimeArray(latestMilliseconds - startMilliseconds);
  const INTERVAL_DURATION_MAP = [
    { condition: days === 1, intervalToDuration: 3600 * 1e3 * 4 },
    { condition: days > 0 && days < 5, intervalToDuration: 3600 * 1e3 * 8 },
    { condition: days > 0, intervalToDuration: 3600 * 1e3 * 24 },
    { condition: hours === 1, intervalToDuration: 60 * 1e3 * 10 },
    { condition: hours > 0 && hours < 5, intervalToDuration: 1800 * 1e3 },
    { condition: hours > 0 && hours < 12, intervalToDuration: 3600 * 1e3 },
    { condition: hours > 0 && hours < 24, intervalToDuration: 3600 * 1e3 * 2 },
    { condition: hours > 0, intervalToDuration: 3600 * 1e3 },
    { condition: minutes > 0 && minutes <= 15, intervalToDuration: 60 * 1e3 },
    { condition: minutes > 0 && minutes <= 30, intervalToDuration: 60 * 1e3 * 5 },
    { condition: minutes > 0, intervalToDuration: 60 * 1e3 * 10 }
  ];
  for (const elem of INTERVAL_DURATION_MAP) {
    if (elem.condition) {
      return calculateTicks(elem.intervalToDuration, startMilliseconds, latestMilliseconds);
    }
  }
  return [];
}
function calculateTicks(intervalDuration, startMilliseconds, latestMilliseconds) {
  let millisecondsCount = startMilliseconds;
  let count = 0;
  const ticks = [];
  while (millisecondsCount < latestMilliseconds) {
    ticks.push(intervalDuration * count);
    millisecondsCount += intervalDuration;
    count += 1;
  }
  return [...ticks, latestMilliseconds - startMilliseconds];
}

// src/calculateDistance.ts
var import_turf = require("@turf/turf");
function calculateLoggerDistance(loggerLocations, units = "kilometers", smooth = true) {
  const filteredLocations = kalmanFilterAll(loggerLocations);
  const points = (smooth ? filteredLocations : loggerLocations).map((location) => (0, import_turf.point)([location.longitude, location.latitude]));
  let totalDistance = 0;
  for (let i = 0; i < points.length; i++) {
    const from = points[i];
    const to = points[i + 1];
    if (from != null && to != null) {
      totalDistance += (0, import_turf.distance)(from, to, { units });
    }
  }
  return totalDistance;
}
function kalmanFilterAll(locations, decay) {
  if (locations == null || locations.length === 0) {
    return [];
  }
  const kalmanFilter = new KalmanFilter(decay);
  return kalmanFilter.filterAll(locations);
}
var KalmanFilter = class {
  constructor(decay = 3) {
    this.variance = -1;
    this.latitude = 0;
    this.longitude = 0;
    this.milliseconds = 0;
    this.decay = decay;
    this.variance = -1;
  }
  filterAll(locations) {
    return locations.map(
      (location) => this.filter(location.latitude, location.longitude, location.horizontalAccuracy, location.timestamp.getTime())
    );
  }
  filter(latitude, longitude, accuracy, milliseconds) {
    const setAccuracy = accuracy >= 1 ? accuracy : 1;
    if (this.variance < 0) {
      this.latitude = latitude;
      this.longitude = longitude;
      this.milliseconds = milliseconds;
      this.variance = setAccuracy * setAccuracy;
    } else {
      const timeIncMs = milliseconds - this.milliseconds;
      if (timeIncMs > 0) {
        this.variance += timeIncMs * this.decay * this.decay / 1e3;
        this.milliseconds = milliseconds;
      }
      const k = this.variance / (this.variance + setAccuracy * setAccuracy);
      this.latitude += k * (latitude - this.latitude);
      this.longitude += k * (longitude - this.longitude);
      this.variance *= 1 - k;
    }
    return { latitude: this.latitude, longitude: this.longitude };
  }
};

// src/capitalize.ts
function capitalize(str) {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}

// src/chunkArray.ts
function chunkArray(arr, chunkSize = 20) {
  const chunks = [];
  for (let i = 0; i < Math.ceil(arr.length / chunkSize); i++) {
    chunks.push(arr.slice(i * chunkSize, (i + 1) * chunkSize));
  }
  return chunks;
}

// src/classNames.ts
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// src/cleanObject.ts
function cleanObject(obj) {
  const result = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (value != null && typeof value === "object" && !Array.isArray(value) && !(value instanceof Date)) {
      const nestedObj = cleanObject(value);
      if (Object.keys(nestedObj).length > 0) {
        result[key] = nestedObj;
      }
    } else if (value != null && value !== "") {
      result[key] = value;
    }
  });
  return result;
}

// src/formatDate.ts
var import_luxon2 = require("luxon");
var FormatDateMode = /* @__PURE__ */ ((FormatDateMode2) => {
  FormatDateMode2["DATE"] = "DATE";
  FormatDateMode2["TIME"] = "TIME";
  FormatDateMode2["DATE_TIME"] = "DATE_TIME";
  return FormatDateMode2;
})(FormatDateMode || {});
var DEFAULT_FORMAT_DATE_OPTIONS = {
  hasTimezone: false,
  has24Hours: true,
  mode: "DATE_TIME" /* DATE_TIME */,
  dateTimeSeparator: ", ",
  today: "Today",
  tomorrow: "Tomorrow",
  yesterday: "Yesterday"
};
function formatDate(date, options) {
  let setDate = import_luxon2.DateTime.fromJSDate(date);
  const setOptions = { ...DEFAULT_FORMAT_DATE_OPTIONS, ...options ?? {} };
  if (setOptions.timezoneOffset != null) {
    setDate = moveTimezone(setDate, setOptions.timezoneOffset);
  }
  if (setOptions.mode === "DATE" /* DATE */) {
    return formatDateMode(setDate, setOptions);
  }
  if (setOptions.mode === "TIME" /* TIME */) {
    return formatTimeMode(setDate, setOptions);
  }
  return formatDateTimeMode(setDate, setOptions);
}
function formatDateMode(dateTime, options) {
  if (options.today != null && isToday(dateTime)) {
    return options.today;
  }
  if (options.tomorrow != null && isTomorrow(dateTime)) {
    return options.tomorrow;
  }
  if (options.yesterday != null && isYesterday(dateTime)) {
    return options.yesterday;
  }
  return dateTime.toLocaleString(import_luxon2.DateTime.DATE_SHORT).replaceAll(/\//g, ".");
}
function formatTimeMode(dateTime, options) {
  let formatted = dateTime.toFormat(options.has24Hours ? "HH:mm" : "hh:mm a");
  if (options.hasTimezone) {
    const timezone = options.timezoneOffset != null && options.timezoneName != null ? options.timezoneName : parseTimeZone(dateTime);
    formatted += ` ${timezone}`;
  }
  return formatted;
}
function formatDateTimeMode(dateTime, options) {
  const formattedDate = formatDateMode(dateTime, options);
  const formattedTime = formatTimeMode(dateTime, options);
  return `${formattedDate}${options == null ? void 0 : options.dateTimeSeparator}${formattedTime}`;
}
function parseTimeZone(dateTime) {
  return dateTime.toFormat("ZZZZ");
}
function isToday(dateTime) {
  return dateTime.toISODate() === import_luxon2.DateTime.local().toISODate();
}
function isTomorrow(dateTime) {
  return dateTime.toISODate() === import_luxon2.DateTime.local().plus({ days: 1 }).toISODate();
}
function isYesterday(dateTime) {
  return dateTime.toISODate() === import_luxon2.DateTime.local().minus({ days: 1 }).toISODate();
}
function moveTimezone(dateTime, timezoneOffset) {
  const { zoneName } = import_luxon2.DateTime.fromISO(`2020-01-01T01:00:00${timezoneOffset}`, { setZone: true });
  return dateTime.setZone(zoneName);
}

// src/formatDistance.ts
function formatDistance(distance2, distanceUnit) {
  if (distance2 == null || Number.isNaN(distance2)) {
    return "-";
  }
  if (distanceUnit == null) {
    return isImperialDefault() ? formatMile(distance2) : formatKilometer(distance2);
  }
  return distanceUnit === "METRIC" /* METRIC */ ? formatKilometer(distance2) : formatMile(distance2);
}
function isImperialDefault() {
  return true;
}
function formatKilometer(distance2) {
  return `${new Intl.NumberFormat().format(roundDistance(distance2))} km`;
}
function formatMile(distance2) {
  return `${new Intl.NumberFormat().format(roundDistance(distance2 / 1.609344))} mi`;
}
function roundDistance(distance2) {
  return Number((Math.round(distance2 * 100) / 100).toFixed(2));
}

// src/formatTemperature.ts
function formatTemperature(temperature, fractionDigits = 1, unit = true) {
  if (temperature == null || Number.isNaN(temperature)) {
    return "-";
  }
  if (unit) {
    return `${parseFloat(temperature.toFixed(fractionDigits))}\xB0C`;
  }
  return parseFloat(temperature.toFixed(fractionDigits)).toString();
}

// src/millisecondsToPaddedTime.ts
function millisecondsToPaddedTime(milliseconds) {
  if (milliseconds == null || Number.isNaN(milliseconds)) {
    return "-";
  }
  const [days, hours, minutes, seconds] = millisecondsToTimeArray(milliseconds);
  return `${pad(days > 0 ? hours + days * 24 : hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
}
function pad(num, size) {
  return `000${num}`.slice(size * -1);
}

// src/millisecondsToTime.ts
function millisecondsToTime(milliseconds, options) {
  if (milliseconds == null || Number.isNaN(milliseconds)) {
    return "-";
  }
  const [days, hours, minutes, seconds] = millisecondsToTimeArray(milliseconds);
  return [
    days > 0 && (options == null ? void 0 : options.days) !== false ? `${new Intl.NumberFormat().format(days)}d` : null,
    hours > 0 && (options == null ? void 0 : options.hours) !== false ? `${new Intl.NumberFormat().format(hours)}h` : null,
    minutes > 0 && (options == null ? void 0 : options.minutes) !== false ? `${new Intl.NumberFormat().format(minutes)}m` : null,
    seconds > 0 && (options == null ? void 0 : options.seconds) !== false ? `${new Intl.NumberFormat().format(seconds)}s` : null
  ].filter((elem) => elem != null).slice((options == null ? void 0 : options.minUnits) != null ? options.minUnits : 0, (options == null ? void 0 : options.maxUnits) != null ? options.maxUnits : options == null ? void 0 : options.maxUnits).join(" ");
}

// src/parseAddressComponents.ts
function parseAddressComponents(place) {
  var _a;
  const address = {};
  (_a = place.address_components) == null ? void 0 : _a.forEach(({ long_name: longName, types }) => {
    if (types.includes("street_number")) {
      address.streetNumber = longName;
    } else if (types.includes("route")) {
      address.street = longName;
    } else if (types.includes("neighborhood")) {
      address.neighborhood = longName;
    } else if (types.includes("locality")) {
      address.city = longName;
    } else if (types.includes("administrative_area_level_2")) {
      address.county = longName;
    } else if (types.includes("administrative_area_level_1")) {
      address.state = longName;
    } else if (types.includes("country")) {
      address.country = longName;
    } else if (types.includes("postal_code")) {
      address.zip = longName;
    }
  });
  return address;
}

// src/formatPressure.ts
function formatPressure(pressure, fractionDigits = 1, unit = true) {
  if (pressure == null || Number.isNaN(pressure)) {
    return "-";
  }
  const roundedPressure = Math.max(0, Math.ceil(pressure * 100) / 100);
  if (unit) {
    return `${parseFloat(roundedPressure.toFixed(fractionDigits))} cmH\u20820`;
  }
  return parseFloat(roundedPressure.toFixed(fractionDigits)).toString();
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  FormatDateMode,
  calculateChartTicks,
  calculateLoggerDistance,
  capitalize,
  chunkArray,
  classNames,
  cleanObject,
  formatDate,
  formatDistance,
  formatPressure,
  formatTemperature,
  isImperialDefault,
  kalmanFilterAll,
  millisecondsToPaddedTime,
  millisecondsToTime,
  millisecondsToTimeArray,
  parseAddressComponents
});
