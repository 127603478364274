import { millisecondsToTime } from '@packages/utils';
import { useEffect, useMemo, useState } from 'react';

export function useCountdown(date?: Date) {
  const [countDown, setCountDown] = useState<number | null>();

  useEffect(() => {
    const interval = setInterval(() => {
      if (date == null) {
        setCountDown(null);
        return;
      }

      setCountDown(date.getTime() - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [date]);

  const formatted = useMemo(() => {
    if (countDown == null) {
      return null;
    }

    if (countDown >= 0 && countDown < 60000) {
      return 'in 0m';
    }

    if (countDown < 0 && countDown >= -60000) {
      return 'since 0m';
    }

    if (countDown < 0) {
      return `since ${millisecondsToTime(Math.abs(countDown), { seconds: false })}`;
    }

    return `in ${millisecondsToTime(countDown, { seconds: false })}`;
  }, [countDown]);

  return formatted;
}
