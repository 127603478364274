import { Routes as RouteDefs } from '@packages/constants';
import withUser from '../../components/HOC/withUser';
import AppLayout from '../../components/Layouts/AppLayout';
import { Meta } from '../../types';

const meta: Meta = {
  title: 'Tools',
  description: 'Scripts and tooling. Please use with caution and only if you know what you are doing.',
};

const Tools = () => {
  return (
    <AppLayout meta={meta}>
      <div className="mt-12 mb-4">
        <ul className="font-semibold text-blue-500 underline">
          <li>
            <a href={RouteDefs.wmt.TOOLS_MANAGE_DESTINATIONS}>Manage Destinations</a>
          </li>
        </ul>
      </div>
    </AppLayout>
  );
};

export default withUser(Tools);
