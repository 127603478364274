import { classNames } from '@packages/utils';
import { VFC } from 'react';

export type NavigationItemProps = {
  name: string;
  href: string;
  icon: any;
  current?: boolean;
  hidden?: boolean;
};

const NavigationItem: VFC<NavigationItemProps> = ({ name, href, icon: Icon, current, hidden = false }) => {
  return !hidden ? (
    <a
      key={name}
      href={href}
      className={classNames(
        current ? 'bg-primary-700 text-white' : 'text-blue-100 hover:bg-primary-700',
        'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
      )}
    >
      <Icon className="flex-shrink-0 w-6 h-6 mr-3 text-primary-300" aria-hidden="true" />
      {name}
    </a>
  ) : null;
};

export default NavigationItem;
