import { Assets } from '@packages/assets';
import { Routes } from '@packages/constants';
import { AuthError } from 'firebase/auth';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import AuthLayout from '../components/Layouts/AuthLayout';
import Alert from '../components/Shared/Alert';
import Button from '../components/Shared/Button';
import Card from '../components/Shared/Card';
import { login } from '../lib/firebase/auth';
import { Meta } from '../types';

export type LoginFormValues = {
  email: string;
  password: string;
  isRememberMeSet?: boolean;
};

const meta: Meta = {
  title: 'Sign in',
  description: 'Sign in to Paragonix Webmaster tools',
};

const Login = () => {
  const navigate = useNavigate();

  const [error, setError] = useState<string | undefined>();

  const initialValues = {
    email: '',
    password: '',
    isRememberMeSet: false,
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
    isRememberMeSet: yup.boolean().default(false),
  });

  async function onSubmit({ email, password, isRememberMeSet }: LoginFormValues) {
    try {
      await login(email, password, isRememberMeSet);
    } catch (err) {
      setError((err as AuthError).code);
      return;
    }

    navigate(Routes.wmt.ANALYTICS);
  }

  return (
    <AuthLayout meta={meta}>
      <div className="flex flex-col justify-center min-h-screen py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="w-auto h-6 mx-auto" src={Assets.logo.paragonixLogoLightPng} alt="Logo" />
          <h2 className="mt-6 text-center text-gray-500">Sign in to Webmaster Tools</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <Card>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => onSubmit(values)}>
              {({ isSubmitting }) => (
                <Form className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1">
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <div className="mt-1">
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Field
                        id="isRememberMeSet"
                        name="isRememberMeSet"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <label htmlFor="isRememberMeSet" className="block ml-2 text-sm text-gray-900">
                        Remember me
                      </label>
                    </div>
                  </div>

                  {error != null && <Alert title={error} type="danger" />}

                  <div>
                    <Button buttonType="submit" isLoading={isSubmitting} isFullWidth>
                      Sign in
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
