import { DESTINATION_OWNERS } from '@packages/constants';
import { useMemo, useState, VFC } from 'react';
import { useSessions } from '../../../contexts/useSessions';
import Card from '../../Shared/Card';
import DestinationsList from '../../Shared/DestinationsList';
import SearchBox from '../../Shared/SearchBox';

const DestinationsListSection: VFC = () => {
  const { stats } = useSessions();

  const [search, setSearch] = useState<string | undefined>();

  const destinations = useMemo(() => {
    if (stats?.counts?.destinations == null) {
      return [];
    }

    return Object.entries(stats.counts.destinations).map(([unos, { total }]) => {
      const DESTINATION = DESTINATION_OWNERS.find(elem => elem.unos === unos);

      return {
        unos,
        name: DESTINATION?.companyName ?? 'Unknown',
        salesRep: DESTINATION?.salesRep?.name ?? 'Unknown',
        areaDirector: DESTINATION?.areaDirector?.name ?? 'Unknown',
        total,
      };
    });
  }, [stats]);

  const filteredDestinations = useMemo(() => {
    return destinations.filter(destination => {
      if (search == null || search.length === 0) {
        return true;
      }

      return (
        (destination.unos != null && destination.unos.toLowerCase().includes(search.toLowerCase())) ||
        (destination.name != null && destination.name.toLowerCase().includes(search.toLowerCase())) ||
        (destination.salesRep != null && destination.salesRep.toLowerCase().includes(search.toLowerCase())) ||
        (destination.areaDirector != null && destination.areaDirector.toLowerCase().includes(search.toLowerCase()))
      );
    });
  }, [destinations, search]);

  return (
    <Card>
      <h2 className="mb-12 text-xl font-medium">Destinations</h2>

      <div className="space-y-2">
        <SearchBox onChange={search => setSearch(search)} placeholder="Search for destinations UNOS, name, owner or email ..." />

        <DestinationsList destinations={filteredDestinations} />
      </div>
    </Card>
  );
};

export default DestinationsListSection;
