import { ReadingCompressed, Session } from '@packages/firebase';
import { classNames } from '@packages/utils';
import { DateTime } from 'luxon';
import { VFC, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { read, utils } from 'xlsx';
import Loader from '../../Loader';

type ImportDropProps = {
  session: Session;
  onData: (data: ReadingCompressed[]) => void;
};

type DropzoneContentProps = {
  text?: string;
  error?: string;
  isLoading?: boolean;
};

const ImportDrop: VFC<ImportDropProps> = ({ onData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  async function onDrop(acceptedFiles: File[], rejectedFiles: FileRejection[]) {
    if (rejectedFiles.length > 0) {
      handleRejectedFiles(rejectedFiles);
      return;
    }

    if (acceptedFiles.length !== 1) {
      setError('Max. one file allowed');
    }

    const file = acceptedFiles[0];
    const buffer = await file.arrayBuffer();

    const workbook = read(buffer, { cellDates: false });

    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const sheetData: any = utils.sheet_to_json(sheet);

    // allowed events values
    const allowedEvents = ['unknown', 'started', 'stopped', 'userData', 'reading', 'safeShutdown', 'probeRemoved', 'probeAttached'];

    // find out correct time zone
    const timezoneString = sheetData[0]['__EMPTY']; // eslint-disable-line dot-notation
    const timezone = extractTimeZone(timezoneString);
    const timezoneIANA = mapToIANA(timezone);

    const data = (sheetData ?? []).map((row: any, index: number) => {
      // skip first row which is the table header
      if (index === 0) return undefined;

      const r = {
        date: row['__EMPTY'] || null, // eslint-disable-line dot-notation
        probe: row['__EMPTY_1'] || null, // eslint-disable-line dot-notation
        ambient: row['__EMPTY_2'] || null, // eslint-disable-line dot-notation
        event: row['__EMPTY_3'] ? row['__EMPTY_3'].charAt(0).toLowerCase() + row['__EMPTY_3'].slice(1) : 'reading', // eslint-disable-line dot-notation
      };

      // conitnue only with allowed events
      if (!allowedEvents.includes(r.event)) return undefined;

      const date = DateTime.fromObject({ year: 1899, month: 12, day: 30, hour: 0, minute: 0, second: 0 })
        .plus({ days: r.date })
        .setZone(timezoneIANA, { keepLocalTime: true });

      const rowData = {
        a: r.ambient,
        e: r.event,
        p: r.probe,
        t: date.toJSDate(),
      };

      return rowData;
    });

    const filteredData = data.filter(Boolean);
    const startedEvent = filteredData.find(d => d.e === 'started');

    if (filteredData.length === 0) {
      setError('No data found');
      return;
    }

    if (startedEvent == null) {
      onData([{ e: 'started', t: filteredData[0].t }, ...filteredData]);
    } else {
      onData(filteredData);
    }
  }

  function handleRejectedFiles(rejectedFiles: FileRejection[]) {
    const errorCode = rejectedFiles[0].errors[0].code;

    setError(`Failed with error code: ${errorCode}`);
    setIsLoading(false);
  }

  return (
    <div>
      <Dropzone maxFiles={1} multiple={false} maxSize={5e6} onDrop={(acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles)}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...(getRootProps() as any)}
            className={classNames(
              isDragActive ? 'bg-gray-100' : 'transparent',
              'flex items-center justify-center w-full border-2 border-gray-200 border-dashed rounded-md h-40 cursor-pointer',
            )}
          >
            <input {...getInputProps()} />

            <DropzoneContent text="Drop or click to select an excel/csv file (max. 5MB)" error={error} isLoading={isLoading} />
          </div>
        )}
      </Dropzone>
    </div>
  );
};

const DropzoneContent = ({ text, error, isLoading }: DropzoneContentProps) => {
  return (
    <div className="flex items-center justify-center w-1/2">
      {isLoading ? (
        <Loader size="xl" />
      ) : (
        <div className="flex flex-col items-center space-y-2">
          <RiFileExcel2Fill className="w-6 h-6 text-[#1D693F]" />
          <p className="max-w-xs text-sm font-light text-center text-gray-500">{text}</p>

          {error != null && <p className="text-sm text-red-500">{error}</p>}
        </div>
      )}
    </div>
  );
};

export default ImportDrop;

function mapToIANA(abbreviation: string): string {
  const map: { [key: string]: string } = {
    EST: 'America/New_York',
    EDT: 'America/New_York',
    CST: 'America/Chicago',
    CDT: 'America/Chicago',
    MST: 'America/Denver',
    MDT: 'America/Denver',
    PST: 'America/Los_Angeles',
    PDT: 'America/Los_Angeles',
    PT: 'America/Los_Angeles',
    // add any other abbreviations you need to handle here
  };

  return map[abbreviation] || 'UTC';
}

function extractTimeZone(str: string): string {
  const match = str.match(/\((\w+)\)/);
  return match ? match[1] : '';
}
