import { OrganType, ProductUtil } from '@packages/firebase';
import OrganBadge from '../../OrganBadge';

function productFormatter({ value, row }: { value: OrganType; row: any }) {
  return (
    <div className="pr-3 whitespace-nowrap">
      <div className="flex items-center">
        <OrganBadge organ={value} />

        <div className="ml-4">
          <div className="font-medium text-gray-900">{ProductUtil.toHeading(row.original.product)}</div>
        </div>
      </div>
    </div>
  );
}

export default productFormatter;
