import { formatTemperature } from '@packages/utils';
import { useMemo, VFC } from 'react';
import HorizontalReportPanel from '../HorizontalReportPanel';

type ReportDataTemperaturesProps = {
  min: number | undefined;
  avg: number | undefined;
  max: number | undefined;
};

const ReportDataTemperatures: VFC<ReportDataTemperaturesProps> = ({ min, avg, max }) => {
  const columns = useMemo(() => {
    return [
      {
        name: 'Minimum',
        value: formatTemperature(min),
      },
      {
        name: 'Average',
        value: formatTemperature(avg),
      },
      {
        name: 'Maximum',
        value: formatTemperature(max),
      },
    ];
  }, [min, avg, max]);

  return <HorizontalReportPanel columns={columns} />;
};

export default ReportDataTemperatures;
