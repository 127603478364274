import { DESTINATION_OWNERS } from '@packages/constants';
import { HiInformationCircle } from 'react-icons/hi2';
import { Tooltip, TooltipContainer } from '../../Tooltip';

function destinationFormatter({ value }: { value?: string }) {
  if (value == null) {
    return '-';
  }

  const destination = DESTINATION_OWNERS.find(elem => elem.unos === value);

  if (destination == null) {
    return value;
  }

  return (
    <div className="flex items-center space-x-1.5">
      <div>{destination.unos}</div>
      <TooltipContainer>
        <HiInformationCircle className="w-4 h-4" />
        <Tooltip>{destination.companyName}</Tooltip>
      </TooltipContainer>
    </div>
  );
}

export default destinationFormatter;
