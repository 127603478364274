import { generateCsvDataFromExportables, Reading, Session, TimelineEntry } from '@packages/firebase';
import { formatDate, millisecondsToTime } from '@packages/utils';
import { useMemo, VFC } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { HiArrowDownTray } from 'react-icons/hi2';

export type SessionInfoProps = {
  session: Session;
  readings: Reading[] | undefined;
  timeline: TimelineEntry[] | undefined;
};

const SessionInfo: VFC<SessionInfoProps> = ({ session, readings, timeline }) => {
  const startedEvent = useMemo(() => {
    if (readings == null || readings.length === 0) {
      return null;
    }

    return readings.find(reading => reading.isStartedEvent());
  }, [readings]);

  const endedEvent = useMemo(() => {
    if (readings == null || readings.length === 0) {
      return null;
    }

    return readings.find(reading => reading.isStoppedEvent());
  }, [readings]);

  const loggingDuration = useMemo(() => {
    if (startedEvent?.timestamp == null || endedEvent?.timestamp == null) {
      return '-';
    }

    const duration = endedEvent.timestamp.getTime() - startedEvent.timestamp.getTime();

    return duration > 0 ? millisecondsToTime(duration, { maxUnits: 3 }) : '-';
  }, [startedEvent, endedEvent]);

  const csv = useMemo(() => {
    if (readings?.length === 0 && timeline?.length === 0) {
      return null;
    }

    return {
      filename: `paragonix_${session?.joinKey ?? 'unknown'}`,
      ...generateCsvDataFromExportables(session, readings, timeline),
    };
  }, [session, readings, timeline]);

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Session & Logger Info</h3>
      </div>
      <div className="px-4 py-5 border-t border-gray-200 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Session Started At</dt>
            <dd className="mt-1 text-sm text-gray-900">{formatDate(session.createdAt)}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Logger Started At</dt>
            <dd className="mt-1 text-sm text-gray-900">{startedEvent?.timestamp != null ? formatDate(startedEvent.timestamp) : '-'}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Session Stopped At</dt>
            <dd className="mt-1 text-sm text-gray-900">{session.endedAt != null ? formatDate(session.endedAt) : '-'}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Logger Stopped At</dt>
            <dd className="mt-1 text-sm text-gray-900">{endedEvent?.timestamp != null ? formatDate(endedEvent.timestamp) : '-'}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Session Duration</dt>
            <dd className="mt-1 text-sm text-gray-900">{session.durationString()}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Logging Duration</dt>
            <dd className="mt-1 text-sm text-gray-900">{loggingDuration}</dd>
          </div>
          <div className="sm:col-span-2">
            <dd className="mt-1 text-sm text-gray-900">
              <ul className="border border-gray-200 divide-y divide-gray-200 rounded-md">
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex items-center flex-1 w-0">
                    <HiArrowDownTray className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                    <span className="flex-1 w-0 ml-2 truncate">Session Report</span>
                  </div>
                  <div className="flex-shrink-0">
                    {csv != null ? (
                      <CsvDownloader filename={csv.filename} extension=".csv" separator="," columns={csv.columns} datas={csv.data}>
                        <span className="font-medium text-blue-600 hover:text-blue-500">Download</span>
                      </CsvDownloader>
                    ) : (
                      <span className="font-medium text-gray-400">Report unavailable</span>
                    )}
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default SessionInfo;
