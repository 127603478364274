import { User } from '@packages/firebase';
import { User as AuthUser } from 'firebase/auth';
import { createContext, useContext } from 'react';

export type AuthUserContextProps = {
  authUser: AuthUser;
  user: User;
  isUserLoading: boolean;
};

const AuthUserContext = createContext<AuthUserContextProps | undefined>(undefined);

function useAuthUser() {
  const context = useContext(AuthUserContext);

  if (context == null) {
    throw Error('Auth user must be used inside auth user context');
  }

  return context;
}

export { AuthUserContext, useAuthUser };
