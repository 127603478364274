import { DESTINATION_OWNERS } from '@packages/constants';
import {
  DonationType,
  DonationTypeUtil,
  OrganTypeUtil,
  ProductType,
  ProductUtil,
  Session,
  SessionType,
  SessionTypeUtil,
  TimelineEntryTypeUtil,
} from '@packages/firebase';
import { SessionTrackingMap, timelineEntryIcon } from '@packages/ui';
import { classNames, formatDate, formatDistance, millisecondsToPaddedTime } from '@packages/utils';
import { useFormik } from 'formik';
import { FC, VFC, useMemo, useState } from 'react';
import { HiMapPin, HiUserGroup, HiWifi, HiXMark } from 'react-icons/hi2';
import { MdTimeline } from 'react-icons/md';
import * as yup from 'yup';
import { ProductColorContext } from '../../../contexts/useProductColor';
import { useFetchSessionDetails } from '../../../lib/firebase/firestore';
import { calcReportDataForSessionOnCall, endSession } from '../../../lib/firebase/functions';
import Badge from '../Badge';
import Button from '../Button';
import Modal from '../Modal';
import KPI from '../Statistics/KPI';
import { ETA } from './ETA';
import ImportLogs from './ImportLogs';
import LiveTemperatures from './LiveTemperatures';
import MembersTable from './MembersTable';
import PressureChart from './PressureChart';
import ReportDataPressure from './ReportDataPressure';
import ReportDataTemperatures from './ReportDataTemperatures';
import SessionAuditLogs from './SessionAuditLogs';
import SessionInfo from './SessionInfo';
import TemperatureChart from './TemperatureChart';
import Timeline, { EventIcon } from './Timeline';

export type SessionDetailModalProps = {
  session: Session;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

type EndSessionButtonProps = {
  session: Session;
};

type ModalSectionProps = {
  title?: string;
  isPadded?: boolean;
  isTitlePadded?: boolean;
};

type StateBadgeProps = {
  text: string;
  isActive: boolean;
};

const SessionDetailModal: VFC<SessionDetailModalProps> = ({ session, isOpen, setIsOpen }) => {
  const { timeline, members, readings, auditLogs, locations, isLoading } = useFetchSessionDetails(session.id);

  const productColors = useMemo(() => {
    const { primary, primaryLight } = OrganTypeUtil.colors(session.organ);

    return {
      default: primary,
      light: primaryLight,
    };
  }, [session.product]);

  const createdByMember = useMemo(() => {
    if (members == null) {
      return null;
    }

    return members.find(member => member.id === session.createdBy);
  }, [members, session.createdBy]);

  const sessionState = useMemo(() => {
    return {
      text: !session.finished ? 'Session active' : 'Session stopped',
      isActive: !session.finished,
    };
  }, [session.finished]);

  const loggerState = useMemo(() => {
    if (session.finished) {
      return null;
    }

    if (session.loggerState === 'STARTED') {
      return {
        text: 'Logger active',
        isActive: true,
      };
    }

    if (session.loggerState === 'STOPPED') {
      return {
        text: 'Logger stopped',
        isActive: false,
      };
    }

    return {
      text: 'No Logger information',
      isActive: false,
    };
  }, [session.loggerState]);

  const destinationString = useMemo(() => {
    if (session.destination == null) {
      return null;
    }

    const destination = DESTINATION_OWNERS.find(elem => elem.unos === session.destination);

    if (destination == null) {
      return session.destination;
    }

    return `${destination.companyName} (${destination.unos})`;
  }, [session.destination]);

  const onRegenerateReportDataClick = () => {
    calcReportDataForSessionOnCall(session.id);
  };

  if (isLoading) {
    return null;
  }

  return (
    <ProductColorContext.Provider value={productColors}>
      <Modal size="5xl" isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="bg-gray-50">
          <div className="flex w-full items-center justify-between bg-gray-100 px-6 py-4">
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <h1 className="text-2xl font-semibold">
                  {ProductUtil.toHeading(session.product)}{' '}
                  {session.product === ProductType.VANTAGEPOINT ? `(${OrganTypeUtil.organString(session.organ)})` : ''}
                </h1>

                <Badge styleType={session.donationType === DonationType.DBD ? 'donation_type_dbd' : 'donation_type_dcd'} size="xs">
                  Donor Type →&nbsp;<span className="font-bold">{DonationTypeUtil.toUIString(session.donationType)}</span>
                </Badge>

                <Badge
                  styleType={session.sessionType === SessionType.TRAINING ? 'session_type_training' : 'session_type_clinical'}
                  size="xs"
                >
                  Session Type →&nbsp;<span className="font-bold">{SessionTypeUtil.toUIString(session.sessionType)}</span>
                </Badge>
              </div>

              <div className="text-sm text-gray-500">
                Started by <span className="font-semibold text-gray-800">{createdByMember?.fullname ?? 'Unknown'}</span>{' '}
                {createdByMember?.email != null && <span>({createdByMember.email})</span>}
              </div>
            </div>

            <Button styleType="light" size="md" onClick={() => setIsOpen(false)}>
              <HiXMark className="h-6 w-6" />
            </Button>
          </div>

          <main className="space-y-10 p-6">
            <div className="flex w-full justify-center">
              <div className="flex flex-col justify-center">
                <div className="flex flex-col items-center justify-center">
                  <div>
                    <img className="w-48" src={ProductUtil.imagePath(session.product)} alt="" />
                  </div>

                  <div className="mb-4 flex items-center space-x-2">
                    <StateBadge text={sessionState.text} isActive={sessionState.isActive} />
                    {loggerState != null && <StateBadge text={loggerState.text} isActive={loggerState.isActive} />}
                  </div>

                  <div className="space-y-1">
                    {destinationString != null && (
                      <div className="mb-4 text-center text-base text-gray-500">
                        <div className="font-semibold">{destinationString}</div>
                      </div>
                    )}

                    <div className="text-center text-sm text-gray-500">
                      Session Key: <span className="font-semibold">{session.joinKey ?? '-'}</span>
                    </div>

                    <div className="text-center text-sm text-gray-500">
                      Donor ID: <span className="font-semibold">{session.unosId ?? '-'}</span>
                    </div>

                    <div className="text-center text-sm text-gray-500">
                      Logger SN: <span className="font-semibold">{session.loggerSerialNo ?? '-'}</span>
                    </div>

                    <div className="text-center text-sm text-gray-500">
                      Advanced Tracker ID: <span className="font-semibold">{session.advancedTrackerId ?? '-'}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-5">
              <div className="space-y-5">
                <ModalSection title="Members" isPadded>
                  <MembersTable members={members} />
                </ModalSection>

                {session.loggerData != null && (
                  <ModalSection>
                    <div className="py-8">
                      <LiveTemperatures probe={session.loggerData.probe} ambient={session.loggerData.ambient} />
                    </div>
                  </ModalSection>
                )}

                {session.reportData != null && (
                  <ModalSection>
                    <div className="py-8">
                      <ReportDataTemperatures
                        min={session.reportData.probeMinimum}
                        avg={session.reportData.probeAverage}
                        max={session.reportData.probeMaximum}
                      />
                    </div>
                  </ModalSection>
                )}

                {session.reportData != null && session.product === ProductType.BAROGUARD && (
                  <ModalSection>
                    <div className="py-8">
                      <ReportDataPressure
                        min={session.reportData.pressureMinimum}
                        avg={session.reportData.pressureAverage}
                        max={session.reportData.pressureMaximum}
                      />
                    </div>
                  </ModalSection>
                )}

                {/* Ischemic Time */}
                <ModalSection>
                  <div className="flex flex-col items-center justify-center space-y-5 py-8">
                    <div className="flex flex-col items-center">
                      <span className="text-gray-400">Ischemic Time</span>
                      <span className="text-2xl font-medium">
                        {millisecondsToPaddedTime(session.ischemicTime?.durationInMilliseconds())}
                      </span>

                      {session.ischemicTime != null && (
                        <div className="mt-4 space-y-2">
                          <div className="flex items-center space-x-3">
                            <EventIcon
                              icon={timelineEntryIcon(session.ischemicTime.startType)}
                              color={productColors.light}
                              backgroundColor={productColors.default}
                            />

                            <div className="flex flex-col text-sm">
                              <span className="text-gray-500">
                                {TimelineEntryTypeUtil.toUIString(session.ischemicTime.startType, session.product)}
                              </span>
                              <span className="text-black">{formatDate(session.ischemicTime.startedAt)}</span>
                            </div>
                          </div>

                          <div className="flex items-center space-x-3">
                            <EventIcon
                              icon={timelineEntryIcon(session.ischemicTime.endType)}
                              color={productColors.light}
                              backgroundColor={productColors.default}
                            />

                            <div className="flex flex-col text-sm">
                              <span className="text-gray-500">
                                {TimelineEntryTypeUtil.toUIString(session.ischemicTime.endType, session.product)}
                              </span>
                              <span className="text-black">{formatDate(session.ischemicTime.endedAt)}</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </ModalSection>

                {/* ETA */}
                <ModalSection>
                  <ETA session={session} members={members ?? []} />
                </ModalSection>

                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
                  <ModalSection>
                    <KPI
                      name="Distance"
                      value={session.reportData?.distance != null ? formatDistance(session.reportData.distance) : '-'}
                      icon={HiMapPin}
                      colors={{ icon: productColors.light, background: productColors.default }}
                    />
                  </ModalSection>

                  <ModalSection>
                    <KPI
                      name="Number of Members"
                      value={session.metrics?.memberCount ?? '-'}
                      icon={HiUserGroup}
                      colors={{ icon: productColors.light, background: productColors.default }}
                    />
                  </ModalSection>

                  <ModalSection>
                    <KPI
                      name="Number of Timeline Entries"
                      value={session.metrics?.timelineCount ?? '-'}
                      icon={MdTimeline}
                      colors={{ icon: productColors.light, background: productColors.default }}
                    />
                  </ModalSection>

                  <ModalSection>
                    <KPI
                      name="Number of Readings"
                      value={readings?.length ?? '-'}
                      icon={HiWifi}
                      colors={{ icon: productColors.light, background: productColors.default }}
                    />
                  </ModalSection>
                </dl>
              </div>

              <SessionInfo session={session} readings={readings} timeline={timeline} />

              <ModalSection title="Tracking Map" isPadded>
                <SessionTrackingMap
                  sessions={[{ session, locations }]}
                  options={{ showInfoWindows: false, height: '500px', showPolylines: true }}
                />
              </ModalSection>

              <ModalSection title="Temperatures (°C)" isPadded>
                <TemperatureChart readings={readings} />
              </ModalSection>

              {session.product === ProductType.BAROGUARD && (
                <ModalSection title="Pressure (cmH₂0)" isPadded>
                  <PressureChart readings={readings} />
                </ModalSection>
              )}

              <ModalSection title="Timeline" isPadded>
                <Timeline timeline={timeline} product={session.product} />
              </ModalSection>

              <ModalSection title="Audit Logs" isPadded>
                <SessionAuditLogs logs={auditLogs} members={members} />
              </ModalSection>

              <ModalSection title="Danger Zone" isPadded>
                <div className="space-y-8">
                  {session.finished && (
                    <>
                      <div className="space-y-4">
                        <span className="font-semibold text-gray-900">Import Logger Data</span>
                        <p className="text-gray-500">
                          This is where you can import and override session logs.{' '}
                          <span className="font-semibold">Proceed with caution, this cannot be undone</span>.
                        </p>
                      </div>

                      <ImportLogs session={session} timeline={timeline} />

                      <hr />

                      <Button onClick={() => onRegenerateReportDataClick()}>Re-generate report data</Button>
                    </>
                  )}

                  {!session.finished && (
                    <div className="space-y-4">
                      <div className="space-y-2">
                        <span className="font-semibold text-gray-900">Stop Session</span>
                        <p className="text-gray-500">
                          This is where you can stop the session. Once you click the button you will be asked for confirmation by entering
                          the sessions join key. <span className="font-semibold">Proceed with caution, this cannot be undone</span>.
                        </p>
                      </div>

                      <EndSessionButton session={session} />
                    </div>
                  )}
                </div>
              </ModalSection>
            </div>
          </main>
        </div>
      </Modal>
    </ProductColorContext.Provider>
  );
};

const EndSessionButton: VFC<EndSessionButtonProps> = ({ session }) => {
  const [isEndingSession, setIsEndingSession] = useState(false);

  const formik = useFormik({
    initialValues: {
      confirmationText: '',
    },
    validationSchema: yup.object().shape({
      confirmationText: yup.string().required(),
    }),
    onSubmit: async ({ confirmationText }: { confirmationText: string }) => {
      if (confirmationText !== session.joinKey.toString()) {
        return;
      }

      await endSession(session.id);

      setIsEndingSession(false);
    },
  });

  if (!isEndingSession) {
    return (
      <div className="space-y-2">
        <Button styleType="danger" onClick={() => setIsEndingSession(true)}>
          Stop Session
        </Button>
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-2">
          <label htmlFor="confirmationText" className="font-medium text-gray-900">
            Confirm you want to end this session by typing its join key ({session.joinKey.toString()}):
          </label>
          <input
            id="confirmationText"
            name="confirmationText"
            type="text"
            placeholder={session.joinKey.toString()}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            onChange={formik.handleChange}
            value={formik.values.confirmationText}
          />
        </div>

        <div className="mt-4 space-x-2">
          <Button
            buttonType="submit"
            styleType="danger"
            disabled={formik.values.confirmationText !== session.joinKey.toString() || formik.errors.confirmationText != null}
            isLoading={formik.isSubmitting}
          >
            Stop Session
          </Button>
          <Button styleType="light" onClick={() => setIsEndingSession(false)}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

const ModalSection: FC<ModalSectionProps> = ({ title, isPadded = false, isTitlePadded = true, children }) => {
  return (
    <div className={classNames(isPadded ? 'p-8' : '', 'rounded-md bg-white shadow')}>
      {title != null && <h2 className={classNames(isTitlePadded ? 'mb-6 pb-2' : '', 'text-xl font-medium')}>{title}</h2>}

      {children}
    </div>
  );
};

const StateBadge: VFC<StateBadgeProps> = ({ text, isActive }) => {
  return (
    <Badge styleType={isActive ? 'success' : 'danger'}>
      <span>{text}</span>
      {isActive && <div className="ml-2 h-2 w-2 animate-ping rounded-full bg-green-500" />}
    </Badge>
  );
};

export default SessionDetailModal;
