import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import app from './config';

export function getFirebaseStorage() {
  return getStorage(app);
}

export async function uploadImageFromBlob(blobUrl: string, name: string, path?: string) {
  const storage = getFirebaseStorage();
  const pathWithTrailingSlash = `${path || ''}${path && !path.endsWith('/') ? '/' : ''}`;
  const storageRef = ref(storage, `${pathWithTrailingSlash}${name}`);

  const blob = await fetch(blobUrl).then(r => r.blob());

  await uploadBytes(storageRef, blob);
}

export async function getStorageDownloadURL(name: string, path: string | undefined) {
  const storage = getFirebaseStorage();
  const pathWithTrailingSlash = `${path || ''}${path && !path.endsWith('/') ? '/' : ''}`;
  const storageRef = ref(storage, `${pathWithTrailingSlash}${name}`);

  return getDownloadURL(storageRef);
}
