import { LoadGoogle } from '@packages/ui';
import { HelmetProvider } from 'react-helmet-async';
import Routes from './routes';

function App() {
  return (
    <LoadGoogle libraries={['places']}>
      <HelmetProvider>
        <Routes />
      </HelmetProvider>
    </LoadGoogle>
  );
}

export default App;
