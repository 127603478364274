import { Organization } from '@packages/firebase';
import { classNames } from '@packages/utils';
import { useState, VFC } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { read, utils } from 'xlsx';
import { ImportData } from '.';
import Loader from '../../../Shared/Loader';

type ImportDropProps = {
  organizations: Organization[];
  onData: (data: ImportData[]) => void;
};

type DropzoneContentProps = {
  text?: string;
  error?: string;
  isLoading?: boolean;
};

const ImportDrop: VFC<ImportDropProps> = ({ organizations, onData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  async function onDrop(acceptedFiles: File[], rejectedFiles: FileRejection[]) {
    if (rejectedFiles.length > 0) {
      handleRejectedFiles(rejectedFiles);
      return;
    }

    if (acceptedFiles.length !== 1) {
      setError('Max. one file allowed');
    }

    const file = acceptedFiles[0];
    const buffer = await file.arrayBuffer();

    const workbook = read(buffer);

    const data = workbook.SheetNames.flatMap(sheetName => {
      const sheet = workbook.Sheets[sheetName];
      const sheetData = utils.sheet_to_json(sheet);

      return (sheetData ?? []).map((row: any) => {
        // const [handle, ...name] = row.Name != null ? row.Name.split(' ') : ['', ''];

        const rowData = {
          // name: name.join(' ').length > 0 ? name.join(' ') : undefined,
          // handle: handle != null && handle.length > 0 ? handle : undefined,
          name: row.Name,
          handle: row.Handle,
          address: row.Address,
          country: row.Country != null ? row.Country.toUpperCase() : undefined,
          organTypes: row.Organs != null ? row.Organs.split(',').map((organ: string) => organ.trim().toLowerCase()) : [],
          categories: row.Categories != null ? row.Categories.split(',').map((category: string) => category.trim().toLowerCase()) : [],
          domains: row.Domains != null ? row.Domains.split(',').map((domain: string) => domain.trim().toLowerCase()) : [],
        };

        return {
          organization: rowData,
          errors: [
            rowData.name == null || rowData.name.length === 0 ? 'No name found.' : null,
            rowData.handle == null || rowData.handle.length === 0 ? 'No handle found.' : null,
            organizations.find(organization => organization.handle === rowData.handle) != null
              ? 'Organization with this handle aleady exists'
              : null,
          ].filter(elem => elem != null) as string[],
        };
      });
    });

    onData(data);
  }

  function handleRejectedFiles(rejectedFiles: FileRejection[]) {
    const errorCode = rejectedFiles[0].errors[0].code;

    setError(`Failed with error code: ${errorCode}`);
    setIsLoading(false);
  }

  return (
    <div>
      <Dropzone maxFiles={1} multiple={false} maxSize={5e6} onDrop={(acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles)}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...(getRootProps() as any)}
            className={classNames(
              isDragActive ? 'bg-gray-100' : 'transparent',
              'flex items-center justify-center w-full border-2 border-gray-200 border-dashed rounded-md h-40 cursor-pointer',
            )}
          >
            <input {...getInputProps()} />

            <DropzoneContent text="Drop or click to select an excel/csv file (max. 5MB)" error={error} isLoading={isLoading} />
          </div>
        )}
      </Dropzone>
    </div>
  );
};

const DropzoneContent = ({ text, error, isLoading }: DropzoneContentProps) => {
  return (
    <div className="flex items-center justify-center w-1/2">
      {isLoading ? (
        <Loader size="xl" />
      ) : (
        <div className="flex flex-col items-center space-y-2">
          <RiFileExcel2Fill className="w-6 h-6 text-[#1D693F]" />
          <p className="max-w-xs text-sm font-light text-center text-gray-500">{text}</p>

          {error != null && <p className="text-sm text-red-500">{error}</p>}
        </div>
      )}
    </div>
  );
};

export default ImportDrop;
