import { classNames } from '@packages/utils';
import { ButtonHTMLAttributes, FC } from 'react';
import { Link } from 'react-router-dom';
import { BaseSizes, BaseStyleTypes } from '../../types';
import Loader from './Loader';

export type ButtonTypes = 'button' | 'submit' | 'reset' | 'link';
export type ButtonStyleTypes = BaseStyleTypes | 'light' | 'excel';
export type ButtonSizes = BaseSizes;

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  styleType?: ButtonStyleTypes;
  buttonType?: ButtonTypes;
  size?: ButtonSizes;
  isFullWidth?: boolean;
  isLoading?: boolean;
  href?: string;
};

const Button: FC<ButtonProps> = ({
  styleType = 'default',
  buttonType = 'button',
  size = 'md',
  isFullWidth = false,
  isLoading = false,
  href,
  children,
  ...rest
}) => {
  let sizeSpecificClassNames;

  switch (size) {
    case 'xs':
      sizeSpecificClassNames = 'px-2.5 py-1.5 text-xs';
      break;
    case 'sm':
      sizeSpecificClassNames = 'px-3 py-2 text-sm leading-4';
      break;
    case 'md':
      sizeSpecificClassNames = 'px-4 py-2 text-sm';
      break;
    case 'lg':
      sizeSpecificClassNames = 'px-4 py-2 text-base';
      break;
    case 'xl':
      sizeSpecificClassNames = 'px-6 py-3 text-base';
      break;
    default:
      sizeSpecificClassNames = 'px-4 py-2 text-sm';
      break;
  }

  const className = classNames(
    styleType,
    sizeSpecificClassNames,
    isFullWidth ? 'w-full' : '',
    'button inline-flex items-center justify-center font-medium border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed',
  );

  if (buttonType === 'link') {
    return (
      <Link to={href ?? '#'}>
        <span className={className}>{children}</span>
      </Link>
    );
  }

  return (
    <button
      {...rest}
      // eslint-disable-next-line react/button-has-type
      type={buttonType}
      className={className}
    >
      <div className={classNames(isLoading ? 'space-x-2' : '', 'flex flex-row items-center justify-center')}>
        {isLoading && <Loader size="sm" />}
        {children}
      </div>
    </button>
  );
};

export default Button;
