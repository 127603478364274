import { AuditLog, AuditLogType, AuditLogTypeUtil, SessionMember } from '@packages/firebase';
import { formatDate } from '@packages/utils';
import { DateTime } from 'luxon';
import { VFC } from 'react';
import {
  HiArrowRightOnRectangle,
  HiArrowUpTray,
  HiBackspace,
  HiClock,
  HiPlay,
  HiPlus,
  HiQuestionMarkCircle,
  HiSignal,
  HiSignalSlash,
  HiStop,
  HiUserPlus,
  HiXMark,
} from 'react-icons/hi2';
import { MdFlight, MdOutlineLocationOff, MdOutlineLocationOn } from 'react-icons/md';
import { TbDatabaseImport } from 'react-icons/tb';
import { useProductColor } from '../../../contexts/useProductColor';
import DataState from '../DataState';

export type SessionAuditLogsProps = {
  logs: AuditLog[] | undefined;
  members: SessionMember[] | undefined;
};

const ICONS = {
  [AuditLogType.SESSION_CREATED]: HiPlus,
  [AuditLogType.SESSION_JOINED]: HiUserPlus,
  [AuditLogType.SESSION_LEFT]: HiArrowRightOnRectangle,
  [AuditLogType.SESSION_STOPPED]: HiStop,
  [AuditLogType.LOGGER_STARTED]: HiPlay,
  [AuditLogType.LOGGER_CONNECTED]: HiSignal,
  [AuditLogType.LOGGER_DISCONNECTED]: HiSignalSlash,
  [AuditLogType.LOGGER_STOPPED]: HiStop,
  [AuditLogType.TIMELINEENTRY_ADDED]: HiPlus,
  [AuditLogType.TIMELINEENTRY_UPDATED]: HiArrowUpTray,
  [AuditLogType.TIMELINEENTRY_DELETED]: HiXMark,
  [AuditLogType.ETA_SET]: HiClock,
  [AuditLogType.ETA_DELETED]: HiXMark,
  [AuditLogType.FLIGHT_ADDED]: MdFlight,
  [AuditLogType.FLIGHT_DELETED]: HiXMark,
  [AuditLogType.ADVANCED_TRACKING_ACTIVATED]: HiSignal,
  [AuditLogType.ADVANCED_TRACKING_DEACTIVATED]: HiSignalSlash,
  [AuditLogType.UNOSID_SET]: HiPlus,
  [AuditLogType.UNOSID_DELETED]: HiBackspace,
  [AuditLogType.DESTINATION_SET]: MdOutlineLocationOn,
  [AuditLogType.DESTINATION_DELETED]: MdOutlineLocationOff,
  [AuditLogType.MANUAL_IMPORT_SESSION_LOGS]: TbDatabaseImport,
  unknown: HiQuestionMarkCircle,
};

const SessionAuditLogs: VFC<SessionAuditLogsProps> = ({ logs, members }) => {
  const colors = useProductColor();

  return (
    <DataState
      hasData={members != null && members.length > 0 && logs != null && logs.length > 0}
      isLoading={false}
      text="No Audit Logs found"
    >
      <div className="flow-root">
        <ul className="-mb-8">
          {(logs as AuditLog[]).map((log, index) => {
            const Icon = ICONS[log.event] ?? ICONS.unknown;

            return (
              <li key={`audit-log-item-${index}`}>
                <div className="relative pb-8">
                  {index !== (logs as AuditLog[]).length - 1 ? (
                    <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex items-start space-x-3">
                    <div className="relative">
                      <span
                        className="flex h-8 w-8 items-center justify-center rounded-md ring-8 ring-white"
                        style={{ color: colors.light, backgroundColor: colors.default }}
                      >
                        <Icon className="h-5 w-5" />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1">
                      <div>
                        <div className="text-sm">
                          <span className="font-medium">
                            {(members as SessionMember[]).find(member => member.id === log.userId)?.fullname ?? 'Unknown'}
                          </span>{' '}
                          <span className="text-gray-500">{AuditLogTypeUtil.toUserUIString(log.event)}</span>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-500">
                          {`${DateTime.fromJSDate(log.timestampUser).toRelative({ padding: 1000 })} (${formatDate(log.timestampUser)} ${
                            log.timezone
                          })`}
                        </p>
                      </div>
                      <div className="mt-2 text-sm text-gray-700">
                        <dl>
                          <dt className="font-semibold">OS (Version):</dt>
                          <dd className="mb-1">
                            {log.os} {log.os !== 'Web' ? `(${log.osVersion})` : ''}
                          </dd>
                          <dt className="font-semibold">Device:</dt>
                          <dd className="mb-1">{log.device}</dd>
                          <dt className="font-semibold">App Version:</dt>
                          <dd className="mb-1">{log.appVersion}</dd>
                          {log.context != null && (
                            <>
                              <dt className="font-semibold">Context:</dt>
                              <dd className="mb-1">{log.context}</dd>
                            </>
                          )}
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </DataState>
  );
};

export default SessionAuditLogs;
