import { millisecondsToTimeArray } from '@packages/utils';

export function calculateChartTicks(startMilliseconds: number, latestMilliseconds: number) {
  const [days, hours, minutes] = millisecondsToTimeArray(latestMilliseconds - startMilliseconds);

  const INTERVAL_DURATION_MAP = [
    { condition: days === 1, intervalToDuration: 3600 * 1000 * 4 },
    { condition: days > 0 && days < 5, intervalToDuration: 3600 * 1000 * 8 },
    { condition: days > 0, intervalToDuration: 3600 * 1000 * 24 },
    { condition: hours === 1, intervalToDuration: 60 * 1000 * 10 },
    { condition: hours > 0 && hours < 5, intervalToDuration: 1800 * 1000 },
    { condition: hours > 0 && hours < 12, intervalToDuration: 3600 * 1000 },
    { condition: hours > 0 && hours < 24, intervalToDuration: 3600 * 1000 * 2 },
    { condition: hours > 0, intervalToDuration: 3600 * 1000 },
    { condition: minutes > 0 && minutes <= 15, intervalToDuration: 60 * 1000 },
    { condition: minutes > 0 && minutes <= 30, intervalToDuration: 60 * 1000 * 5 },
    { condition: minutes > 0, intervalToDuration: 60 * 1000 * 10 },
  ];

  for (const elem of INTERVAL_DURATION_MAP) {
    if (elem.condition) {
      return calculateTicks(elem.intervalToDuration, startMilliseconds, latestMilliseconds);
    }
  }

  return [];
}

function calculateTicks(intervalDuration: number, startMilliseconds: number, latestMilliseconds: number) {
  let millisecondsCount = startMilliseconds;
  let count = 0;

  const ticks: number[] = [];

  while (millisecondsCount < latestMilliseconds) {
    ticks.push(intervalDuration * count);

    millisecondsCount += intervalDuration;
    count += 1;
  }

  return ticks;
}
