import { classNames } from '@packages/utils';
import { FC } from 'react';
import { BaseSizes, BaseStyleTypes } from '../../types';

export type BadgeStyleTypes =
  | BaseStyleTypes
  | 'light'
  | 'success'
  | 'warning'
  | 'danger'
  | 'disabled'
  | 'placeholder'
  | 'donation_type_dbd'
  | 'donation_type_dcd'
  | 'session_type_clinical'
  | 'session_type_training';
export type BadgeSizes = BaseSizes;

export type BadgeProps = {
  styleType?: BadgeStyleTypes;
  size?: BadgeSizes;
  isRound?: boolean;
};

const Badge: FC<BadgeProps> = ({ styleType = 'default', size = 'md', isRound = false, children }) => {
  let sizeSpecificClassNames;

  switch (size) {
    case 'xs':
      sizeSpecificClassNames = 'px-2.5 py-1.5 text-xs';
      break;
    case 'sm':
      sizeSpecificClassNames = 'px-3 py-2 text-sm leading-4';
      break;
    case 'md':
      sizeSpecificClassNames = 'px-4 py-2 text-sm';
      break;
    case 'lg':
      sizeSpecificClassNames = 'px-4 py-2 text-base';
      break;
    case 'xl':
      sizeSpecificClassNames = 'px-6 py-3 text-base';
      break;
    default:
      sizeSpecificClassNames = 'px-4 py-2 text-sm';
      break;
  }

  const className = classNames(
    styleType,
    sizeSpecificClassNames,
    isRound ? 'rounded-full' : 'rounded-md',
    'badge inline-flex items-center text-sm font-medium',
  );

  return <span className={className}>{children}</span>;
};

export default Badge;
