import {
  AuditLog,
  AuditLogType,
  DistanceUnit,
  Log,
  LoggerLocation,
  ReadingCompressed,
  Session,
  SessionMember,
  TimelineEntry,
  TimelineEntryType,
  User,
  auditLogConverter,
  locationConverter,
  logConverter,
  sessionConverter,
  sessionMemberConverter,
  timelineConverter,
  userConverter,
} from '@packages/firebase';
import { Timestamp, addDoc, collection, doc, getDoc, getFirestore, query, serverTimestamp, setDoc, writeBatch } from 'firebase/firestore';
import { useMemo } from 'react';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import { Destination } from '../../components/Pages/Tools/ManageDestinations/ImportDrop';
import { getFirebaseAuth } from './auth';
import app from './config';
import { calcReportDataForSessionOnCall } from './functions';

export type CreateUserParams = {
  firstname: string;
  lastname: string;
  settings: {
    hasTimezone: boolean;
    has24Hours: boolean;
    distanceUnit: DistanceUnit;
  };
};

export function getFirebaseStore() {
  return getFirestore(app);
}

export async function getUser(userId: string) {
  const firestore = getFirebaseStore();

  const user = await getDoc(doc(firestore, 'users', userId).withConverter<User>(userConverter));

  return user.data();
}

export function useFetchUser() {
  const auth = getFirebaseAuth();
  const firestore = getFirebaseStore();

  const [user, isUserLoading] = useDocumentData(
    auth.currentUser?.uid != null ? doc(firestore, 'users', auth.currentUser.uid).withConverter<User>(userConverter) : null,
  );

  return {
    user,
    isUserLoading,
  };
}

export function useFetchUsers() {
  const firestore = getFirebaseStore();

  const [users, areUsersLoading] = useCollectionData(collection(firestore, 'users').withConverter<User>(userConverter));

  return {
    users,
    areUsersLoading,
  };
}

export function useFetchSessions() {
  const firestore = getFirebaseStore();

  const [sessions, areSessionsLoading] = useCollectionData(collection(firestore, 'sessions').withConverter<Session>(sessionConverter));

  return {
    sessions,
    areSessionsLoading,
  };
}

export function useFetchSessionDetails(sessionId: string) {
  const [timeline, isTimelineLoading] = useCollectionData(getSessionTimeline(sessionId));
  const [members, areMembersLoading] = useCollectionData(getSessionMembers(sessionId));
  const [auditLogs, areAuditLogsLoading] = useCollectionData(getSessionAuditLogs(sessionId));
  const [locations, areLocationsLoading] = useCollectionData(getLocations(sessionId));
  const [log, isLogLoading] = useDocumentData(getSessionReadings(sessionId));

  const sortedTimeline = useMemo(() => {
    if (timeline == null) {
      return [];
    }

    return timeline.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
  }, [timeline]);

  const sortedReadings = useMemo(() => {
    if (log?.log == null) {
      return [];
    }

    return log.log.map(elem => elem).sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
  }, [log]);

  const sortedAuditLogs = useMemo(() => {
    if (auditLogs == null) {
      return [];
    }

    return auditLogs.sort((a, b) => a.timestampUser.getTime() - b.timestampUser.getTime());
  }, [auditLogs]);

  const sortedLocations = useMemo(() => {
    if (locations == null) {
      return [];
    }

    return locations.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
  }, [locations]);

  return {
    timeline: sortedTimeline,
    members,
    auditLogs: sortedAuditLogs,
    readings: sortedReadings,
    locations: sortedLocations,
    isLoading: isTimelineLoading || areMembersLoading || isLogLoading || areAuditLogsLoading || areLocationsLoading,
  };
}

export function getSessionTimeline(sessionId: string) {
  const firestore = getFirebaseStore();

  return query(collection(firestore, 'sessions', sessionId, 'timeline')).withConverter<TimelineEntry>(timelineConverter);
}

export function getSessionMembers(sessionId: string) {
  const firestore = getFirebaseStore();

  return query(collection(firestore, 'sessions', sessionId, 'users')).withConverter<SessionMember>(sessionMemberConverter);
}

export function getSessionReadings(sessionId: string) {
  const firestore = getFirebaseStore();

  return doc(firestore, 'sessions', sessionId, 'log', 'log').withConverter<Log>(logConverter);
}

export function getSessionAuditLogs(sessionId: string) {
  const firestore = getFirebaseStore();

  return query(collection(firestore, 'sessions', sessionId, 'auditLogs')).withConverter<AuditLog>(auditLogConverter);
}

export function getLocations(sessionId: string) {
  const firestore = getFirebaseStore();

  return query(collection(firestore, 'sessions', sessionId, 'locations')).withConverter<LoggerLocation>(locationConverter);
}

export async function importDestinations(destinations: Destination[]) {
  const firestore = getFirebaseStore();

  const batch = writeBatch(firestore);

  for (const destination of destinations) {
    if (destination.handle == null) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const ref = doc(firestore, 'destinationsTmp', destination.handle as string);

    batch.set(ref, { name: destination.name, handle: destination.handle, organs: destination.organs, types: destination.types });
  }

  return batch.commit();
}

export async function importSessionLogs(session: Session, timeline: TimelineEntry[], logs: ReadingCompressed[]) {
  const firestore = getFirebaseStore();
  const auth = getFirebaseAuth();

  await setDoc(doc(firestore, 'sessions', session.id, 'log', 'log'), {
    log: logs,
  });

  // update timeline start and stop events
  const timelineStart = timeline.find(entry => entry.type === TimelineEntryType.START_SESSION);
  const timelineStop = timeline.find(entry => entry.type === TimelineEntryType.STOP_SESSION);

  const logStart = logs.find(log => log.e === 'started');
  const logStop = logs.find(log => log.e === 'stopped');

  if (!timelineStart && logStart) {
    await addDoc(collection(firestore, 'sessions', session.id, 'timeline'), {
      timestamp: logStart.t,
      createdAt: Timestamp.now(),
      type: TimelineEntryType.START_SESSION,
      senderId: auth.currentUser?.uid,
    });
  }

  if (!timelineStop && logStop) {
    await addDoc(collection(firestore, 'sessions', session.id, 'timeline'), {
      timestamp: logStop.t,
      createdAt: Timestamp.now(),
      type: TimelineEntryType.STOP_SESSION,
      senderId: auth.currentUser?.uid,
    });
  }

  await addDoc(collection(firestore, 'sessions', session.id, 'auditLogs'), {
    userId: auth.currentUser?.uid,
    event: AuditLogType.MANUAL_IMPORT_SESSION_LOGS,
    timestampUser: new Date(),
    timestampServer: serverTimestamp(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    appVersion: process.env.REACT_APP_VERSION,
    osVersion: navigator.userAgent,
    os: 'WMT',
    device: navigator.userAgent,
  });

  await calcReportDataForSessionOnCall(session.id);
}
