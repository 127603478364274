import { Dialog, Transition } from '@headlessui/react';
import { classNames } from '@packages/utils';
import { FC, Fragment } from 'react';
import { BaseSizes } from '../../types';

export type ModalSizes = BaseSizes | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl';

export type ModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  size?: ModalSizes;
  disableOutsideClick?: boolean;
};

const sizeClassBySize = {
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  '4xl': 'max-w-4xl',
  '5xl': 'max-w-5xl',
  '6xl': 'max-w-6xl',
  '7xl': 'max-w-7xl',
};

const Modal: FC<ModalProps> = ({ isOpen, setIsOpen, size = 'md', disableOutsideClick = false, children }) => {
  function closeModal() {
    if (disableOutsideClick) {
      return;
    }

    setIsOpen(false);
  }

  const sizeClasses = sizeClassBySize[size];

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={() => closeModal()}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={classNames(disableOutsideClick ? 'pointer-events-none' : '', 'fixed inset-0 bg-black/30')} />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`${sizeClasses} inline-block w-full transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all`}
            >
              <div>{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
