import { Menu, Transition } from '@headlessui/react';
import {
  Organization,
  OrganizationMember,
  OrganizationMemberAccessRole,
  OrganizationMemberAccessRoleUtil,
  OrganizationMemberStatus,
  OrganizationPreselectedMember,
  OrganizationStatus,
  OrganizationUnregisteredInvite,
} from '@packages/firebase';
import { classNames } from '@packages/utils';
import { Fragment, VFC, useMemo } from 'react';
import { HiEllipsisVertical, HiTrash } from 'react-icons/hi2';
import Table from '../../Shared/Table';
import CellFormatters from '../../Shared/Table/CellFormatters';

export type OrganizationUsersListSectionProps = {
  organization: Organization;
  users: OrganizationPreselectedMember[] | OrganizationMember[];
  unregisteredInvites: OrganizationUnregisteredInvite[];
  onGrantAccess: (id: string) => void;
  onDeactivateUser: (id: string) => void;
  onReactivateUser: (id: string) => void;
  onChangeAccessRole: (id: string, accessRole: OrganizationMemberAccessRole) => void;
  onSendInvite: (id: string) => void;
  onRemove: (id: string) => void;
  onDeleteUnregisteredInvite: (id: string) => void;
};

type UserActionsProps = {
  accessRole?: OrganizationMemberAccessRole;
  onGrantAccess?: () => void;
  onDeactivateUser?: () => void;
  onReactivateUser?: () => void;
  onChangeAccessRole?: (accessRole: OrganizationMemberAccessRole) => void;
  onSendInvite?: () => void;
  onRemove?: () => void;
  onDeleteUnregisteredInvite?: () => void;
};

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: CellFormatters.bold,
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: CellFormatters.organizationMemberStatus,
  },
  {
    Header: 'Access Role',
    accessor: 'accessRole',
  },
  {
    accessor: 'actions',
  },
];

const OrganizationUsersListSection: VFC<OrganizationUsersListSectionProps> = ({
  organization,
  users,
  unregisteredInvites,
  onGrantAccess,
  onDeactivateUser,
  onReactivateUser,
  onChangeAccessRole,
  onSendInvite,
  onRemove,
  onDeleteUnregisteredInvite,
}) => {
  const data = useMemo(() => {
    const processedUsers = users.map(user => {
      const isViewer = user.accessRole === OrganizationMemberAccessRole.VIEWER;
      const isAdmin = user.accessRole === OrganizationMemberAccessRole.ADMIN;
      const isLastAdmin = isAdmin && users.filter(item => item.accessRole === OrganizationMemberAccessRole.ADMIN).length === 1;
      const isLastActiveAdmin =
        isAdmin &&
        users.filter(item => item.accessRole === OrganizationMemberAccessRole.ADMIN && item.status === OrganizationMemberStatus.ACTIVE)
          .length === 1;

      let actions = {
        grantAccess: user.status === OrganizationMemberStatus.ACCESS_REQUESTED,
        deactivate: user.status === OrganizationMemberStatus.ACTIVE && !isLastActiveAdmin,
        reactivate: user.status === OrganizationMemberStatus.INACTIVE,
        changeAccessRole: user.status !== OrganizationMemberStatus.INACTIVE && !isLastAdmin,
        sendInvite: user.status === OrganizationMemberStatus.INVITED,
        remove: false,
      };

      if (organization.status === OrganizationStatus.CREATE_REQUESTED || organization.status === OrganizationStatus.CREATED) {
        actions = {
          grantAccess: false,
          deactivate: false,
          reactivate: false,
          changeAccessRole: !isLastAdmin,
          sendInvite: user.status === OrganizationMemberStatus.INVITED,
          remove: user.status === OrganizationMemberStatus.PRESELECTED,
        };
      }

      if (organization.status === OrganizationStatus.IN_VALIDATION) {
        actions = {
          grantAccess: false,
          deactivate: false,
          reactivate: false,
          changeAccessRole: false,
          sendInvite: user.status === OrganizationMemberStatus.INVITED,
          remove: isViewer,
        };
      }

      if (organization.status === OrganizationStatus.INACTIVE) {
        actions = {
          grantAccess: false,
          deactivate: false,
          reactivate: false,
          changeAccessRole: false,
          sendInvite: false,
          remove: false,
        };
      }

      const showActions = Object.values(actions).some(item => item === true);

      return {
        id: user.id,
        name: user.fullname,
        email: user.email,
        status: user.status,
        accessRole: OrganizationMemberAccessRoleUtil.toUIString(user.accessRole),
        actions: showActions ? (
          <UserActions
            accessRole={user.accessRole}
            onGrantAccess={actions.grantAccess ? () => onGrantAccess(user.id) : undefined}
            onDeactivateUser={actions.deactivate ? () => onDeactivateUser(user.id) : undefined}
            onReactivateUser={actions.reactivate ? () => onReactivateUser(user.id) : undefined}
            onChangeAccessRole={actions.changeAccessRole ? value => onChangeAccessRole(user.id, value) : undefined}
            onSendInvite={actions.sendInvite ? () => onSendInvite(user.id) : undefined}
            onRemove={actions.remove ? () => onRemove(user.id) : undefined}
          />
        ) : undefined,
      };
    });

    const processedUnregisteredInvites = unregisteredInvites.map(invite => {
      return {
        id: invite.id,
        name: <i>Wait for user sign-up</i>,
        email: invite.email,
        status: OrganizationMemberStatus.INVITED,
        accessRole: OrganizationMemberAccessRoleUtil.toUIString(OrganizationMemberAccessRole.VIEWER),
        actions: (
          <UserActions
            onSendInvite={() => onSendInvite(invite.id)}
            onDeleteUnregisteredInvite={() => onDeleteUnregisteredInvite(invite.id)}
          />
        ),
      };
    });

    return [...processedUsers, ...processedUnregisteredInvites];
  }, [users, unregisteredInvites, organization]);

  return <Table columns={columns as any} data={data} options={{ hasPagination: false, hasRowSelect: false }} />;
};

const UserActions: VFC<UserActionsProps> = ({
  accessRole,
  onGrantAccess,
  onDeactivateUser,
  onReactivateUser,
  onChangeAccessRole,
  onSendInvite,
  onRemove,
  onDeleteUnregisteredInvite,
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <HiEllipsisVertical className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {onGrantAccess != null && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-left text-sm',
                    )}
                    onClick={() => onGrantAccess()}
                  >
                    Grant access
                  </button>
                )}
              </Menu.Item>
            </div>
          )}

          {onSendInvite != null && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-left text-sm',
                    )}
                    onClick={() => onSendInvite()}
                  >
                    Re-send invite
                  </button>
                )}
              </Menu.Item>
            </div>
          )}

          {accessRole != null && onChangeAccessRole != null && (
            <div className="py-1">
              {accessRole !== OrganizationMemberAccessRole.ADMIN && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full px-4 py-2 text-left text-sm',
                      )}
                      onClick={() => onChangeAccessRole(OrganizationMemberAccessRole.ADMIN)}
                    >
                      Promote to Admin
                    </button>
                  )}
                </Menu.Item>
              )}

              {accessRole !== OrganizationMemberAccessRole.VIEWER && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full px-4 py-2 text-left text-sm',
                      )}
                      onClick={() => onChangeAccessRole(OrganizationMemberAccessRole.VIEWER)}
                    >
                      Demote to Viewer
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
          )}

          {(onDeactivateUser != null || onReactivateUser != null) && (
            <div className="py-1">
              {onReactivateUser != null && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full px-4 py-2 text-left text-sm',
                      )}
                      onClick={() => onReactivateUser()}
                    >
                      Reactivate
                    </button>
                  )}
                </Menu.Item>
              )}

              {onDeactivateUser != null && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full px-4 py-2 text-left text-sm',
                      )}
                      onClick={() => onDeactivateUser()}
                    >
                      Deactivate
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
          )}

          {onRemove != null && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-left text-sm',
                    )}
                    onClick={() => onRemove()}
                  >
                    <div className="flex items-center space-x-1.5">
                      <HiTrash className="h-4 w-4 text-red-500" />
                      <span>Remove from Organization</span>
                    </div>
                  </button>
                )}
              </Menu.Item>
            </div>
          )}

          {onDeleteUnregisteredInvite != null && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-left text-sm',
                    )}
                    onClick={() => onDeleteUnregisteredInvite()}
                  >
                    <div className="flex items-center space-x-1.5">
                      <HiTrash className="h-4 w-4 text-red-500" />
                      <span>Delete Invite</span>
                    </div>
                  </button>
                )}
              </Menu.Item>
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default OrganizationUsersListSection;
