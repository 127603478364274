import { Routes } from '@packages/constants';
import { FC, ReactNode, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { HiBuildingOffice2, HiChartPie } from 'react-icons/hi2';
import { useLocation } from 'react-router-dom';
import { Meta } from '../../../types';
import DesktopNavigation from './DesktopNavigation';
import Header from './Header';
import MobileNavigation from './MobileNavigation';

export type AppLayoutProps = {
  title?: ReactNode;
  description?: ReactNode;
  meta: Meta;
  showHeader?: boolean;
};

const AppLayout: FC<AppLayoutProps> = ({ title, description, meta, showHeader = true, children }) => {
  const { pathname } = useLocation();
  const [isSidebarOpen, setSidebarIsOpen] = useState(false);

  const navigationItems = useMemo(
    () => [
      {
        name: 'Analytics',
        href: Routes.wmt.ANALYTICS,
        icon: HiChartPie,
        current: pathname.includes(Routes.wmt.ANALYTICS),
      },
      {
        name: 'Organizations',
        href: Routes.wmt.ORGANIZATIONS,
        icon: HiBuildingOffice2,
        current: pathname.includes(Routes.wmt.ORGANIZATIONS),
      },
    ],
    [pathname],
  );

  return (
    <>
      <Helmet>
        <title>{meta.title} | Paragonix Webmaster Tool</title>
        <meta name="description" content={meta.description} />
      </Helmet>

      <div>
        <MobileNavigation items={navigationItems} isOpen={isSidebarOpen} setIsOpen={setSidebarIsOpen} />
        <DesktopNavigation items={navigationItems} />

        <div className="flex min-h-screen flex-1 flex-col bg-gray-50 md:pl-64">
          <Header setSidebarIsOpen={setSidebarIsOpen} />

          <main>
            <div className="py-12">
              {showHeader && (
                <div className="mx-auto space-y-2 px-4 sm:px-6 md:px-8">
                  <h1 className="text-3xl font-semibold text-gray-900">{title ?? meta.title}</h1>
                  {(description != null || meta.description != null) && (
                    <h2 className="text-xl font-light text-gray-500">{description ?? meta.description}</h2>
                  )}
                </div>
              )}
              <div className="mx-auto px-4 sm:px-6 md:px-8">{children}</div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
