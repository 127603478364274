import boldFormatter from './bold.formatter';
import booleanFormatter from './boolean.formatter';
import countryFormatter from './country.formatter';
import datetimeFormatter from './datetime.formatter';
import destinationFormatter from './destination.formatter';
import donationTypeFormatter from './donationType.formatter';
import millisecondsToPaddedTimeFormatter from './millisecondsToPaddedTime.formatter';
import organTypeFormatter from './organType.formatter';
import organizationFormatter from './organization.formatter';
import organizationMemberStatusFormatter from './organizationMemberStatus.formatter';
import organizationStatusFormatter from './organizationStatus.formatter';
import organizationTypesFormatter from './organizationTypes.formatter';
import productFormatter from './product.formatter';
import sessionTypeFormatter from './sessionType.formatter';
import temperatureFormatter from './temperature.formatter';

export default {
  product: productFormatter,
  donationType: donationTypeFormatter,
  sessionType: sessionTypeFormatter,
  datetime: datetimeFormatter,
  boolean: booleanFormatter,
  organization: organizationFormatter,
  temperature: temperatureFormatter,
  millisecondsToPaddedTime: millisecondsToPaddedTimeFormatter,
  country: countryFormatter,
  organType: organTypeFormatter,
  organizationTypes: organizationTypesFormatter,
  organizationStatus: organizationStatusFormatter,
  bold: boldFormatter,
  destination: destinationFormatter,
  organizationMemberStatus: organizationMemberStatusFormatter,
};
