import { FirebaseError } from 'firebase/app';
import {
  Auth,
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import app from './config';

export function getFirebaseAuth() {
  return getAuth(app);
}

export async function login(email: string, password: string, isRememberMeSet = false) {
  const auth = getFirebaseAuth();

  await setPersistence(auth, isRememberMeSet ? browserLocalPersistence : browserSessionPersistence);

  const { user } = await signInWithEmailAndPassword(auth, email, password);

  await verifyClaims(auth);

  return user;
}

export function logout() {
  const auth = getFirebaseAuth();

  signOut(auth);
}

export function getAuthUser() {
  const auth = getFirebaseAuth();

  return auth.currentUser;
}

async function verifyClaims(auth: Auth) {
  const token = await auth.currentUser?.getIdTokenResult();

  if (token?.claims?.permissions == null) {
    throw new FirebaseError('no-permission', 'No permission');
  }

  if (!(token.claims.permissions as string[]).includes('WMT')) {
    throw new FirebaseError('no-permission', 'No permission');
  }
}
