import { ReactNode, VFC } from 'react';
import Badge from '../Badge';
import ChangeBadge, { ChangeType } from './ChangeBadge';

export type KPIProps = {
  name: string;
  nameBadgeText?: string;
  value: string | number | ReactNode;
  comparisonValue?: string;
  icon: any;
  changeValue?: string;
  changeType?: ChangeType;
  colors: {
    icon: string;
    background: string;
  };
};

const KPI: VFC<KPIProps> = ({ name, nameBadgeText, value, comparisonValue, icon: Icon, changeValue, changeType, colors }) => {
  return (
    <div className="px-4 py-5 overflow-hidden bg-transparent sm:py-6 sm:px-6">
      <dt>
        <div className="absolute p-3 rounded-md" style={{ color: colors.icon, backgroundColor: colors.background }}>
          <Icon className="w-6 h-6 text-white" aria-hidden="true" />
        </div>
        <div className="flex items-center space-x-2">
          <p className="ml-16 text-sm font-medium text-gray-400 truncate">{name}</p>
          {nameBadgeText != null && nameBadgeText.length > 0 && (
            <Badge styleType="light" size="xs">
              {nameBadgeText}
            </Badge>
          )}
        </div>
      </dt>
      <dd className="flex items-baseline justify-between ml-16 md:block lg:flex">
        <div className="flex items-baseline text-2xl font-semibold text-black">
          {value}
          {comparisonValue != null && <span className="ml-2 text-sm font-medium text-gray-500">from {comparisonValue}</span>}
        </div>

        {changeValue != null && changeType != null && <ChangeBadge value={changeValue} changeType={changeType} />}
      </dd>
    </div>
  );
};

export default KPI;
