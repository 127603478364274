"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  Colors: () => colors_exports,
  DESTINATION_OWNERS: () => DESTINATION_OWNERS,
  ImageUrls: () => imageUrls_exports,
  Routes: () => Routes
});
module.exports = __toCommonJS(src_exports);

// src/colors.ts
var colors_exports = {};
__export(colors_exports, {
  COLOR_AMBIENT: () => COLOR_AMBIENT,
  COLOR_DONATION_TYPE_PRIMARY: () => COLOR_DONATION_TYPE_PRIMARY,
  COLOR_DONATION_TYPE_PRIMARY_LIGHT: () => COLOR_DONATION_TYPE_PRIMARY_LIGHT,
  COLOR_HEART_PRIMARY: () => COLOR_HEART_PRIMARY,
  COLOR_HEART_PRIMARY_LIGHT: () => COLOR_HEART_PRIMARY_LIGHT,
  COLOR_KIDNEY_PRIMARY: () => COLOR_KIDNEY_PRIMARY,
  COLOR_KIDNEY_PRIMARY_LIGHT: () => COLOR_KIDNEY_PRIMARY_LIGHT,
  COLOR_KTS_PRIMARY: () => COLOR_KTS_PRIMARY,
  COLOR_KTS_PRIMARY_LIGHT: () => COLOR_KTS_PRIMARY_LIGHT,
  COLOR_LIST_ITEM_BG_DEFAULT: () => COLOR_LIST_ITEM_BG_DEFAULT,
  COLOR_LIST_ITEM_ICON_DEFAULT: () => COLOR_LIST_ITEM_ICON_DEFAULT,
  COLOR_LIVERGUARD_PRIMARY: () => COLOR_LIVERGUARD_PRIMARY,
  COLOR_LIVERGUARD_PRIMARY_LIGHT: () => COLOR_LIVERGUARD_PRIMARY_LIGHT,
  COLOR_LIVER_PRIMARY: () => COLOR_LIVER_PRIMARY,
  COLOR_LIVER_PRIMARY_LIGHT: () => COLOR_LIVER_PRIMARY_LIGHT,
  COLOR_LUNGGUARD_PRIMARY: () => COLOR_LUNGGUARD_PRIMARY,
  COLOR_LUNGGUARD_PRIMARY_LIGHT: () => COLOR_LUNGGUARD_PRIMARY_LIGHT,
  COLOR_LUNG_PRIMARY: () => COLOR_LUNG_PRIMARY,
  COLOR_LUNG_PRIMARY_LIGHT: () => COLOR_LUNG_PRIMARY_LIGHT,
  COLOR_PANCREASPAK_PRIMARY: () => COLOR_PANCREASPAK_PRIMARY,
  COLOR_PANCREASPAK_PRIMARY_LIGHT: () => COLOR_PANCREASPAK_PRIMARY_LIGHT,
  COLOR_PANCREAS_PRIMARY: () => COLOR_PANCREAS_PRIMARY,
  COLOR_PANCREAS_PRIMARY_LIGHT: () => COLOR_PANCREAS_PRIMARY_LIGHT,
  COLOR_PROBE: () => COLOR_PROBE,
  COLOR_RL_BG_DEFAULT: () => COLOR_RL_BG_DEFAULT,
  COLOR_RL_ICON_DEFAULT: () => COLOR_RL_ICON_DEFAULT,
  COLOR_SESSION_TYPE_PRIMARY: () => COLOR_SESSION_TYPE_PRIMARY,
  COLOR_SESSION_TYPE_PRIMARY_LIGHT: () => COLOR_SESSION_TYPE_PRIMARY_LIGHT,
  COLOR_SHERPAPAK_PRIMARY: () => COLOR_SHERPAPAK_PRIMARY,
  COLOR_SHERPAPAK_PRIMARY_LIGHT: () => COLOR_SHERPAPAK_PRIMARY_LIGHT,
  COLOR_SWITCH_OFF: () => COLOR_SWITCH_OFF,
  COLOR_SWITCH_ON: () => COLOR_SWITCH_ON,
  COLOR_TIMELINE_ENTRY_BG_DEFAULT: () => COLOR_TIMELINE_ENTRY_BG_DEFAULT,
  COLOR_TIMELINE_ENTRY_ICON_DEFAULT: () => COLOR_TIMELINE_ENTRY_ICON_DEFAULT,
  COLOR_UNKNOWN_PRIMARY: () => COLOR_UNKNOWN_PRIMARY,
  COLOR_UNKNOWN_PRIMARY_LIGHT: () => COLOR_UNKNOWN_PRIMARY_LIGHT,
  COLOR_VANTAGEPOINT_PRIMARY: () => COLOR_VANTAGEPOINT_PRIMARY,
  COLOR_VANTAGEPOINT_PRIMARY_LIGHT: () => COLOR_VANTAGEPOINT_PRIMARY_LIGHT,
  COLOR_VRP_BG_DEFAULT: () => COLOR_VRP_BG_DEFAULT,
  COLOR_VRP_ICON_DEFAULT: () => COLOR_VRP_ICON_DEFAULT
});
var COLOR_UNKNOWN_PRIMARY = "#B91C1C";
var COLOR_UNKNOWN_PRIMARY_LIGHT = "#FEE2E2";
var COLOR_SHERPAPAK_PRIMARY = "#C91E4B";
var COLOR_SHERPAPAK_PRIMARY_LIGHT = "#F5D8DE";
var COLOR_LUNGGUARD_PRIMARY = "#3BADDE";
var COLOR_LUNGGUARD_PRIMARY_LIGHT = "#DBEFF8";
var COLOR_LIVERGUARD_PRIMARY = "#E97D29";
var COLOR_LIVERGUARD_PRIMARY_LIGHT = "#FBE7D8";
var COLOR_PANCREASPAK_PRIMARY = "#007D8A";
var COLOR_PANCREASPAK_PRIMARY_LIGHT = "#BFDADD";
var COLOR_KTS_PRIMARY = "#73BB44";
var COLOR_KTS_PRIMARY_LIGHT = "#EDFFE0";
var COLOR_VANTAGEPOINT_PRIMARY = "#323031";
var COLOR_VANTAGEPOINT_PRIMARY_LIGHT = "#D3D3D3";
var COLOR_HEART_PRIMARY = "#C91E4B";
var COLOR_HEART_PRIMARY_LIGHT = "#F5D8DE";
var COLOR_LUNG_PRIMARY = "#3BADDE";
var COLOR_LUNG_PRIMARY_LIGHT = "#DBEFF8";
var COLOR_LIVER_PRIMARY = "#E97D29";
var COLOR_LIVER_PRIMARY_LIGHT = "#FBE7D8";
var COLOR_PANCREAS_PRIMARY = "#007D8A";
var COLOR_PANCREAS_PRIMARY_LIGHT = "#BFDADD";
var COLOR_KIDNEY_PRIMARY = "#73BB44";
var COLOR_KIDNEY_PRIMARY_LIGHT = "#EDFFE0";
var COLOR_DONATION_TYPE_PRIMARY = "#6366F1";
var COLOR_DONATION_TYPE_PRIMARY_LIGHT = "#A5B4FC";
var COLOR_SESSION_TYPE_PRIMARY = "#F59E0B";
var COLOR_SESSION_TYPE_PRIMARY_LIGHT = "#FCD34D";
var COLOR_VRP_BG_DEFAULT = "#000000";
var COLOR_VRP_ICON_DEFAULT = "#FFFFFF";
var COLOR_TIMELINE_ENTRY_BG_DEFAULT = "#000000";
var COLOR_TIMELINE_ENTRY_ICON_DEFAULT = "#FFFFFF";
var COLOR_LIST_ITEM_BG_DEFAULT = "#000000";
var COLOR_LIST_ITEM_ICON_DEFAULT = "#FFFFFF";
var COLOR_RL_BG_DEFAULT = "#000000";
var COLOR_RL_ICON_DEFAULT = "#FFFFFF";
var COLOR_PROBE = "#00A9E0";
var COLOR_AMBIENT = "#5e5e5e";
var COLOR_SWITCH_OFF = "#e0e0e0";
var COLOR_SWITCH_ON = "#000000";

// src/destinationOwners.ts
var DESTINATION_OWNERS = [
  {
    companyName: "Ascension Saint Vincent",
    unos: "INSV",
    salesRep: {
      name: "Christopher Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Children's of Alabama",
    unos: "ALCH-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Legacy of Hope",
    unos: "ALOB-OP1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Alabama Hospital",
    unos: "ALUA-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Birmingham VA Medical Center",
    unos: "ALVA-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Baptist Medical Center",
    unos: "ARBH-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Arkansas Children's Hospital",
    unos: "ARCH-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Arkansas Regional Organ Recovery Agency",
    unos: "AROR-OP1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "UAMS Medical Center",
    unos: "ARUA-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Phoenix Children's Hospital",
    unos: "AZCH-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Banner-University Medical Center Phoenix",
    unos: "AZGS-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Mayo Clinic Hospital Arizona",
    unos: "AZMC-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Donor Network of Arizona",
    unos: "AZOB-OP1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "St. Joseph's Hospital and Medical Center",
    unos: "AZSJ-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Banner University Medical Center-Tucson",
    unos: "AZUA-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Rady Children's Hospital and Health Center",
    unos: "CACH-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Childrens Hospital Los Angeles",
    unos: "CACL-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Cedars-Sinai Medical Center",
    unos: "CACS-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Donor Network West",
    unos: "CADN-OP1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Scripps Green Hospital",
    unos: "CAGH-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Sierra Donor Services",
    unos: "CAGS-OP1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of California Irvine Medical Center",
    unos: "CAIM-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Harbor UCLA Medical Center",
    unos: "CALA-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Loma Linda University Medical Center",
    unos: "CALL-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "UCSF Medical Center at Mission Bay",
    unos: "CAMB-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "OneLegacy",
    unos: "CAOP-OP1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Lucile Salter Packard Children's Hospital at Stanford",
    unos: "CAPC-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "California Pacific Medical Center-Van Ness Campus",
    unos: "CAPM-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Riverside Community Hospital",
    unos: "CARC-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Lifesharing - A Donate Life Organization",
    unos: "CASD-IO1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of California San Diego Medical Center",
    unos: "CASD-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of California San Francisco Medical Center",
    unos: "CASF-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Sutter Medical Center Sacramento",
    unos: "CASG-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Sharp Memorial Hospital",
    unos: "CASH-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Saint Joseph Hospital",
    unos: "CASJ-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of California Davis Medical Center",
    unos: "CASM-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Stanford Health Care",
    unos: "CASU-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "St. Vincent Medical Center",
    unos: "CASV-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of California at Los Angeles Medical Center",
    unos: "CAUC-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Keck Hospital of USC",
    unos: "CAUH-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Children's Hospital Colorado",
    unos: "COCH-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Memorial Hospital",
    unos: "COMH-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Centura Transplant",
    unos: "COPM-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Donor Alliance",
    unos: "CORS-OP1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Presbyterian/St Luke's Medical Center",
    unos: "COSL-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Colorado Hospital/Health Science Center",
    unos: "COUC-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Hartford Hospital",
    unos: "CTHH-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "LifeChoice Donor Services",
    unos: "CTOP-OP1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Yale New Haven Hospital",
    unos: "CTYN-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Children's National Medical Center",
    unos: "DCCH-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Georgetown University Medical Center",
    unos: "DCGU-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "George Washington University Hospital",
    unos: "DCGW-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Washington Regional Transplant Community",
    unos: "DCTC-OP1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Washington Hospital Center",
    unos: "DCWH-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Walter Reed National Military Medical Center at Bethesda",
    unos: "DCWR-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Alfred I duPont Hospital for Children",
    unos: "DEAI-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Christiana Care Health Services",
    unos: "DECC-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Johns Hopkins All Children's Hospital",
    unos: "FLAC-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Broward Health Medical Center",
    unos: "FLBC-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Cleveland Clinic Florida Weston",
    unos: "FLCC-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "OurLegacy",
    unos: "FLFH-IO1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "AdventHealth Orlando",
    unos: "FLFH-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Gulf Coast Medical Center",
    unos: "FLFR-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Halifax Health",
    unos: "FLHM-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Memorial Regional Hospital/Joe DiMaggio Children's Hospital",
    unos: "FLJD-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Jackson Memorial Hospital University of Miami School of Medicine",
    unos: "FLJM-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Largo Medical Center",
    unos: "FLLM-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Life Alliance Organ Recovery Agency",
    unos: "FLMP-OP1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Memorial Regional Hospital",
    unos: "FLMR-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Ascension Sacred Heart Pensacola",
    unos: "FLSH-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "St Josephs Hospital",
    unos: "FLSJ-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Mayo Clinic Hospital Florida",
    unos: "FLSL-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Tampa General Hospital",
    unos: "FLTG-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "LifeQuest Organ Recovery Services",
    unos: "FLUF-IO1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "UF Health Shands Hospital",
    unos: "FLUF-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "LifeLink of Florida",
    unos: "FLWC-OP1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Children's Healthcare of Atlanta at Egleston",
    unos: "GAEH-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Emory University Hospital",
    unos: "GAEM-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "LifeLink of Georgia",
    unos: "GALL-OP1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "AU Medical Center, Inc.",
    unos: "GAMC-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Piedmont Hospital",
    unos: "GAPH-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "University Hospital",
    unos: "GAUH-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Legacy of Life Hawaii",
    unos: "HIOP-OP1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "The Queen's Medical Center",
    unos: "HIQM-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Iowa Methodist Medical Center",
    unos: "IAIM-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Iowa Hospitals and Clinics Transplant Programs",
    unos: "IAIV-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "MercyOne Des Moines Transplant Center",
    unos: "IAMH-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Iowa Donor Network",
    unos: "IAOP-OP1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "The Iowa City VA Health Care System",
    unos: "IAVA-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Advocate Christ Medical Center",
    unos: "ILCH-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Ann & Robert H. Lurie Children's Hospital of Chicago",
    unos: "ILCM-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Evanston Hospital",
    unos: "ILEH-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Gift of Hope Organ & Tissue Donor Network",
    unos: "ILIP-OP1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Loyola University Medical Center",
    unos: "ILLU-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Springfield Memorial Hospital",
    unos: "ILMM-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Northwestern Memorial Hospital",
    unos: "ILNM-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Rush University Medical Center",
    unos: "ILPL-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "OSF Saint Francis Medical Center",
    unos: "ILSF-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Chicago Medical Center",
    unos: "ILUC-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Illinois Medical Center",
    unos: "ILUI-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Edward Hines Jr. Veterans Administration Medical Center",
    unos: "ILVA-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Indiana University Health",
    unos: "INIM-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Lutheran Hospital of Fort Wayne",
    unos: "INLH-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Indiana Donor Network",
    unos: "INOP-OP1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Ascension St. Vincent Hospital",
    unos: "INSV-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Kansas Hospital",
    unos: "KSUK-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Kentucky Organ Donor Affiliates",
    unos: "KYDA-OP1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Jewish Hospital",
    unos: "KYJH-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Norton Children's Hospital",
    unos: "KYKC-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Kentucky Medical Center",
    unos: "KYUK-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Children's Hospital",
    unos: "LACH-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Ochsner Foundation Hospital",
    unos: "LAOF-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Louisiana Organ Procurement Agency",
    unos: "LAOP-OP1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Tulane Medical Center",
    unos: "LATU-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Willis-Knighton Medical Center",
    unos: "LAWK-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Beth Israel Deaconess Medical Center",
    unos: "MABI-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Baystate Medical Center",
    unos: "MABS-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Boston Medical Center",
    unos: "MABU-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Boston Children's Hospital",
    unos: "MACH-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Lahey Clinic Medical Center",
    unos: "MALC-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Massachusetts General Hospital",
    unos: "MAMG-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Tufts Medical Center",
    unos: "MANM-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "New England Organ Bank",
    unos: "MAOB-OP1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Brigham and Women's Hospital",
    unos: "MAPB-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "UMass Memorial Medical Center",
    unos: "MAUM-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Johns Hopkins Hospital",
    unos: "MDJH-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "The Living Legacy Foundation of Maryland",
    unos: "MDPC-OP1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Maryland Medical System",
    unos: "MDUM-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Maine Medical Center",
    unos: "MEMC-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "William Beaumont Hospital",
    unos: "MIBH-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Children's Hospital of Michigan",
    unos: "MICH-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Helen DeVos Children's Hospital",
    unos: "MIDV-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Henry Ford Hospital",
    unos: "MIHF-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Harper University Hospital Detroit Medical Center",
    unos: "MIHH-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Gift of Life Michigan",
    unos: "MIOP-OP1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Spectrum Health",
    unos: "MISH-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Ascension St. John Hospital",
    unos: "MISJ-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Mercy Health Saint Mary\u2019s",
    unos: "MISM-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Michigan Medical Center",
    unos: "MIUM-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Univ of MN Amplatz Children's Hosp",
    unos: "MNAC-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Abbott Northwestern Hospital",
    unos: "MNAN-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Children's Minnesota",
    unos: "MNCM-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Hennepin County Medical Center",
    unos: "MNHC-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Mayo Clinic Hospital Minnesota",
    unos: "MNMC-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "LifeSource Upper Midwest Organ Procurement Organization",
    unos: "MNOP-OP1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Minnesota Medical Center, Fairview",
    unos: "MNUM-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Barnes-Jewish Hospital",
    unos: "MOBH-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Cardinal Glennon Children's Hospital",
    unos: "MOCG-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "St. Louis Children's Hospital at Washington University Medical Center",
    unos: "MOCH-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Children's Mercy Hospital",
    unos: "MOCM-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "St Luke's Hospital of Kansas City",
    unos: "MOLH-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Mid-America Transplant Services",
    unos: "MOMA-OP1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Research Medical Center",
    unos: "MORH-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "SSM Health Saint Louis University Hospital",
    unos: "MOSL-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Missouri Hospital and Clinics",
    unos: "MOUM-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Mississippi Organ Recovery Agency",
    unos: "MSOP-OP1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Mississippi Medical Center",
    unos: "MSUM-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Midwest Transplant Network",
    unos: "MWOB-OP1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Wake Forest Baptist Medical Center",
    unos: "NCBG-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "LifeShare Carolinas",
    unos: "NCCM-IO1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Carolinas Medical Center",
    unos: "NCCM-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Duke University Hospital",
    unos: "NCDU-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Vidant Medical Center",
    unos: "NCEC-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "University of North Carolina Hospitals",
    unos: "NCMH-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Carolina Donor Services",
    unos: "NCNC-OP1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Sanford Bismarck Medical Center",
    unos: "NDMC-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Sanford Medical Center Fargo",
    unos: "NDSL-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Children's Hospital & Medical Center",
    unos: "NECH-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Live On Nebraska",
    unos: "NEOR-OP1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "The Nebraska Medical Center",
    unos: "NEUN-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Mary Hitchcock Memorial Hospital",
    unos: "NHDH-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Newark Beth Israel Medical Center",
    unos: "NJBI-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Hackensack University Medical Center",
    unos: "NJHK-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Virtua Our Lady of Lourdes Hospital",
    unos: "NJLL-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Robert Wood Johnson University Hospital",
    unos: "NJRW-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Saint Barnabas Medical Center",
    unos: "NJSB-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "New Jersey Organ and Tissue Sharing Network OPO",
    unos: "NJTO-OP1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "University Hospital",
    unos: "NJUH-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "University Hospital, University of New Mexico Health Sciences Center",
    unos: "NMAQ-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "New Mexico Donor Services",
    unos: "NMOP-OP1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Presbyterian Hospital",
    unos: "NMPH-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Nevada Donor Network",
    unos: "NVLV-OP1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University Medical Center of Southern Nevada",
    unos: "NVUM-TX1",
    salesRep: {
      name: "Cory Thompson",
      email: "cthompson@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cthompson@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Albany Medical Center Hospital",
    unos: "NYAM-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Center for Donation and Transplant",
    unos: "NYAP-OP1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Long Island Jewish Medical Center-Cohen Children's Medical Center",
    unos: "NYCC-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "NY Presbyterian Hospital/Columbia Univ. Medical Center",
    unos: "NYCP-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "State University of New York, Downstate Medical Center",
    unos: "NYDS-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Erie County Medical Center",
    unos: "NYEC-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Finger Lakes Donor Recovery Network",
    unos: "NYFL-IO1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Strong Memorial Hospital, University of Rochester Medical Center",
    unos: "NYFL-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Montefiore Medical Center",
    unos: "NYMA-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Mount Sinai Medical Center",
    unos: "NYMS-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "North Shore University Hospital/Northwell Health",
    unos: "NYNS-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "New York-Presbyterian Hospital/Weill Cornell Medical Center",
    unos: "NYNY-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "LiveOnNY",
    unos: "NYRT-OP1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "University Hospital of State University of New York at Stony Brook",
    unos: "NYSB-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "New York University Medical Center",
    unos: "NYUC-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "State University of New York Upstate Medical University",
    unos: "NYUM-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "James J. Peters VA Medical Center",
    unos: "NYVA-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Westchester Medical Center",
    unos: "NYWC-TX1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Upstate New York Transplant Services Inc",
    unos: "NYWN-OP1",
    salesRep: {
      name: "Dan Gordon",
      email: "dgordon@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["dgordon@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "The Cleveland Clinic Foundation",
    unos: "OHCC-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Nationwide Children's Hospital",
    unos: "OHCH-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Children's Hospital Medical Center",
    unos: "OHCM-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Toledo Medical Center",
    unos: "OHCO-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Lifebanc",
    unos: "OHLB-OP1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Life Connection of Ohio",
    unos: "OHLC-OP1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Lifeline of Ohio",
    unos: "OHLP-OP1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Ohio State University Medical Center",
    unos: "OHOU-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "LifeCenter Organ Donor Network",
    unos: "OHOV-OP1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "The Christ Hospital",
    unos: "OHTC-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Cincinnati  Medical Center",
    unos: "OHUC-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "University Hospitals of Cleveland",
    unos: "OHUH-TX1",
    salesRep: {
      name: "Briget Morrison",
      email: "bmorrison@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["bmorrison@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Integris Baptist Medical Center",
    unos: "OKBC-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "OU Medical Center",
    unos: "OKMD-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "LifeShare Transplant Donor Services of Oklahoma",
    unos: "OKOP-OP1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Saint Francis Hospital",
    unos: "OKSF-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "St John Medical Center",
    unos: "OKSJ-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Legacy Good Samaritan Hospital and Medical Center",
    unos: "ORGS-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Providence St. Vincent Medical Center",
    unos: "ORSV-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Pacific Northwest Transplant Bank",
    unos: "ORUO-IO1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Oregon Health and Science University",
    unos: "ORUO-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "VA Portland Health Care System",
    unos: "ORVA-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Albert Einstein Medical Center",
    unos: "PAAE-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Allegheny General Hospital",
    unos: "PAAG-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Crozer-Chester Medical Center",
    unos: "PACC-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "UPMC Children's Hospital of Pittsburgh",
    unos: "PACH-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Children's Hospital of Philadelphia",
    unos: "PACP-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Gift of Life Donor Program",
    unos: "PADV-OP1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Geisinger Medical Center",
    unos: "PAGM-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Penn State Milton S Hershey Medical Center",
    unos: "PAHE-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Pinnacle Health System at Harrisburg Hospital",
    unos: "PAHH-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Hahnemann University Hospital",
    unos: "PAHM-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "The Lankenau Hospital",
    unos: "PALH-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Lehigh Valley Hospital",
    unos: "PALV-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "UPMC Hamot",
    unos: "PAPH-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Pittsburgh Medical Center",
    unos: "PAPT-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Reading Hospital",
    unos: "PARH-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "St. Christopher's Hospital for Children",
    unos: "PASC-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Center for Organ Recovery and Education",
    unos: "PATF-OP1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Thomas Jefferson University Hospital",
    unos: "PATJ-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Temple University Hospital",
    unos: "PATU-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Hospital of the University of Pennsylvania",
    unos: "PAUP-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "VA Pittsburgh Healthcare System",
    unos: "PAVA-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Cardiovascular Center of Puerto Rico and the Caribbean",
    unos: "PRCC-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "LifeLink of Puerto Rico",
    unos: "PRLL-OP1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Auxilio Mutuo Hospital",
    unos: "PRSJ-TX1",
    salesRep: {
      name: "Thomas Roth",
      email: "tjroth@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["tjroth@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Rhode Island Hospital",
    unos: "RIRH-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "MUSC Children's Hospital",
    unos: "SCCH-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "MUSC Lancaster",
    unos: "SCLA-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Medical University of South Carolina",
    unos: "SCMU-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "We Are Sharing Hope SC",
    unos: "SCOP-OP1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Prisma Health Greenville Memorial Hospital",
    unos: "SCPG-TX1",
    salesRep: {
      name: "Coby Babbs",
      email: "cbabbs@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["cbabbs@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Avera McKennan Hospital",
    unos: "SDMK-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Sanford Health/USD Medical Center",
    unos: "SDSV-TX1",
    salesRep: {
      name: "Brian Cisel",
      email: "bcisel@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["bcisel@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Baptist Memorial Hospital",
    unos: "TNBM-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Tennessee Donor Services",
    unos: "TNDS-OP1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Erlanger Medical Center",
    unos: "TNEM-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Le Bonheur Children's Medical Center",
    unos: "TNLB-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Methodist University Hospital",
    unos: "TNMH-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Mid-South Transplant Foundation",
    unos: "TNMS-OP1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Centennial Medical Center",
    unos: "TNPV-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Saint Thomas Hospital",
    unos: "TNST-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "University of TN Med. Ctr. at Knoxville",
    unos: "TNUK-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Tennessee Valley Healthcare System",
    unos: "TNVA-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Vanderbilt University Medical Center",
    unos: "TNVU-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Baylor  Scott and White All Saints Medical Center-Fort Worth",
    unos: "TXAS-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "University Hospital, University of Texas Health Science Center",
    unos: "TXBC-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Cook Children's Medical Center",
    unos: "TXCF-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Children's Medical Center of Dallas",
    unos: "TXCM-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Seton Medical Center Austin",
    unos: "TXCT-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Driscoll Children's Hospital",
    unos: "TXDC-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Dell Children's Medical Center",
    unos: "TXDL-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "North Austin Medical Center",
    unos: "TXDM-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Doctor's Hospital at Renaissance",
    unos: "TXDR-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Dell Seton Medical Center at The University of Texas at Austin",
    unos: "TXDS-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Texas Health Harris Methodist Fort Worth Hospital",
    unos: "TXFW-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "LifeGift Organ Donation Center",
    unos: "TXGC-OP1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Medical City Dallas Hospital",
    unos: "TXHD-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Memorial Hermann Hospital, University of Texas at Houston",
    unos: "TXHH-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "CHI St. Luke's Health Baylor College of Medicine Medical Center",
    unos: "TXHI-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Methodist Specialty and Transplant Hospital",
    unos: "TXHS-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Texas Medical Branch at Galveston",
    unos: "TXJS-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Las Palmas Medical Center",
    unos: "TXLP-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "McAllen Medical Center",
    unos: "TXMA-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Methodist Dallas Medical Center",
    unos: "TXMC-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Houston Methodist Hospital",
    unos: "TXMH-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Medical City Fort Worth",
    unos: "TXPL-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Parkland Health and Hospital System",
    unos: "TXPM-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Christus Santa Rosa Hospital Medical Center",
    unos: "TXRM-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Texas Organ Sharing Alliance",
    unos: "TXSA-OP1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Southwest Transplant Alliance",
    unos: "TXSB-OP1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "UT Southwestern Medical Center/William P. Clements Jr. University Hospital",
    unos: "TXSP-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Scott and White Memorial Hospital",
    unos: "TXSW-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Texas Children's Hospital",
    unos: "TXTC-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Baylor University Medical Center",
    unos: "TXTX-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "East Texas Medical Center",
    unos: "TXTY-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "University Children\u2019s Health",
    unos: "TXUC-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Michael E. Debakey VA Medical Center",
    unos: "TXVA",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Michael E. DeBakey VA Medical Center",
    unos: "TXVA-TX1",
    salesRep: {
      name: "Amy Ashby",
      email: "aashby@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Joe Rago",
      email: "joe@paragonixtechnologies.com"
    },
    emails: ["aashby@paragonixtechnologies.com", "joe@paragonixtechnologies.com", "cchase@paragonixtechnologies.com"]
  },
  {
    companyName: "Intermountain Medical Center",
    unos: "UTLD-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Utah Medical Center",
    unos: "UTMC-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "DonorConnect",
    unos: "UTOP-OP1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Primary Children's Hospital",
    unos: "UTPC-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Children's Hospital of the King's Daughters",
    unos: "VACH-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Inova Fairfax Hospital",
    unos: "VAFH-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Henrico Doctors' Hospital",
    unos: "VAHD-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Medical College of Virginia Hospitals",
    unos: "VAMC-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Hunter Holmes McGuire Veterans Administration Medical Center",
    unos: "VAMV-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Sentara Norfolk General Hospital",
    unos: "VANG-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "LifeNet Health",
    unos: "VATB-OP1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Virginia Health Sciences Center",
    unos: "VAUV-TX1",
    salesRep: {
      name: "Greg Moore",
      email: "gmoore@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["gmoore@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "The University of Vermont Medical Center",
    unos: "VTMC-TX1",
    salesRep: {
      name: "Ted Hancock",
      email: "ted@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["ted@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "Seattle Children's Hospital",
    unos: "WACH-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "LifeCenter Northwest",
    unos: "WALC-OP1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Providence Sacred Heart Medical Center & Children's Hospital",
    unos: "WASH-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Swedish Medical Center",
    unos: "WASM-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Washington Medical Center",
    unos: "WAUW-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Virginia Mason Medical Center",
    unos: "WAVM-TX1",
    salesRep: {
      name: "Shauna Hagemann",
      email: "shaunah@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["shaunah@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Children's Hospital of Wisconsin",
    unos: "WICH-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Versiti Wisconsin, Inc",
    unos: "WIDN-OP1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Froedtert Memorial Lutheran Hospital",
    unos: "WISE-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Aurora St. Luke's Medical Center",
    unos: "WISL-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "UW Health Organ and Tissue Donation",
    unos: "WIUW-IO1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "University of Wisconsin Hospital and Clinics",
    unos: "WIUW-TX1",
    salesRep: {
      name: "Chris Seuffert",
      email: "cseuffert@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Greg Quinn",
      email: "gquinn@paragonixtechnologies.com"
    },
    emails: ["cseuffert@paragonixtechnologies.com", "gquinn@paragonixtechnologies.com"]
  },
  {
    companyName: "Charleston Area Medical Center",
    unos: "WVCA-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  },
  {
    companyName: "West Virginia University Hospitals Inc.",
    unos: "WVWU-TX1",
    salesRep: {
      name: "John Tomaso",
      email: "jtomaso@paragonixtechnologies.com"
    },
    areaDirector: {
      name: "Meredith Thompson",
      email: "meredith@paragonixtechnologies.com"
    },
    emails: ["jtomaso@paragonixtechnologies.com", "meredith@paragonixtechnologies.com"]
  }
];

// src/imageUrls.ts
var imageUrls_exports = {};
__export(imageUrls_exports, {
  BAROGUARD_PUBLIC_DISCLAIMER_IMAGES: () => BAROGUARD_PUBLIC_DISCLAIMER_IMAGES,
  BAROGUARD_PUBLIC_IMAGE_URL: () => BAROGUARD_PUBLIC_IMAGE_URL,
  BAR_CHART_DISCLAIMER_NOTICE: () => BAR_CHART_DISCLAIMER_NOTICE,
  BAR_CHART_NO_DATA_IMAGE_URL: () => BAR_CHART_NO_DATA_IMAGE_URL,
  BAR_CHART_NO_GPS_DATA_IMAGE_URL: () => BAR_CHART_NO_GPS_DATA_IMAGE_URL,
  BAR_CHART_NO_TIMELINE_ENTRIES_IMAGE_URL: () => BAR_CHART_NO_TIMELINE_ENTRIES_IMAGE_URL,
  KTS_PUBLIC_DISCLAIMER_IMAGES: () => KTS_PUBLIC_DISCLAIMER_IMAGES,
  KTS_PUBLIC_IMAGE_URL: () => KTS_PUBLIC_IMAGE_URL,
  KTS_PUBLIC_SESSION_DETAILS_ICONS: () => KTS_PUBLIC_SESSION_DETAILS_ICONS,
  LIVERGUARD_PUBLIC_DISCLAIMER_IMAGES: () => LIVERGUARD_PUBLIC_DISCLAIMER_IMAGES,
  LIVERGUARD_PUBLIC_IMAGE_URL: () => LIVERGUARD_PUBLIC_IMAGE_URL,
  LIVERGUARD_PUBLIC_SESSION_DETAILS_ICONS: () => LIVERGUARD_PUBLIC_SESSION_DETAILS_ICONS,
  LUNGGUARD_PUBLIC_DISCLAIMER_IMAGES: () => LUNGGUARD_PUBLIC_DISCLAIMER_IMAGES,
  LUNGGUARD_PUBLIC_IMAGE_URL: () => LUNGGUARD_PUBLIC_IMAGE_URL,
  LUNGGUARD_PUBLIC_SESSION_DETAILS_ICONS: () => LUNGGUARD_PUBLIC_SESSION_DETAILS_ICONS,
  PANCREASPAK_PUBLIC_DISCLAIMER_IMAGES: () => PANCREASPAK_PUBLIC_DISCLAIMER_IMAGES,
  PANCREASPAK_PUBLIC_IMAGE_URL: () => PANCREASPAK_PUBLIC_IMAGE_URL,
  PANCREASPAK_PUBLIC_SESSION_DETAILS_ICONS: () => PANCREASPAK_PUBLIC_SESSION_DETAILS_ICONS,
  SHERPAPAK_PUBLIC_DISCLAIMER_IMAGES: () => SHERPAPAK_PUBLIC_DISCLAIMER_IMAGES,
  SHERPAPAK_PUBLIC_IMAGE_URL: () => SHERPAPAK_PUBLIC_IMAGE_URL,
  SHERPAPAK_PUBLIC_SESSION_DETAILS_ICONS: () => SHERPAPAK_PUBLIC_SESSION_DETAILS_ICONS
});
var LUNGGUARD_PUBLIC_IMAGE_URL = "https://paragonix-development.firebaseapp.com/images/lungguard.png";
var BAROGUARD_PUBLIC_IMAGE_URL = "https://paragonix-development.firebaseapp.com/images/baroguard.png";
var SHERPAPAK_PUBLIC_IMAGE_URL = "https://paragonix-development.firebaseapp.com/images/sherpapak.png";
var LIVERGUARD_PUBLIC_IMAGE_URL = "https://paragonix-development.firebaseapp.com/images/liverguard.png";
var PANCREASPAK_PUBLIC_IMAGE_URL = "https://paragonix-development.firebaseapp.com/images/pancreaspak.png";
var KTS_PUBLIC_IMAGE_URL = "https://paragonix-development.firebaseapp.com/images/kts.png";
var SHERPAPAK_PUBLIC_DISCLAIMER_IMAGES = {
  ischemicTime: "https://paragonix-development.firebaseapp.com/images/disclaimer_sherpapak_IT.png",
  probeAverage: "https://paragonix-development.firebaseapp.com/images/disclaimer_sherpapak_APTD.png",
  connectDevice: "https://paragonix-development.firebaseapp.com/images/disclaimer_sherpapak_connect_device.png"
};
var LUNGGUARD_PUBLIC_DISCLAIMER_IMAGES = {
  ischemicTime: "https://paragonix-development.firebaseapp.com/images/disclaimer_lungguard_IT.png",
  probeAverage: "https://paragonix-development.firebaseapp.com/images/disclaimer_lungguard_APTD.png",
  connectDevice: "https://paragonix-development.firebaseapp.com/images/disclaimer_lungguard_connect_device.png"
};
var BAROGUARD_PUBLIC_DISCLAIMER_IMAGES = {
  ischemicTime: "https://paragonix-development.firebaseapp.com/images/disclaimer_baroguard_IT.png",
  probeAverage: "https://paragonix-development.firebaseapp.com/images/disclaimer_baroguard_APTD.png",
  connectDevice: "https://paragonix-development.firebaseapp.com/images/disclaimer_baroguard_connect_device.png"
};
var LIVERGUARD_PUBLIC_DISCLAIMER_IMAGES = {
  ischemicTime: "https://paragonix-development.firebaseapp.com/images/disclaimer_liverguard_IT.png",
  probeAverage: "https://paragonix-development.firebaseapp.com/images/disclaimer_liverguard_APTD.png",
  connectDevice: "https://paragonix-development.firebaseapp.com/images/disclaimer_liverguard_connect_device.png"
};
var PANCREASPAK_PUBLIC_DISCLAIMER_IMAGES = {
  ischemicTime: "https://paragonix-development.firebaseapp.com/images/disclaimer_pancreaspak_IT.png",
  probeAverage: "https://paragonix-development.firebaseapp.com/images/disclaimer_pancreaspak_APTD.png",
  connectDevice: "https://paragonix-development.firebaseapp.com/images/disclaimer_pancreaspak_connect_device.png"
};
var KTS_PUBLIC_DISCLAIMER_IMAGES = {
  probeAverage: "https://paragonix-development.firebaseapp.com/images/disclaimer_kts_APTD.png",
  connectDevice: "https://paragonix-development.firebaseapp.com/images/disclaimer_kts_connect_device.png"
};
var SHERPAPAK_PUBLIC_SESSION_DETAILS_ICONS = {
  key: "https://paragonix-development.firebaseapp.com/images/sherpapak-session-key.png",
  productType: "https://paragonix-development.firebaseapp.com/images/sherpapak-product-type.png",
  started: "https://paragonix-development.firebaseapp.com/images/sherpapak-session-started.png",
  stopped: "https://paragonix-development.firebaseapp.com/images/sherpapak-session-stopped.png"
};
var LUNGGUARD_PUBLIC_SESSION_DETAILS_ICONS = {
  key: "https://paragonix-development.firebaseapp.com/images/lungguard-session-key.png",
  productType: "https://paragonix-development.firebaseapp.com/images/lungguard-product-type.png",
  started: "https://paragonix-development.firebaseapp.com/images/lungguard-session-started.png",
  stopped: "https://paragonix-development.firebaseapp.com/images/lungguard-session-stopped.png"
};
var LIVERGUARD_PUBLIC_SESSION_DETAILS_ICONS = {
  key: "https://paragonix-development.firebaseapp.com/images/liverguard-session-key.png",
  productType: "https://paragonix-development.firebaseapp.com/images/liverguard-product-type.png",
  started: "https://paragonix-development.firebaseapp.com/images/liverguard-session-started.png",
  stopped: "https://paragonix-development.firebaseapp.com/images/liverguard-session-stopped.png"
};
var PANCREASPAK_PUBLIC_SESSION_DETAILS_ICONS = {
  key: "https://paragonix-development.firebaseapp.com/images/pancreaspak-session-key.png",
  productType: "https://paragonix-development.firebaseapp.com/images/pancreaspak-product-type.png",
  started: "https://paragonix-development.firebaseapp.com/images/pancreaspak-session-started.png",
  stopped: "https://paragonix-development.firebaseapp.com/images/pancreaspak-session-stopped.png"
};
var KTS_PUBLIC_SESSION_DETAILS_ICONS = {
  key: "https://paragonix-development.firebaseapp.com/images/kts-session-key.png",
  productType: "https://paragonix-development.firebaseapp.com/images/kts-product-type.png",
  started: "https://paragonix-development.firebaseapp.com/images/kts-session-started.png",
  stopped: "https://paragonix-development.firebaseapp.com/images/kts-session-stopped.png"
};
var BAR_CHART_DISCLAIMER_NOTICE = "https://paragonix-development.firebaseapp.com/images/bar-chart-disclaimer-notice.png";
var BAR_CHART_NO_DATA_IMAGE_URL = "https://paragonix-development.firebaseapp.com/images/bar-chart-no-data.png";
var BAR_CHART_NO_GPS_DATA_IMAGE_URL = "https://paragonix-development.firebaseapp.com/images/bar-chart-no-gps-data.png";
var BAR_CHART_NO_TIMELINE_ENTRIES_IMAGE_URL = "https://paragonix-development.firebaseapp.com/images/bar-chart-no-timeline-entries.png";

// src/routes.ts
var web = {
  ROOT: "/",
  DASHBOARD: "/dashboard",
  SESSIONS: "/sessions",
  ORGANIZATIONS: "/organizations",
  SESSION_DETAIL: "/sessions/:id",
  ORGANIZATION_DETAIL: "/organizations/:id",
  JOIN: "/join/:joinKey?",
  JOIN_NO_KEY: "/join",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  VERIFY_EMAIL_NOTICE: "/verify-email-notice",
  VERIFY_EMAIL: "/verify-email",
  AUTH_ACTION_REDIRECT: "/auth-action-redirect",
  LOGIN: "/login",
  REGISTER: "/register",
  SETUP_ORGANIZATION: "/setup-organization",
  JOIN_ORGANIZATION: "/join-organization",
  ACCEPT_TERMS: "/accept-terms",
  SUPPORT: "/support",
  DELETE_ACCOUNT: "/delete-account",
  DOWNLOAD_SESSION_REPORT: "/download-session-report/:id",
  TERMS_OF_USE: "/i/terms-of-use",
  PRIVACY_POLICY: "/i/privacy-policy",
  EMAIL_REPORT_TEST: "/admin/email-report-test"
};
var wmt = {
  ROOT: "/",
  LIVE: "/live",
  ANALYTICS: "/analytics",
  ORGANIZATIONS: "/organizations",
  ORGANIZATION_DETAIL: "/organizations/:id",
  TOOLS: "/tools",
  TOOLS_MANAGE_DESTINATIONS: "/tools/manage-destinations",
  LOGIN: "/login",
  SETTINGS: "/settings",
  COUNTRIES: "/countries"
};
var Routes = {
  web,
  wmt
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Colors,
  DESTINATION_OWNERS,
  ImageUrls,
  Routes
});
