import { Routes } from '@packages/constants';
import { OrganizationStatus, OrganizationStatusUtil, OrganTypeUtil } from '@packages/firebase';
import { formatCountry } from '@packages/ui';
import { formatDate } from '@packages/utils';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { HiPencilSquare, HiPlay, HiUserPlus } from 'react-icons/hi2';
import { useParams } from 'react-router-dom';
import withUser from '../components/HOC/withUser';
import AppLayout from '../components/Layouts/AppLayout';
import CreateOrganizationModal from '../components/Pages/Organizations/CreateOrganizationModal';
import OrganizationUsersListSection from '../components/Pages/Organizations/OrganizationUsersListSection';
import { useOrganizationManager } from '../components/Pages/Organizations/useOrganizationManager';
import UsersWizard from '../components/Pages/Organizations/UsersWizard';
import Badge from '../components/Shared/Badge';
import Button from '../components/Shared/Button';
import ConfirmationModal from '../components/Shared/ConfirmationModal';
import DataState from '../components/Shared/DataState';
import Loader from '../components/Shared/Loader';
import { deleteOrganizationUnregisteredInvite, useFetchOrganizationTypesConfig } from '../lib/firebase/organizations';
import { Meta } from '../types';

const OrganizationDetail = () => {
  const { id } = useParams();
  const manager = useOrganizationManager(id as string);
  const config = useFetchOrganizationTypesConfig();

  const [isEditOrganizationModalOpen, setIsEditOrganizationModalOpen] = useState(false);
  const [isUserWizardOpen, setIsUserWizardOpen] = useState(false);

  const [isConfirmingValidationStart, setIsConfirmingValidationStart] = useState(false);
  const [isStartValidationLoading, setIsStartValidationLoading] = useState(false);

  const [grantAccessUserId, setGrantAccessUserId] = useState<string>();
  const [isGrantAccessLoading, setIsGrantAccessLoading] = useState(false);

  const [deactivateUserId, setDeactivateUserId] = useState<string>();
  const [isDeactivateUserLoading, setIsDeactivateUserLoading] = useState(false);

  const [reactivateUserId, setReactivateUserId] = useState<string>();
  const [isReactivateUserLoading, setIsReactivateUserLoading] = useState(false);

  const [sendInviteUserId, setSendInviteUserId] = useState<string>();
  const [isSendInviteLoading, setIsSendInviteLoading] = useState(false);

  const [deleteUnregisteredInviteId, setDeleteUnregisteredInviteId] = useState<string>();
  const [isDeleteUnregisteredInviteLoading, setIsDeleteUnregisteredInviteLoading] = useState(false);

  async function handleOnAddUsers(userIds: string[]) {
    if (userIds.length === 0) {
      return;
    }

    for (const userId of userIds) {
      // eslint-disable-next-line no-await-in-loop
      await manager.addUser(userId);
    }
  }

  async function handleOnRemoveUser(userId: string) {
    await manager.removeUser(userId);
  }

  async function handleOnStartValidation() {
    setIsStartValidationLoading(true);

    try {
      await manager.startValidation();
    } finally {
      setIsStartValidationLoading(false);
      setIsConfirmingValidationStart(false);
    }
  }

  async function handleOnGrantAccess() {
    if (grantAccessUserId == null) {
      return;
    }

    setIsGrantAccessLoading(true);

    try {
      await manager.grantAccess(grantAccessUserId);
    } finally {
      setIsGrantAccessLoading(false);
      setGrantAccessUserId(undefined);
    }
  }

  async function handleOnDeactivateUser() {
    if (deactivateUserId == null) {
      return;
    }

    setIsDeactivateUserLoading(true);

    try {
      await manager.deactivateUser(deactivateUserId);
    } finally {
      setIsDeactivateUserLoading(false);
      setDeactivateUserId(undefined);
    }
  }

  async function handleOnReactivateUser() {
    if (reactivateUserId == null) {
      return;
    }

    setIsReactivateUserLoading(true);

    try {
      await manager.reactivateUser(reactivateUserId);
    } finally {
      setIsReactivateUserLoading(false);
      setReactivateUserId(undefined);
    }
  }

  async function handleOnSendInvite() {
    if (sendInviteUserId == null) {
      return;
    }

    setIsSendInviteLoading(true);

    try {
      await manager.sendInvite(sendInviteUserId);
    } finally {
      setIsSendInviteLoading(false);
      setSendInviteUserId(undefined);
    }
  }

  async function handleOnDeleteUnregisteredInvite() {
    if (deleteUnregisteredInviteId == null) {
      return;
    }

    setIsDeleteUnregisteredInviteLoading(true);

    try {
      await deleteOrganizationUnregisteredInvite(deleteUnregisteredInviteId);
    } finally {
      setIsDeleteUnregisteredInviteLoading(false);
      setDeleteUnregisteredInviteId(undefined);
    }
  }

  const meta: Meta = useMemo(() => {
    if (manager.isLoading || manager.organization == null) {
      return {
        title: 'Organization',
        description: 'Manage all Paragonix organizations and customers.',
      };
    }

    return {
      title: manager.organization.name,
      description: 'Manage all Paragonix organizations and customers.',
    };
  }, [manager.organization, manager.isLoading]);

  if (manager.isLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader size="xl" />
      </div>
    );
  }

  if (manager.organization == null) {
    return null;
  }

  if (config.isLoading) {
    return null;
  }

  return (
    <AppLayout showHeader={false} meta={meta}>
      <main>
        <div className="mb-6 text-sm font-medium text-gray-500">
          <a href={Routes.wmt.ORGANIZATIONS}>
            <span>&larr;</span> <span>Back to Organizations</span>
          </a>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="flex-shrink-0">
              <div className="relative">
                <div>
                  {manager.organization.logoUrl != null ? (
                    <div className="flex h-16 w-16 items-center justify-center rounded-md border bg-gray-100">
                      <img className="inline-block max-h-full w-16 rounded-md" src={manager.organization.logoUrl} alt="" />
                    </div>
                  ) : (
                    <div className="inline-block h-16 w-16 rounded-md border bg-gray-100" />
                  )}
                </div>
              </div>
            </div>

            <div className="space-y-1">
              <h1 className="flex items-center space-x-2 text-2xl font-bold text-gray-900">
                <span>{manager.organization.name}</span>

                {manager.organization.status === OrganizationStatus.CREATED && (
                  <Badge styleType="default" size="xs">
                    {OrganizationStatusUtil.toUIString(OrganizationStatus.CREATED)}
                  </Badge>
                )}

                {manager.organization.status === OrganizationStatus.CREATE_REQUESTED && (
                  <Badge styleType="default" size="xs">
                    {OrganizationStatusUtil.toUIString(OrganizationStatus.CREATE_REQUESTED)}
                  </Badge>
                )}

                {manager.organization.status === OrganizationStatus.IN_VALIDATION && (
                  <Badge styleType="warning" size="xs">
                    {OrganizationStatusUtil.toUIString(OrganizationStatus.IN_VALIDATION)}
                  </Badge>
                )}

                {manager.organization.status === OrganizationStatus.ACTIVE && (
                  <Badge styleType="success" size="xs">
                    {OrganizationStatusUtil.toUIString(OrganizationStatus.ACTIVE)}
                  </Badge>
                )}

                {manager.organization.status === OrganizationStatus.INACTIVE && (
                  <Badge styleType="danger" size="xs">
                    {OrganizationStatusUtil.toUIString(OrganizationStatus.INACTIVE)}
                  </Badge>
                )}
              </h1>

              <p className="text-sm font-medium text-gray-500">
                Created{' '}
                {DateTime.fromJSDate(manager.organization.createdAt).toRelative({
                  padding: 1000,
                })}{' '}
                ({formatDate(manager.organization.createdAt)})
              </p>
            </div>
          </div>

          <div className="flex space-x-2">
            {manager.isOrganizationComplete && (
              <Button styleType="light" size="sm" onClick={() => setIsEditOrganizationModalOpen(true)}>
                <HiPencilSquare className="mr-2 h-4 w-4" />
                <span>Edit</span>
              </Button>
            )}

            {manager.isValidateable && (
              <Button
                styleType="success"
                size="sm"
                disabled={!manager.canStartValidation}
                onClick={() => setIsConfirmingValidationStart(true)}
              >
                <HiPlay className="mr-2 h-4 w-4" />
                <span>Start Validation</span>
              </Button>
            )}
          </div>
        </div>

        {manager.isOrganizationComplete ? (
          <>
            <div className="mt-8">
              <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                <section>
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                      <h2 className="text-lg font-medium leading-6 text-gray-900">Organization information</h2>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">Basic info about the organization.</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Handle</dt>
                          <dd className="mt-1 text-sm text-gray-900">{manager.organization.handle ?? <i>No handle</i>}</dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Organs</dt>
                          <dd className="mt-2 text-sm text-gray-900">
                            <div className="flex">
                              {manager.organization.organTypes != null && manager.organization.organTypes.length > 0 ? (
                                manager.organization.organTypes.map(organType => (
                                  <div key={`organ-type-${organType}`} className="mb-2 mr-2">
                                    <Badge styleType="light" size="sm">
                                      <div className="flex items-center space-x-1">
                                        <img className="h-4 w-4" src={OrganTypeUtil.iconPath(organType)} alt="" />
                                        <span>{OrganTypeUtil.organString(organType)}</span>
                                      </div>
                                    </Badge>
                                  </div>
                                ))
                              ) : (
                                <i>No organs</i>
                              )}
                            </div>
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Types</dt>
                          <dd className="mt-2 text-sm text-gray-900">
                            <div className="flex">
                              {manager.organization.types != null &&
                                manager.organization.types.length > 0 &&
                                manager.organization.types.map(type => (
                                  <div key={`organization-type-${type}`} className="mb-2 mr-2">
                                    <Badge styleType="light" size="sm">
                                      {config.organizationTypes?.find(organizationType => organizationType.id === type)?.locales?.en ??
                                        'Unknown'}
                                    </Badge>
                                  </div>
                                ))}

                              {manager.organization.isCorporate && (
                                <div className="mb-2 mr-2">
                                  <Badge styleType="light" size="sm">
                                    Corporate
                                  </Badge>
                                </div>
                              )}

                              {!manager.organization.isCorporate &&
                                (manager.organization.types == null || manager.organization.types.length === 0) && <i>No types</i>}
                            </div>
                          </dd>
                        </div>

                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Domains{' '}
                            {manager.organization.domains != null &&
                              manager.organization.domains.length > 0 &&
                              (manager.organization.areDomainsWhitelisted ? <span>(Whitelisted)</span> : <span>(Not Whitelisted)</span>)}
                          </dt>
                          <dd className="mt-2 text-sm text-gray-900">
                            {manager.organization.domains != null && manager.organization.domains.length > 0 ? (
                              manager.organization.domains.map(domain => (
                                <div key={`known-domains-${domain}`} className="mb-2 mr-2">
                                  <Badge styleType="light" size="sm">
                                    {domain}
                                  </Badge>
                                </div>
                              ))
                            ) : (
                              <i>No domains</i>
                            )}
                          </dd>
                        </div>

                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Address</dt>
                          <dd className="mt-1 text-sm text-gray-900">{manager.organization.address ?? <i>No address</i>}</dd>
                        </div>

                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">Country</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {manager.organization.country ? formatCountry(manager.organization.country) : <i>No country</i>}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <div className="mt-24">
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="text-xl font-medium">Users</h2>
                </div>

                {manager.canAddNewUsers && (
                  <Button onClick={() => setIsUserWizardOpen(true)} styleType="default" size="sm">
                    <HiUserPlus className="mr-2 h-4 w-4" />
                    <span>Add Users</span>
                  </Button>
                )}
              </div>

              <div className="mt-8">
                <DataState
                  hasData={manager.allUsers.length > 0}
                  isLoading={manager.isLoading}
                  styleType="card"
                  text={
                    <div className="flex flex-col justify-center space-y-4">
                      <span className="block">No users in organization</span>

                      {manager.canAddNewUsers && (
                        <Button onClick={() => setIsUserWizardOpen(true)} styleType="default">
                          <HiUserPlus className="mr-2 h-4 w-4" />
                          <span>Add Users</span>
                        </Button>
                      )}
                    </div>
                  }
                >
                  <OrganizationUsersListSection
                    organization={manager.organization}
                    users={manager.allUsers ?? []}
                    unregisteredInvites={manager.unregisteredInvites ?? []}
                    onGrantAccess={id => setGrantAccessUserId(id)}
                    onDeactivateUser={id => setDeactivateUserId(id)}
                    onReactivateUser={id => setReactivateUserId(id)}
                    onChangeAccessRole={(userId, accessRole) => manager.changeUserAccessRole(userId, accessRole)}
                    onSendInvite={id => setSendInviteUserId(id)}
                    onRemove={userId => handleOnRemoveUser(userId)}
                    onDeleteUnregisteredInvite={id => setDeleteUnregisteredInviteId(id)}
                  />
                </DataState>
              </div>

              {manager.organization != null && (
                <UsersWizard isOpen={isUserWizardOpen} setIsOpen={setIsUserWizardOpen} onAddUsers={userIds => handleOnAddUsers(userIds)} />
              )}
            </div>
          </>
        ) : (
          <div className="mt-8">
            <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
              <HiPencilSquare className="mx-auto h-8 w-8 text-gray-400" />

              <h3 className="mt-2 text-sm font-semibold text-gray-900">Create Requested</h3>

              <div className="mx-auto max-w-lg text-sm text-gray-500">
                {manager.allUsers.length > 0 ? (
                  <p className="mt-1">The creation of this organization has been requested by:</p>
                ) : (
                  <p className="mt-1">The creation of this organization has been requested.</p>
                )}

                {manager.allUsers.length > 0 && (
                  <ul className="mt-1.5 max-w-lg space-y-0.5 text-xs text-black">
                    {manager.allUsers.map(user => (
                      <li>
                        <strong>{user.fullname}</strong> ({user.email}))
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="mt-6">
                <Button styleType="light" size="md" onClick={() => setIsEditOrganizationModalOpen(true)}>
                  <span>Complete Setup</span>
                </Button>
              </div>
            </div>
          </div>
        )}
      </main>

      <CreateOrganizationModal
        organization={manager.organization}
        isOpen={isEditOrganizationModalOpen}
        setIsOpen={setIsEditOrganizationModalOpen}
        isEditMode
      />

      <ConfirmationModal
        title="Start Validation"
        text="Are you sure you want to start the validation? This means that the admin of the organization will be notified via email to validate the organization. You can still make changes to the organization after starting the validation, but you will not be able to stop the validation process, or add/remove admins."
        isOpen={isConfirmingValidationStart}
        setIsOpen={setIsConfirmingValidationStart}
        isLoading={isStartValidationLoading}
        onCancel={() => setIsConfirmingValidationStart(false)}
        onConfirm={() => handleOnStartValidation()}
      />

      <ConfirmationModal
        title="Grant Access"
        text="Are you sure you want to grant access to this user? This means that the user will be able to start using the organization."
        isOpen={grantAccessUserId != null}
        setIsOpen={() => setGrantAccessUserId(undefined)}
        isLoading={isGrantAccessLoading}
        onCancel={() => setGrantAccessUserId(undefined)}
        onConfirm={() => handleOnGrantAccess()}
      />

      <ConfirmationModal
        title="Deactivate User"
        text="Are you sure you want to deactivate this user? This means that the user will not be able to access the organization."
        isOpen={deactivateUserId != null}
        setIsOpen={() => setDeactivateUserId(undefined)}
        isLoading={isDeactivateUserLoading}
        onCancel={() => setDeactivateUserId(undefined)}
        onConfirm={() => handleOnDeactivateUser()}
      />

      <ConfirmationModal
        title="Reactivate User"
        text="Are you sure you want to reactivate this user? This means that the user will be able to access the organization."
        isOpen={reactivateUserId != null}
        setIsOpen={() => setReactivateUserId(undefined)}
        isLoading={isReactivateUserLoading}
        onCancel={() => setReactivateUserId(undefined)}
        onConfirm={() => handleOnReactivateUser()}
      />

      <ConfirmationModal
        title="Send Invite"
        text="Are you sure you want to send an invite to this user? This means that the user will receive an email with a link to join the organization."
        isOpen={sendInviteUserId != null}
        setIsOpen={() => setSendInviteUserId(undefined)}
        isLoading={isSendInviteLoading}
        onCancel={() => setSendInviteUserId(undefined)}
        onConfirm={() => handleOnSendInvite()}
      />

      <ConfirmationModal
        title="Delete Invite"
        text="Are you sure you want to delete this invite? This means that the user will not be able to join the organization."
        isOpen={deleteUnregisteredInviteId != null}
        setIsOpen={() => setDeleteUnregisteredInviteId(undefined)}
        isLoading={isDeleteUnregisteredInviteLoading}
        onCancel={() => setDeleteUnregisteredInviteId(undefined)}
        onConfirm={() => handleOnDeleteUnregisteredInvite()}
      />
    </AppLayout>
  );
};

export default withUser(OrganizationDetail);
