import Badge from '../../Badge';

function organizationTypesFormatter({ value }: { value: string[] }) {
  if (value == null || value.length === 0) {
    return '-';
  }

  return (
    <div className="flex">
      {value.map((type, index) => (
        <div key={`${type}-${index}`} className="mb-2 mr-2">
          <Badge styleType="light" size="xs">
            {type}
          </Badge>
        </div>
      ))}
    </div>
  );
}

export default organizationTypesFormatter;
