import { classNames } from '@packages/utils';
import { VFC } from 'react';
import { BaseSizes } from '../../types';

export type LoaderSizes = BaseSizes;

export type LoaderProps = {
  size?: LoaderSizes;
};

const Loader: VFC<LoaderProps> = ({ size }) => {
  let sizeSpecificClassNames;

  switch (size) {
    case 'xs':
      sizeSpecificClassNames = 'h-4';
      break;
    case 'sm':
      sizeSpecificClassNames = 'h-5';
      break;
    case 'md':
      sizeSpecificClassNames = 'h-6';
      break;
    case 'lg':
      sizeSpecificClassNames = 'h-7';
      break;
    case 'xl':
      sizeSpecificClassNames = 'h-8';
      break;
    default:
      sizeSpecificClassNames = 'h-6';
      break;
  }

  const className = classNames(sizeSpecificClassNames);

  return (
    <svg viewBox="0 0 40 40" className={className} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity=".2"
        d="M20.201 5.169c-8.254 0-14.946 6.692-14.946 14.946 0 8.255 6.692 14.946 14.946 14.946s14.946-6.691 14.946-14.946c-.001-8.254-6.692-14.946-14.946-14.946zm0 26.58c-6.425 0-11.634-5.208-11.634-11.634 0-6.425 5.209-11.634 11.634-11.634 6.425 0 11.633 5.209 11.633 11.634 0 6.426-5.208 11.634-11.633 11.634z"
      />
      <path d="m26.013 10.047 1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012v3.312c2.119 0 4.1.576 5.812 1.566z">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 20 20"
          to="360 20 20"
          dur="0.5s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};

export default Loader;
