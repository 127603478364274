import { Routes } from '@packages/constants';
import { onAuthStateChanged, User as AuthUser } from 'firebase/auth';
import { FC, useEffect, useMemo, useState, VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthUserContext, AuthUserContextProps } from '../../contexts/useAuthUser';
import { getFirebaseAuth } from '../../lib/firebase/auth';
import { useFetchUser } from '../../lib/firebase/firestore';

const withUser = (Component: FC) => {
  const WithUser: VFC<Record<string, unknown>> = (props = {}) => {
    const navigate = useNavigate();
    const auth = getFirebaseAuth();

    const { user, isUserLoading } = useFetchUser();

    const [authUser, setAuthUser] = useState<AuthUser | undefined>();

    const value = useMemo(
      () => ({
        authUser,
        user,
        isUserLoading,
      }),
      [authUser, user, isUserLoading],
    );

    useEffect(() => {
      onAuthStateChanged(auth, authStateChangeUser => {
        if (authStateChangeUser == null) {
          navigate(Routes.wmt.LOGIN);
          return;
        }

        setAuthUser(authStateChangeUser);
      });
    }, []);

    return value.authUser != null && value.user != null ? (
      <AuthUserContext.Provider value={value as AuthUserContextProps}>
        <Component {...props} />
      </AuthUserContext.Provider>
    ) : null;
  };

  return WithUser;
};

export default withUser;
