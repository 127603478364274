import { OrganizationMemberStatus, OrganizationMemberStatusUtil } from '@packages/firebase';
import Badge from '../../Badge';

function organizationMemberStatusFormatter({ value }: { value: OrganizationMemberStatus }) {
  if (value == null || value.length === 0) {
    return '-';
  }

  switch (value) {
    case OrganizationMemberStatus.PRESELECTED:
      return (
        <Badge styleType="light" size="sm">
          {OrganizationMemberStatusUtil.toUIString(value)}
        </Badge>
      );
    case OrganizationMemberStatus.ACCESS_REQUESTED:
      return (
        <Badge styleType="light" size="sm">
          {OrganizationMemberStatusUtil.toUIString(value)}
        </Badge>
      );
    case OrganizationMemberStatus.CREATED:
      return (
        <Badge styleType="default" size="sm">
          {OrganizationMemberStatusUtil.toUIString(value)}
        </Badge>
      );
    case OrganizationMemberStatus.INVITED:
      return (
        <Badge styleType="warning" size="sm">
          {OrganizationMemberStatusUtil.toUIString(value)}
        </Badge>
      );
    case OrganizationMemberStatus.ACTIVE:
      return (
        <Badge styleType="success" size="sm">
          {OrganizationMemberStatusUtil.toUIString(value)}
        </Badge>
      );
    case OrganizationMemberStatus.INACTIVE:
      return (
        <Badge styleType="disabled" size="sm">
          {OrganizationMemberStatusUtil.toUIString(value)}
        </Badge>
      );
    default:
      return (
        <Badge styleType="danger" size="sm">
          Unknown
        </Badge>
      );
  }
}

export default organizationMemberStatusFormatter;
