import { Tab } from '@headlessui/react';
import { ProductType, ProductUtil } from '@packages/firebase';
import {
  classNames,
  formatDistance,
  formatPressure,
  formatTemperature,
  millisecondsToPaddedTime,
  millisecondsToTime,
} from '@packages/utils';
import { VFC, useMemo } from 'react';
import { RiMapPinTimeLine, RiPinDistanceFill, RiTempColdLine, RiTimerFlashLine, RiTimerLine } from 'react-icons/ri';
import { WiBarometer } from 'react-icons/wi';
import { useSessions } from '../../../contexts/useSessions';
import Card from '../../Shared/Card';

type TabListItemContentProps = {
  name: string;
  selected: boolean;
  imgSrc?: string;
};

type TabRowProps = {
  name: string;
  icon: any;
  data: Array<{ name: string; value: string | number | undefined }>;
};

type TabRowItemProps = {
  name: string;
  value: string | number | undefined;
};

const PRODUCT_DETAILS_TABS_ORDER = [
  ProductType.SHERPAPAK,
  ProductType.LUNGGUARD,
  ProductType.BAROGUARD,
  ProductType.LIVERGUARD,
  ProductType.PANCREASPAK,
  ProductType.KTS,
  ProductType.VANTAGEPOINT,
];

const AveragesTabsSection = () => {
  const { stats } = useSessions();

  const dataAll = useMemo(
    () => [
      {
        name: 'Temperature',
        icon: RiTempColdLine,
        data: [
          {
            name: 'Minimum',
            value: formatTemperature(stats.averages.all.temperature.min),
          },
          {
            name: 'Average',
            value: formatTemperature(stats.averages.all.temperature.avg),
          },
          {
            name: 'Maximum',
            value: formatTemperature(stats.averages.all.temperature.max),
          },
        ],
      },
      {
        name: 'Distance',
        icon: RiPinDistanceFill,
        data: [
          {
            name: 'Minimum',
            value: formatDistance(stats.averages.all.distance.min),
          },
          {
            name: 'Average',
            value: formatDistance(stats.averages.all.distance.avg),
          },
          {
            name: 'Maximum',
            value: formatDistance(stats.averages.all.distance.max),
          },
        ],
      },
      {
        name: 'Duration',
        icon: RiTimerLine,
        data: [
          {
            name: 'Minimum',
            value: millisecondsToTime(stats.averages.all.duration.min, { maxUnits: 3 }),
          },
          {
            name: 'Average',
            value: millisecondsToTime(stats.averages.all.duration.avg, { maxUnits: 3 }),
          },
          {
            name: 'Maximum',
            value: millisecondsToTime(stats.averages.all.duration.max, { maxUnits: 3 }),
          },
        ],
      },
      {
        name: 'Logging Duration',
        icon: RiTimerFlashLine,
        data: [
          {
            name: 'Minimum',
            value: millisecondsToTime(stats.averages.all.loggingDuration.min, { maxUnits: 3 }),
          },
          {
            name: 'Average',
            value: millisecondsToTime(stats.averages.all.loggingDuration.avg, { maxUnits: 3 }),
          },
          {
            name: 'Maximum',
            value: millisecondsToTime(stats.averages.all.loggingDuration.max, { maxUnits: 3 }),
          },
        ],
      },
      {
        name: 'Ischemic Time',
        icon: RiMapPinTimeLine,
        data: [
          {
            name: 'Minimum',
            value: millisecondsToPaddedTime(stats.averages.all.ischemicTime.min),
          },
          {
            name: 'Average',
            value: millisecondsToPaddedTime(stats.averages.all.ischemicTime.avg),
          },
          {
            name: 'Maximum',
            value: millisecondsToPaddedTime(stats.averages.all.ischemicTime.max),
          },
        ],
      },
    ],
    [stats],
  );

  const data = useMemo(
    () => ({
      [ProductType.SHERPAPAK]: [
        {
          name: 'Temperature',
          icon: RiTempColdLine,
          data: [
            {
              name: 'Minimum',
              value: formatTemperature(stats.averages['sherpapak-cts'].temperature.min),
            },
            {
              name: 'Average',
              value: formatTemperature(stats.averages['sherpapak-cts'].temperature.avg),
            },
            {
              name: 'Maximum',
              value: formatTemperature(stats.averages['sherpapak-cts'].temperature.max),
            },
          ],
        },
        {
          name: 'Distance',
          icon: RiPinDistanceFill,
          data: [
            {
              name: 'Minimum',
              value: formatDistance(stats.averages['sherpapak-cts'].distance.min),
            },
            {
              name: 'Average',
              value: formatDistance(stats.averages['sherpapak-cts'].distance.avg),
            },
            {
              name: 'Maximum',
              value: formatDistance(stats.averages['sherpapak-cts'].distance.max),
            },
          ],
        },
        {
          name: 'Duration',
          icon: RiTimerLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToTime(stats.averages['sherpapak-cts'].duration.min, { maxUnits: 3 }),
            },
            {
              name: 'Average',
              value: millisecondsToTime(stats.averages['sherpapak-cts'].duration.avg, { maxUnits: 3 }),
            },
            {
              name: 'Maximum',
              value: millisecondsToTime(stats.averages['sherpapak-cts'].duration.max, { maxUnits: 3 }),
            },
          ],
        },
        {
          name: 'Logging Duration',
          icon: RiTimerFlashLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToTime(stats.averages['sherpapak-cts'].loggingDuration.min, { maxUnits: 3 }),
            },
            {
              name: 'Average',
              value: millisecondsToTime(stats.averages['sherpapak-cts'].loggingDuration.avg, { maxUnits: 3 }),
            },
            {
              name: 'Maximum',
              value: millisecondsToTime(stats.averages['sherpapak-cts'].loggingDuration.max, { maxUnits: 3 }),
            },
          ],
        },
        {
          name: 'Ischemic Time',
          icon: RiMapPinTimeLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToPaddedTime(stats.averages['sherpapak-cts'].ischemicTime.min),
            },
            {
              name: 'Average',
              value: millisecondsToPaddedTime(stats.averages['sherpapak-cts'].ischemicTime.avg),
            },
            {
              name: 'Maximum',
              value: millisecondsToPaddedTime(stats.averages['sherpapak-cts'].ischemicTime.max),
            },
          ],
        },
      ],
      [ProductType.LUNGGUARD]: [
        {
          name: 'Temperature',
          icon: RiTempColdLine,
          data: [
            {
              name: 'Minimum',
              value: formatTemperature(stats.averages.lungguard.temperature.min),
            },
            {
              name: 'Average',
              value: formatTemperature(stats.averages.lungguard.temperature.avg),
            },
            {
              name: 'Maximum',
              value: formatTemperature(stats.averages.lungguard.temperature.max),
            },
          ],
        },
        {
          name: 'Distance',
          icon: RiPinDistanceFill,
          data: [
            {
              name: 'Minimum',
              value: formatDistance(stats.averages.lungguard.distance.min),
            },
            {
              name: 'Average',
              value: formatDistance(stats.averages.lungguard.distance.avg),
            },
            {
              name: 'Maximum',
              value: formatDistance(stats.averages.lungguard.distance.max),
            },
          ],
        },
        {
          name: 'Duration',
          icon: RiTimerLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToTime(stats.averages.lungguard.duration.min, { maxUnits: 3 }),
            },
            {
              name: 'Average',
              value: millisecondsToTime(stats.averages.lungguard.duration.avg, { maxUnits: 3 }),
            },
            {
              name: 'Maximum',
              value: millisecondsToTime(stats.averages.lungguard.duration.max, { maxUnits: 3 }),
            },
          ],
        },
        {
          name: 'Logging Duration',
          icon: RiTimerFlashLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToTime(stats.averages.lungguard.loggingDuration.min, { maxUnits: 3 }),
            },
            {
              name: 'Average',
              value: millisecondsToTime(stats.averages.lungguard.loggingDuration.avg, { maxUnits: 3 }),
            },
            {
              name: 'Maximum',
              value: millisecondsToTime(stats.averages.lungguard.loggingDuration.max, { maxUnits: 3 }),
            },
          ],
        },
        {
          name: 'Ischemic Time',
          icon: RiMapPinTimeLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToPaddedTime(stats.averages.lungguard.ischemicTime.min),
            },
            {
              name: 'Average',
              value: millisecondsToPaddedTime(stats.averages.lungguard.ischemicTime.avg),
            },
            {
              name: 'Maximum',
              value: millisecondsToPaddedTime(stats.averages.lungguard.ischemicTime.max),
            },
          ],
        },
      ],
      [ProductType.BAROGUARD]: [
        {
          name: 'Temperature',
          icon: RiTempColdLine,
          data: [
            {
              name: 'Minimum',
              value: formatTemperature(stats.averages.baroguard.temperature.min),
            },
            {
              name: 'Average',
              value: formatTemperature(stats.averages.baroguard.temperature.avg),
            },
            {
              name: 'Maximum',
              value: formatTemperature(stats.averages.baroguard.temperature.max),
            },
          ],
        },
        {
          name: 'Pressure',
          icon: WiBarometer,
          data: [
            {
              name: 'Minimum',
              value: formatPressure(stats.averages.baroguard.pressure.min),
            },
            {
              name: 'Average',
              value: formatPressure(stats.averages.baroguard.pressure.avg),
            },
            {
              name: 'Maximum',
              value: formatPressure(stats.averages.baroguard.pressure.max),
            },
          ],
        },
        {
          name: 'Distance',
          icon: RiPinDistanceFill,
          data: [
            {
              name: 'Minimum',
              value: formatDistance(stats.averages.baroguard.distance.min),
            },
            {
              name: 'Average',
              value: formatDistance(stats.averages.baroguard.distance.avg),
            },
            {
              name: 'Maximum',
              value: formatDistance(stats.averages.baroguard.distance.max),
            },
          ],
        },
        {
          name: 'Duration',
          icon: RiTimerLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToTime(stats.averages.baroguard.duration.min, { maxUnits: 3 }),
            },
            {
              name: 'Average',
              value: millisecondsToTime(stats.averages.baroguard.duration.avg, { maxUnits: 3 }),
            },
            {
              name: 'Maximum',
              value: millisecondsToTime(stats.averages.baroguard.duration.max, { maxUnits: 3 }),
            },
          ],
        },
        {
          name: 'Logging Duration',
          icon: RiTimerFlashLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToTime(stats.averages.baroguard.loggingDuration.min, { maxUnits: 3 }),
            },
            {
              name: 'Average',
              value: millisecondsToTime(stats.averages.baroguard.loggingDuration.avg, { maxUnits: 3 }),
            },
            {
              name: 'Maximum',
              value: millisecondsToTime(stats.averages.baroguard.loggingDuration.max, { maxUnits: 3 }),
            },
          ],
        },
        {
          name: 'Ischemic Time',
          icon: RiMapPinTimeLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToPaddedTime(stats.averages.baroguard.ischemicTime.min),
            },
            {
              name: 'Average',
              value: millisecondsToPaddedTime(stats.averages.baroguard.ischemicTime.avg),
            },
            {
              name: 'Maximum',
              value: millisecondsToPaddedTime(stats.averages.baroguard.ischemicTime.max),
            },
          ],
        },
      ],
      [ProductType.LIVERGUARD]: [
        {
          name: 'Temperature',
          icon: RiTempColdLine,
          data: [
            {
              name: 'Minimum',
              value: formatTemperature(stats.averages.liverguard.temperature.min),
            },
            {
              name: 'Average',
              value: formatTemperature(stats.averages.liverguard.temperature.avg),
            },
            {
              name: 'Maximum',
              value: formatTemperature(stats.averages.liverguard.temperature.max),
            },
          ],
        },
        {
          name: 'Distance',
          icon: RiPinDistanceFill,
          data: [
            {
              name: 'Minimum',
              value: formatDistance(stats.averages.liverguard.distance.min),
            },
            {
              name: 'Average',
              value: formatDistance(stats.averages.liverguard.distance.avg),
            },
            {
              name: 'Maximum',
              value: formatDistance(stats.averages.liverguard.distance.max),
            },
          ],
        },
        {
          name: 'Duration',
          icon: RiTimerLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToTime(stats.averages.liverguard.duration.min, { maxUnits: 3 }),
            },
            {
              name: 'Average',
              value: millisecondsToTime(stats.averages.liverguard.duration.avg, { maxUnits: 3 }),
            },
            {
              name: 'Maximum',
              value: millisecondsToTime(stats.averages.liverguard.duration.max, { maxUnits: 3 }),
            },
          ],
        },
        {
          name: 'Logging Duration',
          icon: RiTimerFlashLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToTime(stats.averages.liverguard.loggingDuration.min, { maxUnits: 3 }),
            },
            {
              name: 'Average',
              value: millisecondsToTime(stats.averages.liverguard.loggingDuration.avg, { maxUnits: 3 }),
            },
            {
              name: 'Maximum',
              value: millisecondsToTime(stats.averages.liverguard.loggingDuration.max, { maxUnits: 3 }),
            },
          ],
        },
        {
          name: 'Ischemic Time',
          icon: RiMapPinTimeLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToPaddedTime(stats.averages.liverguard.ischemicTime.min),
            },
            {
              name: 'Average',
              value: millisecondsToPaddedTime(stats.averages.liverguard.ischemicTime.avg),
            },
            {
              name: 'Maximum',
              value: millisecondsToPaddedTime(stats.averages.liverguard.ischemicTime.max),
            },
          ],
        },
      ],
      [ProductType.PANCREASPAK]: [
        {
          name: 'Temperature',
          icon: RiTempColdLine,
          data: [
            {
              name: 'Minimum',
              value: formatTemperature(stats.averages.pancreaspak.temperature.min),
            },
            {
              name: 'Average',
              value: formatTemperature(stats.averages.pancreaspak.temperature.avg),
            },
            {
              name: 'Maximum',
              value: formatTemperature(stats.averages.pancreaspak.temperature.max),
            },
          ],
        },
        {
          name: 'Distance',
          icon: RiPinDistanceFill,
          data: [
            {
              name: 'Minimum',
              value: formatDistance(stats.averages.pancreaspak.distance.min),
            },
            {
              name: 'Average',
              value: formatDistance(stats.averages.pancreaspak.distance.avg),
            },
            {
              name: 'Maximum',
              value: formatDistance(stats.averages.pancreaspak.distance.max),
            },
          ],
        },
        {
          name: 'Duration',
          icon: RiTimerLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToTime(stats.averages.pancreaspak.duration.min, { maxUnits: 3 }),
            },
            {
              name: 'Average',
              value: millisecondsToTime(stats.averages.pancreaspak.duration.avg, { maxUnits: 3 }),
            },
            {
              name: 'Maximum',
              value: millisecondsToTime(stats.averages.pancreaspak.duration.max, { maxUnits: 3 }),
            },
          ],
        },
        {
          name: 'Logging Duration',
          icon: RiTimerFlashLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToTime(stats.averages.pancreaspak.loggingDuration.min, { maxUnits: 3 }),
            },
            {
              name: 'Average',
              value: millisecondsToTime(stats.averages.pancreaspak.loggingDuration.avg, { maxUnits: 3 }),
            },
            {
              name: 'Maximum',
              value: millisecondsToTime(stats.averages.pancreaspak.loggingDuration.max, { maxUnits: 3 }),
            },
          ],
        },
        {
          name: 'Ischemic Time',
          icon: RiMapPinTimeLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToPaddedTime(stats.averages.pancreaspak.ischemicTime.min),
            },
            {
              name: 'Average',
              value: millisecondsToPaddedTime(stats.averages.pancreaspak.ischemicTime.avg),
            },
            {
              name: 'Maximum',
              value: millisecondsToPaddedTime(stats.averages.pancreaspak.ischemicTime.max),
            },
          ],
        },
      ],
      [ProductType.KTS]: [
        {
          name: 'Temperature',
          icon: RiTempColdLine,
          data: [
            {
              name: 'Minimum',
              value: formatTemperature(stats.averages['sherpapak-kts'].temperature.min),
            },
            {
              name: 'Average',
              value: formatTemperature(stats.averages['sherpapak-kts'].temperature.avg),
            },
            {
              name: 'Maximum',
              value: formatTemperature(stats.averages['sherpapak-kts'].temperature.max),
            },
          ],
        },
        {
          name: 'Distance',
          icon: RiPinDistanceFill,
          data: [
            {
              name: 'Minimum',
              value: formatDistance(stats.averages['sherpapak-kts'].distance.min),
            },
            {
              name: 'Average',
              value: formatDistance(stats.averages['sherpapak-kts'].distance.avg),
            },
            {
              name: 'Maximum',
              value: formatDistance(stats.averages['sherpapak-kts'].distance.max),
            },
          ],
        },
        {
          name: 'Duration',
          icon: RiTimerLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToTime(stats.averages['sherpapak-kts'].duration.min, { maxUnits: 3 }),
            },
            {
              name: 'Average',
              value: millisecondsToTime(stats.averages['sherpapak-kts'].duration.avg, { maxUnits: 3 }),
            },
            {
              name: 'Maximum',
              value: millisecondsToTime(stats.averages['sherpapak-kts'].duration.max, { maxUnits: 3 }),
            },
          ],
        },
        {
          name: 'Logging Duration',
          icon: RiTimerFlashLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToTime(stats.averages['sherpapak-kts'].loggingDuration.min, { maxUnits: 3 }),
            },
            {
              name: 'Average',
              value: millisecondsToTime(stats.averages['sherpapak-kts'].loggingDuration.avg, { maxUnits: 3 }),
            },
            {
              name: 'Maximum',
              value: millisecondsToTime(stats.averages['sherpapak-kts'].loggingDuration.max, { maxUnits: 3 }),
            },
          ],
        },
        {
          name: 'Ischemic Time',
          icon: RiMapPinTimeLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToPaddedTime(stats.averages['sherpapak-kts'].ischemicTime.min),
            },
            {
              name: 'Average',
              value: millisecondsToPaddedTime(stats.averages['sherpapak-kts'].ischemicTime.avg),
            },
            {
              name: 'Maximum',
              value: millisecondsToPaddedTime(stats.averages['sherpapak-kts'].ischemicTime.max),
            },
          ],
        },
      ],
      [ProductType.VANTAGEPOINT]: [
        {
          name: 'Temperature',
          icon: RiTempColdLine,
          data: [
            {
              name: 'Minimum',
              value: formatTemperature(stats.averages['vantage-point'].temperature.min),
            },
            {
              name: 'Average',
              value: formatTemperature(stats.averages['vantage-point'].temperature.avg),
            },
            {
              name: 'Maximum',
              value: formatTemperature(stats.averages['vantage-point'].temperature.max),
            },
          ],
        },
        {
          name: 'Distance',
          icon: RiPinDistanceFill,
          data: [
            {
              name: 'Minimum',
              value: formatDistance(stats.averages['vantage-point'].distance.min),
            },
            {
              name: 'Average',
              value: formatDistance(stats.averages['vantage-point'].distance.avg),
            },
            {
              name: 'Maximum',
              value: formatDistance(stats.averages['vantage-point'].distance.max),
            },
          ],
        },
        {
          name: 'Duration',
          icon: RiTimerLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToTime(stats.averages['vantage-point'].duration.min, { maxUnits: 3 }),
            },
            {
              name: 'Average',
              value: millisecondsToTime(stats.averages['vantage-point'].duration.avg, { maxUnits: 3 }),
            },
            {
              name: 'Maximum',
              value: millisecondsToTime(stats.averages['vantage-point'].duration.max, { maxUnits: 3 }),
            },
          ],
        },
        {
          name: 'Logging Duration',
          icon: RiTimerFlashLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToTime(stats.averages['vantage-point'].loggingDuration.min, { maxUnits: 3 }),
            },
            {
              name: 'Average',
              value: millisecondsToTime(stats.averages['vantage-point'].loggingDuration.avg, { maxUnits: 3 }),
            },
            {
              name: 'Maximum',
              value: millisecondsToTime(stats.averages['vantage-point'].loggingDuration.max, { maxUnits: 3 }),
            },
          ],
        },
        {
          name: 'Ischemic Time',
          icon: RiMapPinTimeLine,
          data: [
            {
              name: 'Minimum',
              value: millisecondsToPaddedTime(stats.averages['vantage-point'].ischemicTime.min),
            },
            {
              name: 'Average',
              value: millisecondsToPaddedTime(stats.averages['vantage-point'].ischemicTime.avg),
            },
            {
              name: 'Maximum',
              value: millisecondsToPaddedTime(stats.averages['vantage-point'].ischemicTime.max),
            },
          ],
        },
      ],
    }),
    [stats],
  );

  return (
    <Card>
      <h2 className="mb-12 text-xl font-medium">Product Details</h2>

      <Tab.Group>
        <div className="flex space-x-12">
          <Tab.List className="flex flex-col items-start space-y-4">
            <Tab>{({ selected }) => <TabListItemContent name="All Products" selected={selected} />}</Tab>

            {PRODUCT_DETAILS_TABS_ORDER.map((type, index) => (
              <Tab key={`tabs-${type}-${index}`}>
                {({ selected }) => (
                  <TabListItemContent name={ProductUtil.toHeading(type)} imgSrc={ProductUtil.imagePath(type)} selected={selected} />
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="w-full">
            <Tab.Panel>
              <div className="space-y-8">
                {dataAll.map((item, index) => (
                  <TabRow key={`tab-row-${index}-${item.name}`} {...item} />
                ))}
              </div>
            </Tab.Panel>

            {PRODUCT_DETAILS_TABS_ORDER.map((type, index) => (
              <Tab.Panel key={`tab-panel-${index}`}>
                <div className="space-y-8">
                  {data[type].map((item, index) => (
                    <TabRow key={`tab-row-${index}-${item.name}`} {...item} />
                  ))}
                </div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </div>
      </Tab.Group>
    </Card>
  );
};

const TabListItemContent: VFC<TabListItemContentProps> = ({ name, imgSrc, selected }) => {
  return (
    <div
      className={classNames(
        selected ? 'border-transparent bg-gray-100' : 'border-gray-200 bg-transparent',
        'w-96 rounded-md border px-8 py-2 hover:border-transparent hover:bg-gray-100',
      )}
    >
      <div className="flex items-center space-x-4 text-left">
        {imgSrc != null && <img className="h-20" src={imgSrc} alt="" />}
        <span className="text-lg font-medium">{name}</span>
      </div>
    </div>
  );
};

const TabRow: VFC<TabRowProps> = ({ name, icon: Icon, data }) => {
  return (
    <div>
      <div className="mb-2 ml-2 text-sm font-semibold text-gray-500">
        <div className="flex items-center space-x-1">
          <Icon className="h-4" />
          <h3>{name}</h3>
        </div>
      </div>

      <div className="flex justify-evenly rounded-md bg-gray-50 py-6">
        {data.map((item, index) => (
          <TabRowItem key={`tab-row-item-${name}-${item.name}-${index}`} name={item.name} value={item.value} />
        ))}
      </div>
    </div>
  );
};

const TabRowItem: VFC<TabRowItemProps> = ({ name, value }) => {
  return (
    <div className="flex flex-1 flex-col items-center space-y-1">
      <span className="text-sm text-gray-400">{name}</span>
      <span>{value ?? '-'}</span>
    </div>
  );
};

export default AveragesTabsSection;
