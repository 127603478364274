import { VFC, useMemo } from 'react';
import Table from './Table';
import CellFormatters from './Table/CellFormatters';

export type DestinationsListProps = {
  destinations: Array<{ unos: string; name: string; salesRep: string; areaDirector: string; total: number }>;
};

const columns = [
  {
    Header: 'UNOS',
    accessor: 'unos',
    Cell: CellFormatters.bold,
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Sales Representative',
    accessor: 'salesRep',
  },
  {
    Header: 'Area Director',
    accessor: 'areaDirector',
  },
  {
    Header: 'Total',
    accessor: 'total',
  },
];

const DestinationsList: VFC<DestinationsListProps> = ({ destinations }) => {
  const data = useMemo(() => {
    if (destinations == null) {
      return [];
    }

    return destinations.map(destination => ({
      unos: destination.unos,
      name: destination.name,
      salesRep: destination.salesRep,
      areaDirector: destination.areaDirector,
      total: destination.total,
    }));
  }, [destinations]);

  return <Table columns={columns as any} data={data} options={{ hasPagination: true, initialSortBy: [{ id: 'total', desc: true }] }} />;
};

export default DestinationsList;
