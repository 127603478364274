import { OrganType, OrganTypeUtil } from '@packages/firebase';
import { VFC } from 'react';

export type OrganBadgeProps = {
  organ: OrganType;
};

const OrganBadge: VFC<OrganBadgeProps> = ({ organ }) => {
  return (
    <div className="flex-shrink-0 p-1 rounded" style={{ backgroundColor: OrganTypeUtil.colors(organ).primaryLight }}>
      <img className="w-6 h-6" src={OrganTypeUtil.iconPath(organ)} alt="" />
    </div>
  );
};

export default OrganBadge;
