import { COUNTRIES } from '@packages/ui';
import AuthLayout from '../components/Layouts/AuthLayout';
import { Meta } from '../types';

export type LoginFormValues = {
  email: string;
  password: string;
  isRememberMeSet?: boolean;
};

const meta: Meta = {
  title: 'Countries',
  description: 'A list of countries and their ISO codes',
};

const Countries = () => {
  return (
    <AuthLayout meta={meta}>
      <ul className="p-12">
        {COUNTRIES.map(country => (
          <li>
            {country.name}: <span className="font-bold">{country.code}</span>
          </li>
        ))}
      </ul>
    </AuthLayout>
  );
};

export default Countries;
