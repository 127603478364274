import { VFC } from 'react';
import Button from './Button';
import Modal from './Modal';

export type ConfirmationModalProps = {
  title: string;
  text: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isLoading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmationModal: VFC<ConfirmationModalProps> = ({ title, text, isOpen, setIsOpen, isLoading = false, onConfirm, onCancel }) => {
  return (
    <Modal disableOutsideClick isOpen={isOpen} setIsOpen={setIsOpen} size="xl">
      <div className="divide-y">
        <div className="px-6 py-4">
          <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
          <p className="mt-1 text-sm text-gray-500">{text}</p>
        </div>

        <div className="flex space-x-2 px-6 py-4">
          <Button styleType="light" onClick={() => onCancel()} isFullWidth>
            Cancel
          </Button>

          <Button buttonType="submit" styleType="success" onClick={() => onConfirm()} isLoading={isLoading} isFullWidth>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
