import { useState, VFC } from 'react';
import { Cell, Pie, PieChart as REPieChart, ResponsiveContainer } from 'recharts';
import PieChartTable from './PieChartTable';

export type PieChartProps = {
  data: Array<{
    name: string;
    count: number;
    color: string;
  }>;
};

const PieChart: VFC<PieChartProps> = ({ data }) => {
  const [highLightIndex, setHighLightIndex] = useState<number | undefined>();

  return (
    <div className="space-y-12">
      <div className="w-full h-[250px]">
        <ResponsiveContainer>
          <REPieChart>
            <Pie
              data={data}
              outerRadius={120}
              stroke="none"
              fill="#8884d8"
              dataKey="count"
              onMouseEnter={(_, index) => setHighLightIndex(index)}
              onMouseLeave={() => setHighLightIndex(undefined)}
            >
              {data.map((entry, index) => (
                <Cell key={`pie-chart-cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </REPieChart>
        </ResponsiveContainer>
      </div>

      <PieChartTable data={data} highLightIndex={highLightIndex} />
    </div>
  );
};

export default PieChart;
