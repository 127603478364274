import { getFunctions, httpsCallable } from 'firebase/functions';
import app, { FIREBASE_REGION } from './config';

export function getFirebaseFunctions() {
  return getFunctions(app, FIREBASE_REGION);
}

export function endSession(sessionId: string) {
  const functions = getFirebaseFunctions();

  const fn = httpsCallable(functions, 'endSession');

  return fn({ sessionId });
}

export function calcReportDataForSessionOnCall(sessionId: string) {
  const functions = getFirebaseFunctions();

  const fn = httpsCallable(functions, 'calcReportDataForSessionOnCall');

  return fn({ sessionId });
}

export function startOrganizationValidation(organizationId: string) {
  const functions = getFirebaseFunctions();

  const fn = httpsCallable(functions, 'startOrganizationValidation');

  return fn({ organizationId });
}

export function activateOrganizationMember(organizationId: string, userId: string) {
  const functions = getFirebaseFunctions();

  const fn = httpsCallable(functions, 'activateOrganizationMember');

  return fn({ organizationId, userId });
}

export function deactivateOrganizationMember(organizationId: string, userId: string) {
  const functions = getFirebaseFunctions();

  const fn = httpsCallable(functions, 'deactivateOrganizationMember');

  return fn({ organizationId, userId });
}

export function grantOrganizationAccess(organizationId: string, userId: string) {
  const functions = getFirebaseFunctions();

  const fn = httpsCallable(functions, 'grantOrganizationAccess');

  return fn({ organizationId, userIds: [userId] });
}

export function sendOrganizationMemberInvite(organizationId: string, userIds: string[]) {
  const functions = getFirebaseFunctions();

  const fn = httpsCallable(functions, 'sendOrganizationMemberInvite');

  return fn({ organizationId, userIds });
}

export function inviteToOrganization(organizationId: string, email: string) {
  const functions = getFirebaseFunctions();

  const fn = httpsCallable(functions, 'inviteToOrganization');

  return fn({ organizationId, email });
}
