import { classNames } from '@packages/utils';
import { VFC } from 'react';
import { HiArrowTrendingDown, HiArrowTrendingUp } from 'react-icons/hi2';

export type ChangeType = 'increase' | 'decrease';

export type ChangeBadgeProps = {
  value: string;
  changeType: ChangeType;
};

const ChangeBadge: VFC<ChangeBadgeProps> = ({ value, changeType }) => {
  return (
    <div
      className={classNames(
        changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0',
      )}
    >
      {changeType === 'increase' ? (
        <HiArrowTrendingUp className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" />
      ) : (
        <HiArrowTrendingDown className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" />
      )}

      {value}
    </div>
  );
};

export default ChangeBadge;
