import { classNames } from '@packages/utils';
import { VFC } from 'react';
import { BaseSizes, BaseStyleTypes } from '../../types';

export type AvatarStyleTypes = BaseStyleTypes;
export type AvatarSizes = BaseSizes | 'xxl';

type BaseProps = {
  size?: AvatarSizes;
  isRound?: boolean;
};

export type PlaceholderAvatarProps = BaseProps;

export type ImageAvatarProps = BaseProps & {
  src?: string | null;
  alt?: string;
};

export type InitialsAvatarProps = BaseProps & {
  firstname: string;
  lastname: string;
  styleType?: AvatarStyleTypes;
};

function getSizeSpecificClassNames(size: AvatarSizes) {
  switch (size) {
    case 'xs':
      return 'h-6 w-6 text-xs font-medium leading-none';
    case 'sm':
      return 'h-8 w-8 text-sm font-medium leading-none';
    case 'md':
      return 'h-10 w-10 font-medium leading-none';
    case 'lg':
      return 'h-12 w-12 text-lg font-medium leading-none';
    case 'xl':
      return 'h-14 w-14 text-xl font-medium leading-none';
    case 'xxl':
      return 'h-20 w-20 text-xl font-medium leading-none';
    default:
      return 'h-10 w-10 font-medium leading-none';
  }
}

export const ImageAvatar: VFC<ImageAvatarProps> = ({ src, alt, size = 'md', isRound = true }) => {
  const sizeSpecificClassNames = getSizeSpecificClassNames(size);

  if (src == null || src.length === 0) {
    return <PlaceholderAvatar size={size} isRound={isRound} />;
  }

  return (
    <span className="inline-block">
      <img className={classNames(sizeSpecificClassNames, isRound ? 'rounded-full' : 'rounded-md', 'object-cover')} src={src} alt={alt} />
    </span>
  );
};

export const PlaceholderAvatar: VFC<PlaceholderAvatarProps> = ({ size = 'md', isRound = true }) => {
  const sizeSpecificClassNames = getSizeSpecificClassNames(size);

  return (
    <span
      className={classNames(sizeSpecificClassNames, isRound ? 'rounded-full' : 'rounded-md', 'inline-block overflow-hidden bg-gray-100')}
    >
      <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  );
};

export const InitialsAvatar: VFC<InitialsAvatarProps> = ({ firstname, lastname, styleType = 'default', size = 'md', isRound = true }) => {
  const sizeSpecificClassNames = getSizeSpecificClassNames(size);

  return (
    <span
      className={classNames(
        styleType,
        sizeSpecificClassNames,
        isRound ? 'rounded-full' : 'rounded-md',
        'avatar relative inline-flex items-center justify-center uppercase',
      )}
    >
      <span>
        {firstname.charAt(0)}
        {lastname.charAt(0)}
      </span>
    </span>
  );
};
