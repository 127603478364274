import { classNames } from '@packages/utils';
import { useState, VFC } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { HiPhoto } from 'react-icons/hi2';
import { v4 as uuid } from 'uuid';
import { getStorageDownloadURL, uploadImageFromBlob } from '../../../lib/firebase/storage';
import Loader from '../../Shared/Loader';

export type LogoUploadProps = {
  logoUrl?: string;
  onCompleted?: (filename: string, downloadUrl: string) => void;
};

type DropzoneContentProps = {
  text?: string;
  error?: string;
  isLoading?: boolean;
};

const LogoUpload: VFC<LogoUploadProps> = ({ logoUrl, onCompleted }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  async function onDrop(acceptedFiles: File[], rejectedFiles: FileRejection[]) {
    if (rejectedFiles.length > 0) {
      handleRejectedFiles(rejectedFiles);
      return;
    }

    if (acceptedFiles.length !== 1) {
      setError('Only upload 1 file at the most');
      return;
    }

    setIsLoading(true);

    const file = {
      ...acceptedFiles[0],
      blobUrl: URL.createObjectURL(acceptedFiles[0]),
      name: uuid(),
    };
    await uploadImageFromBlob(file.blobUrl, file.name, 'logos');
    const url = await getStorageDownloadURL(file.name, 'logos');

    onCompleted?.(file.name, url);

    setError(undefined);
    setIsLoading(false);
  }

  function handleRejectedFiles(rejectedFiles: FileRejection[]) {
    const errorCode = rejectedFiles[0].errors[0].code;

    setError(`Failed with error code: ${errorCode}`);
    setIsLoading(false);
  }

  return (
    <div>
      <Dropzone
        accept={{ 'image/*': [] }}
        maxFiles={1}
        multiple={false}
        maxSize={5e6}
        onDrop={(acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles)}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...(getRootProps() as any)}
            className={classNames(
              isDragActive ? 'bg-gray-100' : 'transparent',
              'flex items-center justify-center w-full border-2 border-gray-200 border-dashed rounded-md h-40 cursor-pointer',
            )}
          >
            <input {...getInputProps()} />

            {logoUrl != null ? (
              <div>
                <img src={logoUrl} className="h-5" alt="logo" />
              </div>
            ) : (
              <DropzoneContent text="Drop or click to select logo (max. 5MB)" error={error} isLoading={isLoading} />
            )}
          </div>
        )}
      </Dropzone>
    </div>
  );
};

const DropzoneContent = ({ text, error, isLoading }: DropzoneContentProps) => {
  return (
    <div className="flex items-center justify-center w-1/2">
      {isLoading ? (
        <Loader size="xl" />
      ) : (
        <div className="flex flex-col items-center space-y-2">
          <HiPhoto className="w-6 h-6 text-blue-500" />
          <p className="max-w-xs text-sm font-light text-center text-gray-500">{text}</p>

          {error != null && <p className="text-sm text-red-500">{error}</p>}
        </div>
      )}
    </div>
  );
};

export default LogoUpload;
