import { Session, Stats } from '@packages/firebase';
import { createContext, useContext } from 'react';

export type SessionsContextProps = {
  sessions: Session[];
  areSessionsLoading: boolean;
  stats: Stats;
};

const SessionsContext = createContext<SessionsContextProps | undefined>(undefined);

function useSessions() {
  const context = useContext(SessionsContext);

  if (context == null) {
    throw Error('Sessions must be used inside sessions context');
  }

  return context;
}

export { SessionsContext, useSessions };
