import { Organization, OrganType, OrganTypeUtil } from '@packages/firebase';
import { AddressAutocomplete } from '@packages/ui';
import { AddressComponents, classNames, parseAddressComponents } from '@packages/utils';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import isValidDomain from 'is-valid-domain';
import { FC, useEffect, useMemo, useState, VFC } from 'react';
import { HiCheck, HiXMark } from 'react-icons/hi2';
import * as yup from 'yup';
import {
  createOrganization,
  updateOrganization,
  useFetchOrganizations,
  useFetchOrganizationTypesConfig,
} from '../../../lib/firebase/organizations';
import Alert from '../../Shared/Alert';
import Button from '../../Shared/Button';
import Modal from '../../Shared/Modal';
import { EMAIL_PROVIDERS } from './email-providers';
import LogoUpload from './LogoUpload';

export type CreateOrganizationModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  isEditMode?: boolean;
  organization?: Organization;
};

type OrganCheckboxProps = {
  organType: OrganType;
  isChecked?: boolean;
  onChange: (organType: OrganType) => void;
};

type OrganizationTypeCheckboxProps = {
  type: string;
  label: string;
  isChecked?: boolean;
  onChange: (type: string) => void;
  disabled?: boolean;
};

const CreateOrganizationModal: VFC<CreateOrganizationModalProps> = ({ isOpen, setIsOpen, isEditMode, organization }) => {
  const config = useFetchOrganizationTypesConfig();

  const { organizations, areOrganizationsLoading } = useFetchOrganizations();

  const [error, setError] = useState<string | undefined>();
  const [isSuccess, setIsSuccess] = useState(false);

  const [logoFileName, setLogoFileName] = useState<string | undefined>(organization?.logoFileName);
  const [logoUrl, setLogoUrl] = useState<string | undefined>(organization?.logoUrl);

  const formik = useFormik({
    initialValues: {
      name: organization?.name ?? '',
      handle: organization?.handle ?? '',
      address: organization?.address ?? '',
      addressComponents: (organization?.addressComponents ?? {}) as AddressComponents,
      location: (organization?.location ?? {}) as { lat: number; lng: number },
      country: organization?.country ?? 'US',
      organTypes: (organization?.organTypes ?? []) as OrganType[],
      types: (organization?.types ?? []) as string[],
      domains: organization?.domains != null && organization.domains.length > 0 ? organization.domains.join(', ') : '',
      areDomainsWhitelisted: organization?.areDomainsWhitelisted ?? true,
      isCorporate: organization?.isCorporate ?? false,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required().min(1),
      handle: yup.string().required().min(1),
      address: yup.string().required().min(1),
      country: yup.string().required().min(1),
      organTypes: yup.array().of(yup.string()).required().min(1),
      types: yup.array().of(yup.string()),
      domains: yup.string(),
    }),
    onSubmit: async values => {
      if (isEditMode && organization == null) {
        return;
      }

      const data = {
        ...values,
        name: values.name.trim(),
        handle: values.handle.trim(),
        types: !values.isCorporate ? values.types : [],
        isCorporate: values.isCorporate ?? false,
        domains:
          values.domains != null
            ? values.domains
                .split(',')
                .filter(elem => elem != null && elem.length > 0)
                .map(elem => elem.trim())
                .map(elem => elem.toLowerCase())
                .filter(elem => elem != null && elem.length > 0)
            : [],
        logoFileName,
        logoUrl,
      };

      try {
        if (isEditMode && organization != null) {
          await updateOrganization(organization!.id, data);
        } else {
          await createOrganization(data);
        }
      } catch (err: any) {
        setIsSuccess(false);
        setError(err.message);
        return;
      }

      setError(undefined);
      setIsSuccess(true);

      setIsOpen(false);
    },
    enableReinitialize: true,
  });

  function clearAddress() {
    formik.setFieldValue('address', '');
    formik.setFieldValue('addressComponents', {} as AddressComponents);
    formik.setFieldValue('location', {} as { lat: number; lng: number });
    formik.setFieldValue('country', 'US');
  }

  // eslint-disable-next-line no-undef
  function handleOnPlacesChanged(data: google.maps.places.PlaceResult[]) {
    if (data.length === 0) {
      return;
    }

    const address = data[0].formatted_address;

    if (address != null && address.length > 0) {
      formik.setFieldValue('address', address);
    }

    const country = data[0].address_components?.find(elem => elem.types.includes('country'))?.short_name;

    if (country != null && country.length > 0) {
      formik.setFieldValue('country', country);
    }

    const addressComponents = parseAddressComponents(data[0]);

    if (addressComponents != null) {
      formik.setFieldValue('addressComponents', addressComponents);
    }

    const location = data[0].geometry?.location?.toJSON();

    if (location != null) {
      formik.setFieldValue('location', location);
    }
  }

  const errors = useMemo(() => {
    const domains = formik.values.domains != null && formik.values.domains.length > 0 ? formik.values.domains.split(',') : [];

    const allHandles = (organizations
      ?.filter(item => (organization != null ? organization.id !== item.id : true))
      .map(organization => organization.handle)
      .filter(handle => handle != null) ?? []) as string[];

    const allDomains = (organizations
      ?.filter(item => (organization != null ? organization.id !== item.id : true))
      .flatMap(organization => organization.domains)
      .filter(domain => domain != null) ?? []) as string[];

    const isDuplicateHandle = allHandles.includes(formik.values.handle);

    const isDuplicateDomain = allDomains.some(domain => domains.includes(domain));
    const isPublicEmailProvider = domains.some(domain => EMAIL_PROVIDERS.includes(domain));
    const areDomainsInvalid = domains.some(domain => !isValidDomain(domain));

    return {
      isDuplicateHandle,
      isDuplicateDomain,
      isPublicEmailProvider,
      areDomainsInvalid,
    };
  }, [formik.values, organizations, organization]);

  const isValid = useMemo(() => {
    const { name, handle, address, organTypes, types, isCorporate } = formik.values;

    return (
      name != null &&
      name.length > 0 &&
      name.trim().length > 0 &&
      handle != null &&
      handle.length > 0 &&
      handle.trim().length > 0 &&
      address != null &&
      address.length > 0 &&
      organTypes != null &&
      organTypes.length > 0 &&
      ((types != null && types.length > 0) || isCorporate) &&
      !errors.isDuplicateHandle &&
      !errors.isDuplicateDomain &&
      !errors.isPublicEmailProvider &&
      !errors.areDomainsInvalid
    );
  }, [formik.values, errors]);

  useEffect(() => {
    formik.resetForm();
    setError(undefined);
    setIsSuccess(false);
  }, [isOpen]);

  if (config.isLoading || areOrganizationsLoading) {
    return null;
  }

  return (
    <div className="py-24">
      <Modal size="2xl" disableOutsideClick isOpen={isOpen} setIsOpen={setIsOpen}>
        <form onSubmit={formik.handleSubmit}>
          <div className="divide-y">
            <h1 className="px-6 py-4 text-xl font-semibold text-gray-900">
              {isEditMode ? 'Edit organization' : 'Create new organization'}
            </h1>

            <div className="h-[800px] overflow-y-scroll">
              <div className="space-y-6 px-6 py-4 sm:space-y-5">
                <div className="space-y-6 sm:space-y-5">
                  {/* Name */}
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Name
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="e.g. ACME Inc."
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                    </div>
                  </div>

                  {/* Handle */}
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="handle" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Handle
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <input
                        type="text"
                        name="handle"
                        id="handle"
                        placeholder="e.g. ACME-TX1"
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-50 sm:max-w-xs sm:text-sm"
                        onChange={formik.handleChange}
                        value={formik.values.handle}
                        disabled={isEditMode && organization?.handle != null && organization.handle.length > 0}
                      />

                      {errors.isDuplicateHandle ? (
                        <div className="mt-2">
                          <p className="text-xs text-red-700">There already exists an organization with this handle.</p>
                        </div>
                      ) : (
                        <div className="mt-2">
                          <p className="text-xs text-gray-500">
                            The organization handle must be unique and cannot be changed once created.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Address */}
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Address
                    </label>
                    <div className="sm:col-span-2 sm:mt-0">
                      {formik.values.address != null && formik.values.address.length > 0 ? (
                        <div className="flex items-center space-x-2 sm:mt-px sm:pt-2">
                          <div className="text-sm text-gray-500">{formik.values.address}</div>

                          <button
                            type="button"
                            className="flex items-center space-x-1 rounded-md border border-gray-300 py-1 px-2 text-sm text-gray-500"
                            onClick={() => clearAddress()}
                          >
                            <HiXMark className="h-3 w-3" />
                          </button>
                        </div>
                      ) : (
                        <AddressAutocomplete
                          id="address"
                          placeholder="e.g. 123 Main St, Houston, TX 77002"
                          onChange={places => handleOnPlacesChanged(places)}
                          defaultValue={formik.values.address}
                        />
                      )}
                    </div>
                  </div>

                  {/* Organ Types */}
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="organTypes" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Organs
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <FormikProvider value={formik}>
                        <FieldArray
                          name="organTypes"
                          render={helpers => {
                            return (
                              <div className="space-y-1">
                                <OrganTypeCheckbox
                                  organType={OrganType.HEART}
                                  isChecked={formik.values.organTypes.includes(OrganType.HEART)}
                                  onChange={() =>
                                    formik.values.organTypes.includes(OrganType.HEART)
                                      ? helpers.remove(formik.values.organTypes.indexOf(OrganType.HEART))
                                      : helpers.push(OrganType.HEART)
                                  }
                                />
                                <OrganTypeCheckbox
                                  organType={OrganType.LUNG}
                                  isChecked={formik.values.organTypes.includes(OrganType.LUNG)}
                                  onChange={() =>
                                    formik.values.organTypes.includes(OrganType.LUNG)
                                      ? helpers.remove(formik.values.organTypes.indexOf(OrganType.LUNG))
                                      : helpers.push(OrganType.LUNG)
                                  }
                                />
                                <OrganTypeCheckbox
                                  organType={OrganType.LIVER}
                                  isChecked={formik.values.organTypes.includes(OrganType.LIVER)}
                                  onChange={() =>
                                    formik.values.organTypes.includes(OrganType.LIVER)
                                      ? helpers.remove(formik.values.organTypes.indexOf(OrganType.LIVER))
                                      : helpers.push(OrganType.LIVER)
                                  }
                                />
                                <OrganTypeCheckbox
                                  organType={OrganType.KIDNEY}
                                  isChecked={formik.values.organTypes.includes(OrganType.KIDNEY)}
                                  onChange={() =>
                                    formik.values.organTypes.includes(OrganType.KIDNEY)
                                      ? helpers.remove(formik.values.organTypes.indexOf(OrganType.KIDNEY))
                                      : helpers.push(OrganType.KIDNEY)
                                  }
                                />
                                <OrganTypeCheckbox
                                  organType={OrganType.PANCREAS}
                                  isChecked={formik.values.organTypes.includes(OrganType.PANCREAS)}
                                  onChange={() =>
                                    formik.values.organTypes.includes(OrganType.PANCREAS)
                                      ? helpers.remove(formik.values.organTypes.indexOf(OrganType.PANCREAS))
                                      : helpers.push(OrganType.PANCREAS)
                                  }
                                />
                              </div>
                            );
                          }}
                        />
                      </FormikProvider>
                    </div>
                  </div>

                  {/* Types */}
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="types" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Types
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <FormikProvider value={formik}>
                        <FieldArray
                          name="types"
                          render={helpers => {
                            return (
                              <div className="space-y-1">
                                {config.organizationTypes?.map(organizationType => (
                                  <OrganizationTypeCheckbox
                                    key={`organization-type-checkbox-${organizationType.id}`}
                                    type={organizationType.id}
                                    label={organizationType.locales.en}
                                    isChecked={formik.values.types.includes(organizationType.id)}
                                    onChange={() =>
                                      formik.values.types.includes(organizationType.id)
                                        ? helpers.remove(formik.values.types.indexOf(organizationType.id))
                                        : helpers.push(organizationType.id)
                                    }
                                    disabled={formik.values.isCorporate}
                                  />
                                ))}
                              </div>
                            );
                          }}
                        />
                      </FormikProvider>

                      <div className="relative flex items-start pt-4">
                        <div className="flex h-6 items-center">
                          <input
                            id="isCorporate"
                            name="isCorporate"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-600"
                            checked={formik.values.isCorporate}
                            onChange={formik.handleChange}
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label htmlFor="isCorporate" className="font-medium text-gray-900">
                            Corporate
                          </label>

                          <p className="text-gray-500">
                            This organization is a corporate entity and does not represent any of the above types. It will NOT be selectable
                            as a destination or and can only be joined through domain whitelisting or by invitation from an admin.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Logo */}
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="logo" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Logo <span className="text-gray-500">(optional)</span>
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <LogoUpload
                        logoUrl={logoUrl}
                        onCompleted={(filename, downloadUrl) => {
                          setLogoFileName(filename);
                          setLogoUrl(downloadUrl);
                        }}
                      />
                    </div>
                  </div>

                  {/* Domains */}
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="domains" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Domains <span className="text-gray-500">(optional)</span>
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <input
                        type="text"
                        name="domains"
                        id="domains"
                        placeholder="organization.com, organization.org"
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:max-w-xs sm:text-sm"
                        onChange={formik.handleChange}
                        value={formik.values.domains}
                      />

                      {formik.values.domains != null &&
                        formik.values.domains.length > 0 &&
                        !errors.isDuplicateDomain &&
                        !errors.isPublicEmailProvider &&
                        !errors.areDomainsInvalid && (
                          <div className="relative mt-2 flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="areDomainsWhitelisted"
                                name="areDomainsWhitelisted"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-blue-600"
                                checked={formik.values.areDomainsWhitelisted}
                                onChange={formik.handleChange}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label htmlFor="areDomainsWhitelisted" className="font-medium text-gray-900">
                                Domain Whitelisting
                              </label>

                              <p className="text-gray-500">
                                Any user with an email address that matches one of the domains above will be added to this organization
                                automatically.
                              </p>
                            </div>
                          </div>
                        )}

                      {errors.isDuplicateDomain && (
                        <div className="mt-2">
                          <p className="text-xs text-red-700">
                            One or more of the domains you entered are already in use by another organization.
                          </p>
                        </div>
                      )}

                      {errors.areDomainsInvalid && (
                        <div className="mt-2">
                          <p className="text-xs text-red-700">One or more of the domains you entered are invalid.</p>
                        </div>
                      )}

                      {errors.isPublicEmailProvider && (
                        <div className="mt-2">
                          <p className="text-xs text-red-700">
                            Domains from public email providers like gmail.com or yahoo.com are not allowed
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-6 py-4">
              {error != null && (
                <div className="pb-4">
                  <Alert title={error} type="danger" />
                </div>
              )}

              {isSuccess && (
                <div className="pb-4">
                  <Alert title="Organization created successfully." type="success" />
                </div>
              )}

              <div className="flex space-x-2">
                <Button buttonType="button" styleType="light" onClick={() => setIsOpen(false)} isFullWidth>
                  Cancel
                </Button>

                <Button buttonType="submit" isLoading={formik.isSubmitting} isFullWidth disabled={!isValid}>
                  {isEditMode ? 'Update' : 'Create'}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

const OrganTypeCheckbox: FC<OrganCheckboxProps> = ({ organType, isChecked: isCheckedInitial = false, onChange }) => {
  const [isChecked, setIsChecked] = useState(isCheckedInitial);

  function onClick() {
    setIsChecked(!isChecked);
    onChange(organType);
  }

  return (
    <button
      type="button"
      className={classNames(
        isChecked ? 'border-blue-500' : 'border-gray-200',
        'flex w-full items-center justify-between rounded border py-2 px-3',
      )}
      onClick={() => onClick()}
    >
      <div className="flex items-center space-x-2">
        <div>
          <img className="h-5 w-5" src={OrganTypeUtil.iconPath(organType, true)} alt="" />
        </div>
        <div className="text-sm font-medium">{OrganTypeUtil.organString(organType)}</div>
      </div>

      {isChecked && (
        <div className="flex items-center">
          <div className="rounded-full bg-green-200 p-1">
            <HiCheck className="h-2 w-2 text-green-700" />
          </div>
        </div>
      )}
    </button>
  );
};

const OrganizationTypeCheckbox: FC<OrganizationTypeCheckboxProps> = ({
  type,
  label,
  isChecked: isCheckedInitial = false,
  onChange,
  disabled = false,
}) => {
  const [isChecked, setIsChecked] = useState(isCheckedInitial);

  function onClick() {
    setIsChecked(!isChecked);
    onChange(type);
  }

  return (
    <button
      type="button"
      className={classNames(
        isChecked ? 'border-blue-500' : 'border-gray-200',
        'flex w-full items-center justify-between rounded border py-2 px-3 disabled:cursor-not-allowed disabled:opacity-50',
      )}
      onClick={() => onClick()}
      disabled={disabled}
    >
      <div className="text-sm font-medium">{label}</div>

      {isChecked && (
        <div className="flex items-center">
          <div className="rounded-full bg-green-200 p-1">
            <HiCheck className="h-2 w-2 text-green-700" />
          </div>
        </div>
      )}
    </button>
  );
};

export default CreateOrganizationModal;
